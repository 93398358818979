//
// Utilities - Margin
// --------------------------------------------------

// All sides
.u-margin-0 {
    margin: 0 !important;
}

.u-margin-xs {
    margin: @indent-xs !important;
}

.u-margin-sm {
    margin: @indent-sm !important;
}

.u-margin-md {
    margin: @indent-md !important;
}

.u-margin-lg {
    margin: @indent-lg !important;
}

.u-margin-xl {
    margin: @indent-xl !important;
}

// Top only
.u-margin-top-0 {
    margin-top: 0 !important;
}

.u-margin-top-xs {
    margin-top: @indent-xs !important;
}

.u-margin-top-sm {
    margin-top: @indent-sm !important;
}

.u-margin-top-md {
    margin-top: @indent-md !important;
}

.u-margin-top-lg {
    margin-top: @indent-lg !important;
}

.u-margin-top-xl {
    margin-top: @indent-xl !important;
}

// Right only
.u-margin-right-0 {
    margin-right: 0 !important;
}

.u-margin-right-xs {
    margin-right: @indent-xs !important;
}

.u-margin-right-sm {
    margin-right: @indent-sm !important;
}

.u-margin-right-md {
    margin-right: @indent-md !important;
}

.u-margin-right-lg {
    margin-right: @indent-lg !important;
}

.u-margin-right-xl {
    margin-right: @indent-xl !important;
}

// Left only
.u-margin-left-0 {
    margin-left: 0 !important;
}

.u-margin-left-xs {
    margin-left: @indent-xs !important;
}

.u-margin-left-sm {
    margin-left: @indent-sm !important;
}

.u-margin-left-md {
    margin-left: @indent-md !important;
}

.u-margin-left-lg {
    margin-left: @indent-lg !important;
}

.u-margin-left-xl {
    margin-left: @indent-xl !important;
}

// Bottom only
.u-margin-bottom-0 {
    margin-bottom: 0 !important;
}

.u-margin-bottom-xs {
    margin-bottom: @indent-xs !important;
}

.u-margin-bottom-sm {
    margin-bottom: @indent-sm !important;
}

.u-margin-bottom-md {
    margin-bottom: @indent-md !important;
}

.u-margin-bottom-lg {
    margin-bottom: @indent-lg !important;
}

.u-margin-bottom-xl {
    margin-bottom: @indent-xl !important;
}