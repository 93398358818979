//
// Utilities - Padding
// --------------------------------------------------

// All sides
.u-padding-0 {
    padding: 0 !important;
}

.u-padding-xs {
    padding: @indent-xs !important;
}

.u-padding-sm {
    padding: @indent-sm !important;
}

.u-padding-md {
    padding: @indent-md !important;
}

.u-padding-lg {
    padding: @indent-lg !important;
}

.u-padding-xl {
    padding: @indent-xl !important;
}

// Top only
.u-padding-top-0 {
    padding-top: 0 !important;
}

.u-padding-top-xs {
    padding-top: @indent-xs !important;
}

.u-padding-top-sm {
    padding-top: @indent-sm !important;
}

.u-padding-top-md {
    padding-top: @indent-md !important;
}

.u-padding-top-lg {
    padding-top: @indent-lg !important;
}

.u-padding-top-xl {
    padding-top: @indent-xl !important;
}

// Right only
.u-padding-right-0 {
    padding-right: 0 !important;
}

.u-padding-right-xs {
    padding-right: @indent-xs !important;
}

.u-padding-right-sm {
    padding-right: @indent-sm !important;
}

.u-padding-right-md {
    padding-right: @indent-md !important;
}

.u-padding-right-lg {
    padding-right: @indent-lg !important;
}

.u-padding-right-xl {
    padding-right: @indent-xl !important;
}

// Left only
.u-padding-left-0 {
    padding-left: 0 !important;
}

.u-padding-left-xs {
    padding-left: @indent-xs !important;
}

.u-padding-left-sm {
    padding-left: @indent-sm !important;
}

.u-padding-left-md {
    padding-left: @indent-md !important;
}

.u-padding-left-lg {
    padding-left: @indent-lg !important;
}

.u-padding-left-xl {
    padding-left: @indent-xl !important;
}

// Bottom only
.u-padding-bottom-0 {
    padding-bottom: 0 !important;
}

.u-padding-bottom-xs {
    padding-bottom: @indent-xs !important;
}

.u-padding-bottom-sm {
    padding-bottom: @indent-sm !important;
}

.u-padding-bottom-md {
    padding-bottom: @indent-md !important;
}

.u-padding-bottom-lg {
    padding-bottom: @indent-lg !important;
}

.u-padding-bottom-xl {
    padding-bottom: @indent-xl !important;
}

@media screen and (max-width: @screen-md) {
    .md-padding-0 {
        padding-left: 0;
        padding-right: 0;
    }

}