//
// Mini Cart
// --------------------------------------------------

//
// Variables
// --------------------------------------------------

@minicart-layer-width: 260px;
@minicart-layer-position-offset: -1px;
@minicart-product-image-width: 30%;
@minicart-product-info-width: 60%;
@minicart-side-paddings: 15px;


.mini-cart {
    .product-list-wrapper {
        max-height: 471px;
        overflow: auto;
    }
    .mini-cart-layer {
        width: @minicart-layer-width;
        left: auto;
        right: @minicart-layer-position-offset;
        padding: 0;
        border: none;
        box-shadow: 0 2px 12px 0px @gray-darker;

        .mini-cart-header {
            position: relative;
            padding: 0 25px;
            text-align: right;
            background-color: @gray-darker;
            border: 1px solid @gray-dark;

            &:after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 14px 7px 14px;
                border-color: transparent transparent @gray-darker;
                position: absolute;
                top: -7px;
                right: 23px;
            }

            &:before {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 15px 8px 15px;
                border-color: transparent transparent @gray-dark;
                position: absolute;
                top: -8px;
                right: 22px;
            }

            a {
                display: block;
                padding: 13px 0;
                font-size: @font-size-base;
                color: @brand-white;
                text-decoration: none;
            }
        }

        .cart-link {
            color: @brand-primary;
            &:hover {
                text-decoration: underline;
            }
        }

        .empty:not(span) {
            color: @brand-black;
            padding: 10px;
            text-align: center;
        }

        span.empty {
            vertical-align: middle;
            max-width: 45px;
        }
    }


    .links {
        .button {
            width: 100%;
        }
    }

    .mini-products-list {
        float: none;
        width: 100%;
        margin-bottom: 0;
        max-height: 62vh;
        overflow: auto;

        .product-table td {
            padding: 20px 10px;
        }


        li.mini-cart-item {
            background-color: @brand-white;
            vertical-align: top;
            color: @brand-black;
            padding: 15px;
            line-height: 18px;
            width: 100%;
            font-size: @font-size-small;
            text-align: left;
            border: 0;

            &:first-child {
                font-weight: @font-weight-normal;
            }

            .item-name,
            a {
                font-weight: @font-weight-bold;
                padding: 0;
                color: @brand-black;
                display: inline;
                text-decoration: none;
                font-size: @font-size-base;

                &:hover {
                    background-color: transparent;
                    color: @brand-black;
                }
            }

            .options-list {
                float: none;

                li {
                    font-size: @font-size-small;
                    line-height: 1.2;
                    border: none;
                    width: 100%;
                }
            }

            .item-qty {
                line-height: 1;
            }
        }

        .remove-link {
            width: 5%;
            color: @brand-primary;
            float: left;
            height: 65px;
            position: relative;
            &:before {
                transform: translateY(-50%);
                position: absolute;
                top: 50%;
            }
        }
        .item {
            color: @brand-secondary;
            border: none;
            padding: 0;
        }
        .product-image {
            float: left;
            width: @minicart-product-image-width;
            text-align: center;
        }

        .product-info {
            float: right;
            width: @minicart-product-info-width;
        }

        .item-name {
            color: @brand-secondary;
            font-weight: bold;
            &:hover {
                color: @brand-primary;
            }
        }
    }

    .total,
    .links,
    .empty-popup-cart{
        padding: 10px @minicart-side-paddings;
    }

    .total {
        padding: 13px @minicart-side-paddings;
        background-color: @gray-base;
        color: @brand-black;
    }

    .banner {
        padding: 5px @minicart-side-paddings;
        img {
            width: 100%;
            max-width: none;
        }
    }

    .links {
        background-color: @gray-darker;
        padding: 23px @minicart-side-paddings;


        > .pull-right,
        > .pull-left {
            width: 110px;
        }

        .button {
            display: block;
            min-width: 0;
            width: 100%;
            color: @brand-white;
            font-weight: @font-weight-semi-bold;
            margin: 0;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

//
// Add to Cart Layer
// --------------------------------------------------
.addto-cart-layer {
    .links {
        a + a {
            margin-top: 20px;
        }
    }

    .modal-content {
        width: 400px;
    }
}

@media screen and (max-width: @screen-md) {

    .mini-cart {
        .mini-cart-layer {

            .mini-cart-header {

                &:after {
                    right: 13px;
                }

                &:before {
                    right: 12px;
                }

            }
        }
    }
}

@media screen and (max-width: @screen-sm) {

    .addto-cart-layer {

        .modal-content {
            width: 90%;
        }
    }
}
