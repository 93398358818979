//
// jQuery UI Components
// @see http://docs.jquery.com/UI/Autocomplete#theming
// --------------------------------------------------


//
// UI Autocomplete
// --------------------------------------------------

.ui-autocomplete {
    position: absolute;
    cursor: default;
}

//
// UI Menu
// --------------------------------------------------
// @todo: Make these styles more general
.ui-menu {
    list-style:none;
    padding: 2px;
    margin: 2px;
    display:block;
    float: left;
    background-color: white;
    border: 1px solid #ddd;
    -webkit-border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;

}

.ui-menu .ui-menu {
    margin-top: -3px;
}
.ui-menu .ui-menu-item {
    margin:0;
    padding: 0;
    zoom: 1;
    float: left;
    clear: left;
    width: 100%;
}
.ui-menu .ui-menu-item a {
    text-decoration:none;
    display:block;
    padding:.2em .4em;
    line-height:1.5;
    zoom:1;
}
.ui-menu .ui-menu-item a.ui-state-hover,
.ui-menu .ui-menu-item a.ui-state-active {
    font-weight: bold;
}


//
// Date Picker
// --------------------------------------------------
// @todo: Make these styles more general
#ui-datepicker-div {
    background-color: #fff;
    border: 1px solid @gray-lighter;
    padding: 20px 20px 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    display: none;

    .ui-datepicker-header{
        padding: 10px;

        .ui-datepicker-prev,
        .ui-datepicker-next {
            cursor: pointer;
        }

        .ui-datepicker-prev {
            float: left;
            .ui-icon,
            &:before{
                vertical-align: middle;
            }
            &:before {
                margin-right: 5px;
                .icon;
                content: "\f0d9";
                font-size: 16px;
            }
        }

        .ui-datepicker-next {
            float: right;
            .ui-icon,
            &:after {
                vertical-align: middle;
            }
            &:after {
                margin-left: 5px;
                .icon;
                content: "\f0da";
                font-size: 16px;
            }
        }

        .ui-datepicker-title {
            text-transform: uppercase;
            text-align: center;
            font-size: 15px;
            line-height: 1.5;
        }
    }
    .ui-datepicker-calendar {
        thead th {
            text-transform: uppercase;
        }
        td {
            padding: 10px;
            text-align: center;

            &.ui-datepicker-today {
                background-color: @gray-lighter;
            }

            &.ui-datepicker-current-day {
                border: 1px solid #000;
                background-color: @brand-primary;
                a {
                    color: #fff;
                }
            }
        }
    }
}