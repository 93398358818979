@navigation-container-padding: 0;
@navigation-container-background: transparent;
@navigation-anchor-color: @brand-black;
@navigation-anchor-hover-color: @link-hover-color;
@navigation-anchor-font-size: 15px;
@navigation-trigger-padding: 0;
@navigation-list-padding: 0;
@navigation-list-margin: 0;

//
// Navigation
// --------------------------------------------------
.nav-container,
.smarted-nav-container-wrapper {
	background: @navigation-container-background;
	float: left;
	width: percentage(557/980);
	padding: 0;

	.nav-container {
		width: 100%;
	}
}

/* All Levels */
.navigation {
	width: 100%;
	margin: 0;
	ul {
		&:extend(.reset-list);
		// Can't use flexbox here, due to IE9 requirement
	}

	> ul {
		display: table;
		table-layout: fixed;
		text-align: center;
	}

	li[data-title] {
		display: none !important;
	}


	.nav-title {
		display: block;
		font-size: @navigation-anchor-font-size;
		margin-bottom: 10px;
		font-weight: normal;
		border-bottom: 1px solid @gray-light;
		padding-bottom: 10px;
	}

	li {
		a {
			display: table-cell;
			color: @navigation-anchor-color;
			font-size: @navigation-anchor-font-size;
			line-height: @navigation-anchor-font-size;
			text-decoration: none;
			letter-spacing: 0.5px;
			&:hover {
				color: @navigation-anchor-hover-color;
			}

			// Technically the next active level
			&.has-children + ul {

			}
		}
	}

	// 1st Level (Top Level)
	> ul {
		.clearfix();
		width: 100%;
	}

	li.level0 {
		z-index: 3;
		display: inline-block;
		margin-left: @indent-sm;
		margin-right: @indent-sm;

		&:first-child {
			margin-left: 0;
		}

		& > a,
		& > div > a {
			font-weight: normal;
			text-transform: uppercase;
			display: block;
			white-space: normal;
			text-align: center;
			font-size: 15px;
			position: relative;
			line-height: 14px;
			padding: 28px 0;
			letter-spacing: 0.1px;
			color: @gray-darker;
			/* autoprefixer: off */
   			-ms-touch-action: none !important;
    		/* autoprefixer: on */

			&:hover {
				color: @brand-red;
				background-color: @brand-white;
			}
		}

		&.active > a {
			color: @brand-red;
		}
	}

	// 2nd+ Level Triggers
	li[class^="level"]:not(li.level0) a {
	}

	// 2nd+ Level
	a + .menu-wrapper,
	div + .menu-wrapper {
		&:extend(.menu .drop-down all);
		width: 100%;
		max-width: 980px;
		transform: translateX(-50%);
		min-height: 449px;

	}

	ul.level0 {
		float: left;
		width: 180px;
		height: 100%;
		min-height: 445px;
		background-color: @gray-lighter;
		border-right: 2px solid @gray-base;
	}

	li.visible-md {
		width: 0;
		height: 0;
	}

	li.level1 {
		float: left;
		background-color: @gray-lighter;
		width: 100%;
		transition: height 0.5s ease;

		> a,
		> div > a {
			color: @brand-black;
			display: block;
			height: 40px;
			font-weight: @font-weight-normal;
			padding: 12px 0 0 15px;
			border-bottom: 2px solid @gray-base;
			text-align: left;
			font-size: @font-size-base;
			transition: all 0.5s ease;

			&.active,
			&:hover {
				color: @brand-white;
				background-color: @brand-red;
			}
		}
	}

	ul.level1 {
		opacity: 0;
		position: absolute;
		top: 0;
		right: 0;
		width: calc(~'100% - 180px');
		text-align: left;
		padding: 30px 14px 30px 30px;
		transition: opacity 0.5s ease;
		background-color: @brand-white;
		z-index: -1;
		min-height: 445px;
	}

	.active + ul.level1 {
		opacity: 1;
		z-index: 1;
	}

	div {
		.active {
			& & + ul.level1 {
				opacity: 1;
				z-index: 1;
			}
		}
	}

	li.level2 a,
	li.level2 div a {
		font-size: @font-size-small;
		font-weight: @font-weight-normal;
		line-height: 22px;
	}

	li.level2.has-children {
		float: left;
		width: percentage(230/752);
		margin-right: percentage(30/752);

		&:nth-child(3n) {
			margin-right: 0;
		}

		&:nth-child(3n+1) {
			clear: left;
		}

		> a,
		> div > a {
			color: @gray-dark;
			position: relative;
			font-size: 16px;
			font-weight: @font-weight-normal;
			line-height: 18px;
			display: block;
			margin-bottom: 4px !important;
			letter-spacing: 0.2px;
			padding-right: 10px;

			&:before {
				.icon;
				font-size: 10px;
				line-height: 1;
				content: @fa-var-chevron-right;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);

			}

			&:after {
				content: "";
				position: absolute;
				display: block;
				width: 100%;
				height: 2px;
				background-color: @gray-base;
				bottom: -4px;
				left: 0;
			}
		}
	}

	ul.level2 {
		margin: 3px 0;
	}

	li.level3 a {
		font-size: @font-size-small;
		font-weight: @font-weight-normal;
		line-height: 22px;
		color: @brand-black;

		&:hover {
			color: @brand-blue;
		}
	}
}


.header #breadcrumb {
	display: none;
}


//
// If we are working with responsive, compile this
// --------------------------------------------------
& when (@is-responsive = true) {
	@media screen and (min-width: (981px)) {
		#header {
			position: relative;
		}

		.level1 [title='Favorites'] {
			padding-top: 10px !important;

			&:before {
				.icon;
				content: @fa-var-heart;
				display: inline-block;
				margin-right: 5px;
			}
		}
	}

	@media screen and (max-width: 1039px) {
		.navigation li.level0 {
			margin-left: percentage(28/557);
		}
	}

	@media screen and (max-width: 980px) {


		.container-fluid.header-wrapper {
			padding: 0;
		}

		.nav-container {
			display: none;

			nav.mobile-navigation {
				position: relative;
				overflow: hidden;


				ul {
					width: 100%;
					.reset-list;
				}

				font-size: @font-size-large;
				text-transform: uppercase;
			}

			a {
				border-top: 1px solid @brand-white;
				color: @gray-light;
				display: block;
				font-weight: @font-weight-normal;
				padding: 11px 50px 10px 21px;
				position: relative;
				text-decoration: none;
				text-transform: capitalize;
			}

			li.level0>a {
				font-weight: @font-weight-normal;
				font-size: 16px;
				padding: 11px 50px 10px 21px;
			}

			li {
				.icon-angle-left,
				.icon-angle-right {
					height: 100%;
					position: absolute;
					right: 0;
					top: 0;
					padding: 0 26px 0 25px;
					z-index: 1;
					font-size: 17px;

					&:before {
						position: absolute;
						right: 23px;
						top: 50%;
						transform: translateY(-50%);
					}
				}

				.menu-back {
					padding-left: 38px;
					font-size: @font-size-small;
					text-transform: uppercase;
					padding-top: 12px;
					letter-spacing: 0;
					padding-bottom: 9px;

					.icon-angle-left {
						right: auto;
						left: 1px;
					}
				}

				.top-category .menu-back {
					padding: 11px 50px 10px 21px;
					background-color: @brand-red;
					font-size: 16px;
					font-weight: @font-weight-normal;
					text-transform: capitalize;
				}

				&.has-children {
					> a > .icon-angle-right {
						border-left: 1px solid @brand-white;
					}
				}

				[title='Favorites'] {
					&:before {
						.icon;
						content: @fa-var-heart;
						display: inline-block;
						margin-right: 8px;
					}
				}
			}

			ul[class*='level'] {
				li[class*='level'] > a{
					padding-left: 37px;
					font-size: @font-size-base;
				}
			}

			&.gor-tray {
				background-color: @gray-darker;
				width: 100%;
				height: 0;
				overflow: hidden;
				display: block;

				&.transitioning {
					transition: height 0.5s ease;
				}

				ul {
					.clearfix;
					text-align: left;
				}

				li {
					float: left;
					width: 100%;
				}
			}

			ul {
				transition: all 0.5s ease;
			}


			.nav.mobile-navigation > ul,
			.has-children ul {
				position: absolute;
				top: 0;
				background-color: @gray-darker;
				z-index: 1;
				left: 200%;
			}

			nav.mobile-navigation > ul {
				position: absolute;
				left: 0;
			}


			a.open + ul {
				left: 100%;
			}

			a.open + .menu-wrapper > ul {
				left: 100%;
			}

			a.open + .menu-wrapper > ul.push-left {
				left: 0;
			}

			ul.open.push-left {
				left: -100%;
			}

			a.open + ul.push-left {
				left: 0;
			}
		}
	}
}