@stepBorder: 15px solid;

//
// File Uploader
//-----------------------------------------------

.file-uploader {
    width: 100%;
    max-width: 284px;

    // added via js
    .dz-preview {
        position: relative;
        width: 100%;
        border: 1px solid @brand-blue;
        border-top: none;
        padding: 6px 8px;
        font-size: @font-size-small;
    }

    .dz-filename {
        margin-right: @indent-lg;
        overflow: visible;
        white-space: pre-wrap;
    }

    .dz-message {

        span {
            font-weight: @font-weight-bold;
        }
    }
}

.file-uploader--step {
    max-width: 388px;
}

.company-settings-uploader {
    .dz-preview {
        border-top: 1px solid @brand-blue;;
    }
}

.company-settings__logo {
    display: block;
    margin-bottom: @indent-sm;
}

.company-settings__heading {
    font-family: @font-family-base;
    color: @brand-black;
    padding-bottom: @indent-sm;
}

.file-uploader--modal {
    margin: 0 auto;
    display: none;

    // nesting needed
    button {
        margin: @indent-sm 0 0 0;
    }
}

.file-uploader--visible {
    display: block;
}

.file-uploader__inner {
    background-color: @brand-white;
    border: 1px solid @brand-blue;
    margin: @indent-sm auto 0;
}

.file-uploader__inner--step {
    float: left;
    max-width: 284px;
}

.file-uploader__heading {
    display: inline-block;
    font-size: @font-size-small;
    line-height: normal;
}

.file-uploader__label {
    color: @gray-base;
    display: block;
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: @font-size-small;
    font-weight: normal;
    line-height: normal;
    padding: 4px 8px;
    cursor: pointer;
}

.file-uploader__progress {
    background-color: @brand-blue;
    height: 10px;
    transition: all 0.5s linear;
    margin-top: 3px;
    width: 1px;
}

.file-uploader__info {
    margin-right: 26px;
}

.file-uploader__checkbox-label {
    font-size: @font-size-base;
}

.file-uploader__button {
    margin: 17px 0 25px 0 !important; //override
}

.file-uploader__button--step {
    float: left;
    margin: @indent-sm 0 0 @indent-sm;
}

.file-uploader__button--modal {
    margin: @indent-sm 0 0
}

.file-uploader__preview--step {
    max-width: 284px;
    margin-top: 50px;
}

.file-upload {
    &.file-upload-no-image {
        .dz-image {
            display: none;
        }
    }
}

//
// Upload status global messages
//-----------------------------------------------

.uploader-section__msg {
    padding: 16px 0 14px;
}

.uploader-section__msg--success {
    background: @brand-green;
}

.uploader-section__msg--pending {
    background: @brand-blue;
}

.uploader-section__msg--error {
    background: @brand-red;
}

.uploader-section__msg-text {
    color: @brand-white;
    font-size: @font-size-h5;
    margin-bottom: 0;
    line-height: 28px;
    float: left;
    width: percentage(940/@wrapper-width);
}

.uploader-section__msg-link {
    color: @brand-white;
    font-size: @font-size-base;

    &:hover {
        color: @brand-white !important;
    }
}

.uploader-section__msg-link--right {
    float: right;
}

.uploader-section__grouped-msg {
    display: flex;
}

.uploader-section__status-text {
    font-size: @font-size-h4;
    line-height: 22px;
}
//
// Upload How-to Page
//-----------------------------------------------

// nesting needed for impex content to avoid classes
.upload-section__container {
    margin-bottom: 36px;

    h2 {
        padding-top: 6px;
    }
}

// nesting needed for impex content to avoid classes
.upload-section__cms {

    span {
        font-weight: @font-weight-semi-bold;
    }
}

// nesting needed for impex content to avoid classes
.upload-section__cms--success {

    span {
        line-height: @line-height-computed;
    }
}

.upload-section__inner {
    background: @brand-white;
    padding: 16px @indent-md 6px;
}

.uploader-section__image {
    padding-top: 23px;
}

.upload-section__btn {
    margin-bottom: 22px;

    &.disabled {
        pointer-events: none;
    }
}

.uploader-section__progress-bar {
    padding: 0 @indent-lg 0 @indent-sm;
    display: flex;
}

.uploader-section__progress-step {
    display: inline-block;
    background: @gray-base;
    color: @gray-dark;
    font-weight: @font-weight-bold;
    padding: 8px 0;
    position:relative;
    margin-right: 2px;
    flex-grow: 1;
    text-align: center;

    &:before,
    &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        top: 0;
        position: absolute;
    }

    &:before {
        border-left: @stepBorder @brand-white;
        left: 0;
    }

    &:after {
        border-left: @stepBorder @gray-base;
        right: -15px;
        z-index: 2;
    }
}

.uploader-section__progress-step--active {
    background: @brand-blue;
    color: @brand-white;

    &:after {
        border-left: @stepBorder @brand-blue;
    }
}

.uploader-section__progress-step--success {
    background: @brand-green;
    color: @brand-white;

    &:after {
        border-left: @stepBorder @brand-green;
    }
}

.uploader-section__progress-step--error {
    background: @brand-red;
    color: @brand-white;

    &:after {
        border-left: @stepBorder @brand-red;
    }
}

.uploader-section__progress-step--complete {
    background: @brand-light-blue;

    &:after {
        border-left: @stepBorder @brand-light-blue;
    }
}


.uploader-section__step--active {
    display: block;
}

// Uploader status icons - shared
.uploader-section__icon {
    border-radius: 50%;
    background: @brand-white;
    position: relative;
    top: 3px;
    left: 3px;

    &:before {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 10px;
    }

}

.uploader-section__icon--small {
    .size(15px,15px);
}

.uploader-section__icon--large {
    border-radius: 50%;
    background: @brand-white;
    width: percentage(30/@wrapper-width);
    min-width: 30px;
    height: 30px;
    position: relative;
    float: left;
    margin: 23px @indent-sm 0 0;

    &:before {
        position: absolute;
        top: 3px;
        left: 50%;
        transform: translate(-50%,0);
        font-size: @font-size-large;
    }
}

.uploader-section__icon--error {

    &:before {
        color: @brand-red;
    }
}

.uploader-section__icon--text {
    line-height: 28px;
}

.uploader-section__icon--success {

    &:before {
        color: @brand-green;
    }
}

.uploader-section__icon--pending {
    background: url('/_ui/responsive/common/images/ajax-loader.gif');
    background-size: 30px 30px;
}

.uploader-section__icon--success-inverted {
    background: @brand-green;

    &:before {
        color: @brand-white;
    }
}

.uploader-section__icon--error-inverted {
    background: @brand-red;

    &:before {
        color: @brand-white;
    }
}

.uploader-section__icon--loading {
    display: inline-block;
    float: left;
    width: percentage(30/@wrapper-width);
    min-width: 30px;
    height: 30px;
    margin-right: 8px;
}

@media screen and (max-width: @screen-md) {

    .uploader-section__msg-text {
        width: percentage(908/1040);
    }

    .upload-section__inner {
        padding: 16px @indent-xs 6px 0;
    }

    // override needed to prevent duplicated styles
    .copy-quote-popup .section-header,
    .upload-section__header {
        height: auto !important;
        line-height: 24px !important;

        &:before {
            height: 100% !important;
        }
    }

    // nesting needed for impex content to avoid classes
    .upload-section__cms--success {

        span {
            display: inline-block;
            float: left;
            width: percentage(800/@wrapper-width);
            line-height: @line-height-computed;
            margin-bottom: @indent-lg;
        }

        p {
            clear: both;
        }
    }

    .uploader-section__image {
        padding: 35px 0;
        text-align: center;
        margin-bottom: 16px;
    }

    .uploader-section__progress-bar {
        display: block;
        padding: 0 @indent-sm 0 2px;
    }

    .uploader-section__progress-step {
        display: block;
        margin-bottom: @indent-md;
    }

    .uploader-section__icon--large {
        margin-top: 0;
    }
}


@media screen and (max-width: 650px) {
    // nesting needed
    .file-uploader {
        .file-uploader__button--step {
            width: auto;
            margin: @indent-sm 0 0 @indent-sm;

        }

        .file-uploader__button--modal {
            width: auto;
            margin: @indent-sm 0 0;
        }
    }

    .file-uploader--step {
        max-width: 100%;
    }

}

@media screen and (max-width: @screen-sm) {
    // nesting needed
    .file-uploader {
        .file-uploader__button--step {
            width: auto;
            margin: @indent-sm 0 0 0;
        }
    }
    .file-uploader__preview--step,
    .file-uploader__inner--step {
        max-width: 70%;
    }

}
