.sidebar-nav {
    ul {
        &:extend(.reset-list);
        padding: 0;
        margin: 0;
        li {
            margin-bottom: 10px;
            a {
                color: @brand-primary;
            }
        }
    }
}

// If we are working with responsive, compile this
// --------------------------------------------------
& when (@is-responsive = true) {
    @media screen and (max-width: @screen-md) {
        .sidebar-nav {
            &.accordion {
                background: @gray-lighter;
            }
            .accordion-header {
                display: block;
            }
        }
    }
}

