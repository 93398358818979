/**
Product List / Search Results
 */
.page-searchList,
.page-favorites,
.page-searchGrid,
.page-productGrid,
.page-productList {
	background-color: @brand-white;

	h2 {
		margin-top: 10px;
	}

	footer {
		margin-top: 70px;
	}
}

.facet-mobile {
	display: none;
}

.page-favorites,
.page-searchGrid,
.page-searchList,
.page-productList,
.page-productGrid {
	.pagination-bar {
		padding-bottom: 20px;


		form > div {
			float: right;
		}

		li {
			float: left;
		}
	}
}

.page-favorites {
	.page-header + #breadcrumb {
		margin-top: -30px;
		margin-bottom: 30px;
	}
	.product-grid {
		float: none;
		margin: 0 -17px;
	}
}

.product-list-item {
	.part-number {
		font-weight: @font-weight-normal;
		font-size: @font-size-small;
	}
}

.page-searchList,
.page-productList {
	.product-list-item-wrapper {
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid @gray;

		&:last-child {
			border: 0;
		}
	}

	.product-list-item.row {
		a {
			color: @brand-black;
			text-decoration: none;
			font-weight: @font-weight-bold;
		}
	}
}

.applied-facet.js-facet a {
	margin-bottom: 20px;
}

.facetNavigation {
	float: left;
	width: percentage(280/@wrapper-width);

    .reset-list {
        li {
            margin-bottom: 5px;
        }
    }

    .section-inner li.active {
        font-weight: @font-weight-bold;
    }

    .section-inner li:not(.active) a {
        font-weight: @font-weight-bold;
    }

	.section-inner .accordion-header {
		font-size: 14px;
		height: auto;
		line-height: 1;
		background-color: transparent;
		padding: 0;
		min-width: auto;

		a {
			text-decoration: none;
			color: @brand-black;
		}

		&:before {
			display: none;
		}

		&:after {
			font-size: 11px;
			color: @brand-black;
		}
	}

	.section-header {
		height: 40px;
		font-size: @font-size-base;
		font-weight: @font-weight-bold;
		padding: 11px 15px 11px 20px;

		&:before {
			height: 40px;
		}
	}

	.section-inner {
		padding-left: 20px;
		padding-right: 15px;
		margin-bottom: 20px;
		background-color: @gray-lighter;
	}

	.gor-accordion .accordion-header {
		font-size: @font-size-base;
		border-bottom: 2px solid @brand-black;
		padding-bottom: 5px;

		&:after {
			font-size: 10px;
			right: 0;
		}
	}

	.form-list {
		margin: 0;

		li {
			margin-bottom: 7px;

			&:first-child {
				margin-top: 9px;
			}

			label {
				font-weight: @font-weight-normal;
			}
		}


		.applied-facet {
			margin-bottom: 20px;
		}
	}
}

.section-inner.fastener-type {
	padding: 0;
	border: 2px solid @gray-light;
	border-top: none;

	li {
		padding: 13px 20px;
		line-height: 1;

		&.active {
			color: @brand-blue;
			background-color: @gray-lighter;
		}

		a {
			color: @brand-black;
			text-decoration: none;
			font-weight: @font-weight-normal;
		}
	}
}

.page-searchEmpty {

	#content {
		padding-top: 30px;

		.site-search-wrapper {
			float: none;
			width: 100%;
			max-width: 509px;
			margin-bottom: 15px;
			opacity: 1;

			+ .clearfix {
				margin-bottom: 20px;
			}
		}
	}
}

.product-grid {
	float: right;
	width: percentage(681/@wrapper-width);

	.page-favorites & {
		width: 100%;
	}

	h4 {
		float: left;
	}

	.row {
		margin: 0 -17px;
	}

	[class*=col-] {
		padding-left: 17px;
		padding-right: 17px;
	}

	a {
		display: block;
		text-decoration: none;
	}

	.thumb {
		border: 2px solid @gray-light;
		margin-bottom: 3px;

		img {
			display: block;
		}
	}

	.details {
		color: @brand-black;
		text-decoration: none;
		font-weight: @font-weight-bold;

		.part-number {
			font-weight: @font-weight-normal;
			font-size: @font-size-small;
		}
	}

	.site-search-wrapper {
		float: none;
		width: 100%;
		max-width: 509px;
		margin-bottom: 15px;
		opacity: 1;

		+ .clearfix {
			margin-bottom: 20px;
		}
	}
}

.productGrid {

	> div {
		opacity: 0;
		margin-bottom: 47px;
		transition: opacity 0.4s ease;

		&.height-fixed {
			opacity: 1;
		}
	}
}

.facet_block {
	&:extend(.reset-list all);
}

.pagination-bar {
	float: right;
	font-size: 14px;
	padding: 5px 0;

	div,
	label {
		display: inline-block;
	}

	label {
		margin-right: 6px;
	}

	.custom-styled-select {
		width: 129px;
	}

	.custom-styled-select.with-custom-options ul.custom-options {
		width: 194px;
		right: 0;
		left: auto;

		&.open {
			border-top: 2px solid @gray-base;
		}

		li {
			background-color: @gray-lighter;
			font-size: @font-size-base;
			text-transform: capitalize;
			padding: 5px 15px 0;

			&:first-child {
				padding-top: 10px;
			}

			&:last-child {
				padding-bottom: 10px;
			}
		}
	}

	.outer {
		text-transform: capitalize;
		font-weight: @font-weight-bold;
		padding-right: 27px;

		&.open {
			border-bottom-color: transparent;

			&:before {
				content: "";
				display: block;
				width: calc(~"100% - 4px");
				height: 2px;
				background-color: @gray-lighter;
				position: absolute;
				bottom: 0;
				right: 2px;
				z-index: 51;
			}
		}
	}
}

#load-more {
	display: block;
	position: relative;

	button {
		display: block;
		margin: 0 auto;
	}

	.spinner {
		background-color: rgba(255, 255, 255, 0.5);

		&:before {
			display: block;
			top: 5px;
			font-size: 30px;
			margin-left: -16px;
		}
	}
}

.page-searchEmpty {
	#content {
		background-color: @brand-white;
	}

	.item_container_holder {
		margin-top: 40px;
	}
}

.grid-list-toggle {
	padding-left: 13px;

	> a,
	span {
		display: inline-block;
		width: 30px;
		height: 30px;
		overflow: hidden;
		margin-top: 5px;
		margin-right: 11px;
		background: url("../images/grid-list.png") no-repeat;
		background-size: auto 30px;

		&.list-button {
			background-position: top right;
			margin-right: 0;
		}
	}

	a:hover,
	span {
		opacity: 0.4;
	}
}

.section-inner .active-filters,
.active-filters {
	display: none;
}

.view-type-switcher {
    margin-top: -15px;
    margin-bottom: 5px;
}

//
// If we are working with responsive, compile this
// --------------------------------------------------
& when (@is-responsive = true) {

	@media (min-width: @screen-lg) {
		.page-favorites {
			.col-lg-3 {
				width: 20%;
			}
		}
	}

	@media screen and (max-width: @screen-md) {
		.page-searchEmpty #content .site-search-wrapper,
		.product-grid .site-search-wrapper {
			position: static;
			background-color: transparent;
			padding: 0;
		}

		.facet-mobile .applied-facet {
			background-color: @gray-lightest;
			padding: 15px 15px 20px 20px;

			.section-inner {
				background-color: transparent;
				padding: 0;
			}

			li {
				font-size: @font-size-small;
				line-height: 20px;
				padding-right: 65px;
				position: relative;
				z-index: 1;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;


				a {
					position: absolute;
					right: 0;
					text-decoration: none;

					span {
						display: inline-block;
						margin-right: 5px;
					}
				}
			}

			.button {
				margin-top: 15px;

				.section-inner & {
					margin-top: 0;
				}
			}
		}

		.active-filters {
			display: block;
		}

		.facet-desktop a {
			display: none;
		}

		.facet-mobile {
			display: block;

			.button-set {
				padding: 0 15px 20px 20px;

				.button {
					width: 100%;
				}
			}

			.col-xs-6 > div {
				margin-bottom: 0;
				margin-top: 0 !important;
				padding: 0 !important;
			}
		}

		.facetNavigation {
			width: 100%;

			.section-inner {
				padding: 0;
				margin-bottom: 0;

				&.fastener-type {
					border: none;
				}

				.applied-facet,
				> .gor-accordion {
					padding-left: 20px;
					padding-right: 15px;

					&:first-child {
						margin-top: 20px;
					}
				}
			}
		}

		.product-grid {
			width: 100%;
		}
	}

	@media screen and (max-width: @screen-sm) {
		.product-grid h4 {
			float: left;
			width: 100%;
		}
	}
}
