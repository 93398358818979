.ms-container{

}

.ms-container:after{
  content: "\f061";
  display: block;
  height: 30px;
  width: 30px;
  line-height: 0;
  font-size: 0;
  clear: both;
  min-height: 0;
  margin-top: -15px;
  margin-left: -15px;
  position: absolute;
  top: 50%;
  left: 50%;
  font: normal normal normal 24px/1 FontAwesome;
  line-height: 37px;
  text-align: center;
}

.ms-container .ms-selectable, .ms-container .ms-selection{
  background: #fff;
  color: @brand-black;
  float: left;
  width: 48%;
}
.ms-container .ms-selection{
  float: right;
}

.ms-container .ms-list{
  border: 2px solid @gray-base;
  position: relative;
  height: 200px;
  padding: 0;
  overflow-y: auto;
}

.ms-container .ms-list.ms-focus{
  border-color: @gray;
  outline: 0;
  outline: thin dotted \9;
}

.ms-container ul{
  margin: 0;
  list-style-type: none;
  padding: 0;
}

.ms-container .ms-optgroup-container{
  width: 100%;
}

.ms-container .ms-optgroup-label{
  margin: 0;
  padding: 5px 0px 0px 5px;
  cursor: pointer;
  color: #999;
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection{
  padding: 5px 10px;
  color: @brand-black;
  font-size: 14px;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover{
  cursor: pointer;
  color: @brand-blue;
  text-decoration: none;
  background-color: @gray-lighter;
}

.ms-container .ms-selectable li.disabled,
.ms-container .ms-selection li.disabled{
  background-color: #eee;
  color: #aaa;
  cursor: text;
}

.ms-selected {
    position: relative;

    &:before {
        content: "\f00d";
        display: block;
        position: absolute;
        font-family: @font-family-icon;
        top: 5px;
    }

    span {
        padding-left: 15px;
    }
}

@media screen and (max-width: 800px) {
    .ms-container .ms-selectable, .ms-container .ms-selection {
        width: 100%;
    }

    .ms-container .ms-selectable {
        margin-bottom: 30px;
    }

    .ms-container:after {
        content: "\f063";
        line-height: 53px;
    }

    .ms-container-divider {
        border-top: 1px solid @gray-base;
        padding-top: 20px;
    }
}