//
// Tables
// --------------------------------------------------

//
// Define Tables
// --------------------------------------------------
.table{
    width: 100%;
    margin: @table-margin;

    th{
        border: @table-head-border;
        background-color: @table-head-bg;
        text-align: @table-head-align;
        font-weight: @font-weight-semi-bold;

        font-size: @font-size-small;
        line-height: 1;
        border-bottom: none;
        padding-top: @indent-sm;
        padding-bottom: 8px;

        &:after {
            display:block;
            content:attr(data-text);
            font-weight:bold;
            height:1px;
            color:transparent;
            overflow:hidden;
            visibility:hidden;
            margin-bottom:-1px;
        }

        button.link {
            font-weight: @font-weight-semi-bold;
            color: @brand-black;
        }

        &.sorted,
        &.headerSortDown,
        &.headerSortUp {
            font-weight: @font-weight-bold;

            button.link {
                font-weight: @font-weight-bold;
                color: @brand-black;
            }
        }

        &:first-child {
            padding: @table-head-padding;
            text-align: left;
            border-left: none;
        }

        &:last-child {
            border-right: none;
        }
    }

    td {
        border: @table-cell-border;
        background-color: @table-cell-bg;
        text-align: @table-cell-align;
        font-size: @font-size-small;
        font-weight: @font-weight-normal;
        border-top: none;
        padding: @indent-sm;

        &:after {
            display: block;
            content: attr(data-text);
            font-weight: bold;
            height: 1px;
            color: transparent;
            overflow: hidden;
            visibility: hidden;
            margin-bottom: -1px;
        }

        &:first-child {
            padding: @table-cell-padding;
            font-weight: @font-weight-bold;
            text-align: left;
            border-left: none;

            a {
                text-decoration: none;
            }
        }

        &:last-child {
            border-right: none;
        }

        &.status {
            text-transform: capitalize;
        }

        &.sorted {
            font-weight: @font-weight-bold;

            > span:not(.archive),
            > button.link {
                font-weight: @font-weight-bold !important;
            }
        }

        &.break-word {
            word-break: break-all;
        }
    }
}


.table--wide-desktop {
    @media screen and (min-width: @screen-md) {
        width: max-content;
        min-width: 100%;
    }
}

.table-wrapper {
    width: 100%;
}

th.total-inches-header {
    position: relative;

    .total-inches-or-text {
        position: absolute;
        left: -14px;
    }
}

.table-striped {
    tbody tr:nth-of-type(2n):not(.table-tr-bottom):not(.edit) {
        > tr > td {
            background-color: @table-cell-alt-bg;
        }
    }
}

.responsive-table-link {
    margin-bottom: 0;
}

.table-striped-cart {
    .product-row.gray {
        td {
            background-color: @table-cell-alt-bg;
        }

        + tr.edit td {
            background-color: @table-cell-alt-bg;

            .page-productDetails {
                background-color: @table-cell-alt-bg;
            }
        }
    }
}

tr.edit {
    display: none;

    td {
        border-bottom: 0;
    }

    &.open {
        display: table-row;

        td {
            border-bottom: @table-cell-border;
        }
    }
}


.sortable {
    th {
        cursor: pointer;
    }
}

td.actions {
    padding: 0 @indent-sm;
}

.shopping-cart-items {
    th {
        cursor: pointer;
    }
}

.product-table {
    th {
        padding: 9px percentage(20/@wrapper-width);
    }

    td {
        padding: 20px percentage(20/@wrapper-width);
        border-bottom: @table-cell-border;
        vertical-align: top;
        text-align: center;

        &:first-child {
            border-left: 0;
        }

        &:nth-child(2) {
            text-align: left;
            border-left: none;
            padding-left: 0;
        }
    }

    td.product-image {
        border-right: none;
        width: percentage(87/@wrapper-width);
        padding-left: 20px;

        &.mass-edit-active {
            width: percentage(140/@wrapper-width);
        }

        a {
            display: inline-block;
            width: auto;
        }

        img {
            border: 2px solid @gray-lighter;
            display: block;
            height: auto;
            width: @product-table-image-width;
            max-width: @product-table-image-width;
        }
    }

    .product-info {
        width: 100%;
        float: left;
        margin-left: @product-table-margin;
        text-align: left;
        font-weight: @font-weight-normal;

        a.product-image {
            display: none;
        }

        > a {
            color: @brand-black;
            display: block;
            font-size: @font-size-h5;
            font-weight: @font-weight-bold;
            line-height: 1;
            margin: 10px 0 7px;
            text-decoration: none;
        }

        ul,
        p {
            font-size: @font-size-base;
            line-height: 1.5;

            span.separator {
                color: @gray-base;
                display: inline-block;
                padding: 0 12px;
            }
        }

        p {
            margin-bottom: 7px;
        }

        li {
            margin-bottom: 6px;

            &:first-child {
                padding-left: 0;
            }
        }

        span.empty {
            display: inline-block;
            position: relative;
            width: 42px;
            height: 10px;
            background-color: transparent;
            border-top: 0;

            &:before {
                display: inline-block;
                border-top: 1px solid @brand-black;
                content: "";
                height: 1px;
                left: 3px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 42px;
            }
        }
    }

    tr.edit .product-info {
        float: none;
    }
}

.product-table tr.separator td:first-child {
    font-weight: @font-weight-normal;
    background-color: @gray-lighter;
    padding: @indent-xs @indent-sm;
    height: 27px;
}

.product-info {
    // on mobile, product image inside product-info td is shown
    // (hidden for desktop)
    > a.product-image {
        display: none;
    }
}

@media screen and (max-width: @screen-lg) {
    .product-table {
        td.product-image {
            &.mass-edit-active {
                text-align: center;
                padding-left: @indent-xs;
                padding-right: @indent-xs;
                width: percentage(177/@wrapper-width);
            }
        }
    }
}

@media screen and (max-width: 800px) {
    colgroup {
        display: none;
    }

    .spaced-table {
        margin: @table-margin;

        td {
            padding: 0 @indent-xs;

            &:first-child {
                padding-left: 0;
            }
        }
    }

    .product-table {
        td.product-image {
            width: @product-table-image-width;
        }

        .product-info {
            li {
                &:last-child {
                    margin-bottom: 1px;
                }
            }
        }

        &.edit-attribute-table {

            tr.edit {

                td {
                    padding-left: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .product-table {
        .product-info {
            > a.product-image {
                display: inline-block;
                width: @product-table-image-width;
                max-width: @product-table-image-width;

                img {
                    width: auto;
                    // limit image size
                    max-height: @product-table-image-height;
                }
            }
        }

        td.product-image {
            width: auto;

            a {
                display: none;
            }

            &.mass-edit-active {
                width: percentage(87/@wrapper-width);
            }
        }
    }

    .table:not(.dashboard-table):not(.product-table):not(.table-length):not(.itemized-list-table) {
        th,
        td {
            display: none;

            &:first-child,
            &:last-child {
                padding-top: @indent-sm;
                text-align: left;
                display: table-cell;
                vertical-align: top;
            }

            &:first-child {
                padding-left: percentage(20/313);
                width: percentage(223/315);
            }

            &:last-child {
                padding-left: percentage(14/313);
                width: percentage(92/315);
            }
        }

        th:last-child {
            text-align: center;
            padding-left: 0;
        }

        td:last-child {
            padding-right: @indent-xs;
            padding-left: @indent-xs;
            text-align: center;
        }
    }

    .product-table,
    .dashboard-table {
        colgroup {
            display: none;
        }

        th.status {
            border-right: 0;
        }
        td.status {
            width: 30%;
            border-right: 0;
            vertical-align: top;
            padding: 21px 20px 21px 10px;
        }

        .actions-tab {
            margin-top: @indent-sm;
        }
    }

    .table-stacked {
        colgroup,
        th {
            display: none;
        }

        td {
            display: block;
            text-align: left;
        }

        tr:first-child {
            td:first-child {
                border-top: @table-cell-border;
            }
        }

        // No headers if the no-headers class is present
        &:not(.no-headers) {
            td {
                &:before {
                    display: inline-block;
                    vertical-align: top;
                    width: 50%;
                    content: attr(data-header);
                    font-weight: bold;
                }
            }
        }
    }

    .product-table {
        td,
        th {
            &:first-child {
                padding-left: percentage(20/315);
            }
        }

        th{
            &:nth-child(6) {
                text-align: right;
                padding-right: @indent-lg;
            }
        }

        tr:not(.edit) {
            td {
                &:first-child {
                    padding: 0;
                }

                &:nth-child(2) {
                    padding: 20px;
                }
            }
        }

        tr.edit td {
            padding-left: percentage(10/315);
        }

        tbody tr:not(.edit) td {
            &:last-child {
                text-align: center;
                padding-top: 20px;
                display: table-cell;
                width: percentage(91/315);
            }
        }

        .product-inner-table {
            tbody tr:not(.edit) td {
                padding: 11px 0;
            }
        }

        .product-info {
            a {
                margin-top: -1px;
            }

            p {
                margin-bottom: 8px;
            }

            li {
                margin-bottom: 8px;
                float: none;
                margin-right: 0;
            }

            .mobile-content {
                margin-top: @indent-xs;
            }
        }
    }

    .edit-attribute-table {
        .product-row {
            td {
                &:first-child {
                    display: none;
                }
            }
        }
    }

    table {
        .mobile-content {
            > div {
                padding: @indent-xs 0;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .dashboard-table {
        clear: both;
    }
}

@media screen and (max-width: 400px) {
    .product-table,
    .dashboard-table {
        .product-info {
            span.empty,
            strong {
                margin-top: 2px;
            }
        }
    }

    .product-table td:last-child {
        padding-right: @indent-xs;
        padding-left: @indent-xs;
        text-align: center;
    }
}

@media screen and (max-width: 340px) {
    .product-table td:last-child {
        padding-right: @indent-xs;
        padding-left: @indent-xs;
        text-align: center;
    }
}