//
// Base
// --------------------------------------------------

//
// Box Sizing
// -------------------------
*{
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

//
// Body Reset
// -------------------------
html{
    -webkit-tap-highlight-color: rgba(0,0,0,.25);
}

body{
    font-size: @font-size-base;
    font-family: @font-family-base;
    line-height: @line-height-base;
    color: @text-color;
    background-color: @body-bg;
    font-weight: @font-weight-normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.clip {
        overflow: hidden;
    }
}

#content {
    min-height: 550px;
}

button,
a {
    outline: 0;

    &:hover,
    &:focus,
    &:active {
        outline: 0;
    }
}

//
// Responsive Images
// -------------------------
img{
    max-width: 100%;
    height: auto;
}

