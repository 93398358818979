@font-face {
	font-family: 'boilerplate';
	src: url('../../common/fonts/boilerplate.eot?7zsspk');
	src: url('../../common/fonts/boilerplate.eot?7zsspk#iefix') format('embedded-opentype'),
	url('../../common/fonts/boilerplate.ttf?7zsspk') format('truetype'),
	url('../../common/fonts/boilerplate.woff?7zsspk') format('woff'),
	url('../../common/fonts/boilerplate.svg?7zsspk#boilerplate') format('svg');
	font-weight: normal;
	font-style: normal;
}

.icon-cart-merge,
.icon-cart-nci,
.icon-cvv-white,
.icon-cvv-black {
	&:before {
		/* use !important to prevent issues with browser extensions that change fonts */
		font-family: 'boilerplate' !important;
		line-height: 1;
	}
}

.icon-cart-nci:before {
	content: "\e900";
}

.icon-cvv-white:before {
	content: "\e901";
}

.icon-cvv-black:before {
	content: "\e902";
}

.icon-cart-merge:before {
	content: "\e903";
}