//
// Utilities - Display
// --------------------------------------------------

.u-display-block {
    display: block !important;
}

.u-display-inline-block {
    display: inline-block !important;
}

.u-display-inherit {
    display: inherit !important;
}

