//
// Typography
// --------------------------------------------------

// Headings
// -------------------------
h1, h2, h3, h4, h5, h6, h7,
.h1, .h2, .h3, .h4, .h5, .h6, .h7   {
    font-family: @headings-font-family;
    font-weight: @headings-font-weight;
    line-height: @headings-line-height;
    color: @headings-color;
    margin-top: @line-height-computed;
    margin-bottom: @line-height-computed;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, .h1 {
    font-size: @font-size-h1;
}
h2, .h2 {
    display: block;
    font-size: @font-size-h2;
    padding-bottom: 5px;
    border-bottom: 2px solid @brand-red;
}
h3, .h3 {
    font-size: @font-size-h3;
}
h4, .h4 {
    font-size: @font-size-h4;
}
h5, .h5 {
    font-size: @font-size-h5;
}
h6, .h6 {
    font-size: @font-size-h6;
}
h7, .h7 {
    font-size: @font-size-h7;
}

// Body text
// -------------------------
p {
    margin: 0 0 @line-height-computed;
}


// Links
// -------------------------
a {
    color: @link-color;
    text-decoration: @link-decoration;
    font-weight: @font-weight-bold;

    &:not(.helpful-hint):not(.button) {
        &:hover,
        &:focus {
            color: @link-hover-color;
        }
    }

    &.remove {
        color: @link-remove-color;

        &:hover,
        &:focus {
            color: @link-remove-hover-color;
        }
    }

    &.alternate {
        font-weight: @font-weight-semi-bold;
    }
}

// Lists
// -------------------------
ul,
ol {
    margin-top: 0;
    margin-bottom: @line-height-computed;
    list-style-position: outside;
    padding-left: 1.2em;
    line-height: 24px;

    ul,
    ol {
        margin-bottom: 0;
    }
}

ul.styled-list {
    list-style: none;
    padding-left: 0;

    li {
        margin-top: 22px;
        padding-left: 18px;
        position: relative;

        &:before {
            .icon;
            content: @fa-var-download;
            color: @brand-blue;
            left: 0;
            top: 2px;
            position: absolute;

        }
    }
}

// Inputs
// -------------------------
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}