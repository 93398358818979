.accountGlobalAnnouncementsContentSlot {
    background-color: @brand-red;

    .global-announcement-content {
        padding: 20px 30px;
        color: @brand-white;

        p {
            margin-bottom: 0;
        }

        a {
            color: @brand-white;
        }
    }
}