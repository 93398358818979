//
// Carousel
// --------------------------------------------------


// Core Owl Carousel Styles
.owl-carousel {
    width: 100%;
    display: none;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 0;

    &.owl-loaded {
        display: block;
    }

    &.owl-loading {
        opacity: 0;
        display: block;
    }

    &.owl-hidden {
        opacity: 0;
    }

    .owl-stage {
        position: relative;
        -ms-touch-action: pan-Y;

        &:after {
            content: ".";
            display: block;
            clear: both;
            visibility: hidden;
            line-height: 0;
            height: 0;
        }
    }

    .owl-stage-outer {
        position: relative;
        overflow: hidden;
        /* fix for flashing background */
        transform: translate3d(0px, 0px, 0px);
    }

    .owl-nav {
        width: 100%;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;

        &.disabled {
            display: none;
        }

        .owl-prev,
        .owl-next,
        .owl-dot {
            cursor: pointer;
            cursor: hand;
            user-select: none;
            transition: color 400ms ease;
        }

        .owl-prev,
        .owl-next {
            color: @carousel-nav-color;
            font-size: @carousel-nav-size;
            position: absolute;
            top: 0;

            &:before {
                font-family: @font-family-icon;
            }

            &:hover {
                color: @carousel-hover-nav-color;
            }
        }

        .owl-prev {
            left: 0;

            &:before {
                content: @carousel-prev-icon;
            }
        }

        .owl-next {
            right: 0;

            &:before {
                content: @carousel-next-icon;
            }
        }
    }

    .owl-dots {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 10px;
        text-align: center;

        .owl-dot {
            display: inline-block;

            &.active {
                span {
                    background-color: @carousel-hover-nav-color;
                }
            }

            span {
                display: inline-block;
                background: @carousel-nav-color;
                height: 12px;
                width: 12px;
                margin: 0 5px;
                border-radius: 50%;
            }
        }
    }

    .owl-refresh .owl-item {
        display: none;
    }

    .owl-item {
        position: relative;
        min-height: 1px;
        float: left;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        user-select: none;

        img {
            display: block;
            width: 100%;
            max-width: none;
        }

        // Force width of 100% to internal container
        & > * {
            width: 100%;
        }
    }

    &.owl-text-select-on .owl-item {
        user-select: auto;
    }

    &.owl-grab {
        cursor: move;
        cursor: grab;
    }

    &.owl-rtl {
        direction: rtl;
    }

    &.owl-rtl .owl-item {
        float: right;
    }

}

// Owl Carousel - Auto Height Plugin
.owl-height {
    transition: height 500ms ease-in-out;
}

// Owl Carousel - Lazy Load Plugin
.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease;
}

// Owl Carousel - Animate Plugin
.owl-carousel {
    &.animated {
        animation-duration: 1000ms;
        animation-fill-mode: both;
    }

    &.owl-animated-in {
        z-index: 0;
    }

    &.owl-animated-out {
        z-index: 1;
    }

    &.fadeOut {
        animation-name: fadeOut;
    }

    // No JS
    .no-js & {
        display: block;
    }
}

// @todo: UI Kit - Add to an ongoing list of animations. animations.less
@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}