.star-rating-control {
    height: 16px;
    margin: 15px 0;
    font-size: 24px;
    position: relative;
    div {
        overflow: hidden;
        height: 28px;
    }
    .star-rating {
        display: inline-block;
        margin-right: 3px;

        a {
            cursor: pointer;
            &:before {
                font-family: 'catalyst';
                display: block;
                content: '\f006';
                color: @brand-primary;

            }
        }
        &.star-rating-hover,
        &.star-rating-on {
            a {
                &:before {
                    content: '\f005';
                    color: @brand-primary;
                }
            }
        }
    }
    .rating-cancel {
        display: none !important;
    }
}

// Note that the width of .rating-box and .full must be equal
.rating-box {
    position: relative;
    overflow: hidden;
    width: 90px;
    height: 22px;
    color: @brand-primary;
    font-size: 0;
    margin-left: -2px;

    .star-width {
        overflow: hidden;
        position: absolute;
        top: 0;
        height: 22px;
    }
    .full {
        position: absolute;
        top: 0;
        width: 90px;
    }

    span {
        display: inline-block;
        font-size: 0;
        padding: 0 2px;

        &:before {
            font-size: 15px;
        }
    }
}


