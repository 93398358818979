.markup-form {
    margin-top: 10px;
    margin-bottom: 30px;

    .quote-items {
        margin-bottom: 20px;
    }
}


.markup-product-details.cart-details {
    .product-table td.product-image img {
        min-width: 50px;
    }

    .no-padding {
        padding: 0;
    }

    .separator i {
        line-height: 40px;
    }

    table {
        tr.item {
            td:not(:first-child){
                line-height: 40px;
            }
        }

        tfoot tr.table-tr-bottom{
            td {
                height: 25px;
                padding-top: 0;
                padding-bottom: 0;

                div {
                    padding-top: 0px;
                    padding-bottom: 0px;
                }
            }

            &:first-child {
                td {
                    padding-top: 10px;
                }
            }

            &:last-child {
                td {
                    padding-bottom: 10px;
                }
            }

            td.label-container {
                text-align: right;
                padding-right: 0;
            }

            td .subtotal-wrapper {
                width: auto;
            }

            &.mobile-content td {
                vertical-align: middle;
            }

            &.mobile-content:nth-child(7) td{
                padding-top: 10px;
                white-space: nowrap;
            }

            &:nth-child(3){
                td {
                    padding-bottom: 10px;
                }
            }

            &.mobile-content:nth-child(4) {
                td {
                    padding-top: 10px;
                }

                .item-total {
                    width: 50%;
                }
            }

            &.mobile-content {
                td:nth-child(2){
                    padding-right: 0;
                }
            }
        }

    }
}


.product-table.markup-product-items,
.product-table.quote-product-details-markup{
    th {
        padding: 9px;
    }

    td:not(:nth-child(2)) {
        padding: 20px;
    }
    #header7{
        padding: 9px 40px;
    }
}

.markup-pricing-summary {
    table {
        td:not(:first-child),
        th:not(:first-child){
            text-align: right;
            border-left: 1px solid @gray-base;
        }
        td:not(:first-child):not(:last-child),
        th:not(:first-child):not(:last-child){
            padding-right: 10px;
        }

        th {
            padding-top: 5px;
        }

        tbody tr:first-child td {
            padding-top: 5px;
        }

        .print-total,
        .print-brand-price,
        .print-markup {
            width: 15%;
        }
    }
}

.markup-other-charges {
    td {
        line-height: 40px;
    }
    td:first-child {
        padding-left: 20px;
        font-size: @font-size-h4;
    }

    td:not(:first-child){
        text-align: center;
    }

    td:nth-child(2){
        padding-left: 20px;
    }

    input {
        text-align: center;
    }

    .packaging .error {
        margin-top: 0;
    }
}

#export-markup-modal,
#print-markup-modal {
	.modal-content {
		width: 540px;
		text-align: left;
		max-width: 95%;

		li > label {
			font-size: @font-size-large;
			margin-bottom: 10px;
		}

		a {
			display: block;
			margin-top: 7px;
		}

		.option-container li {
		    margin-bottom: 10px;
		}
	}
}


#apply-markup-product-type-modal,
#apply-overall-markup-modal {
    .modal-content {
        width: 540px;
        text-align: left;
        max-width: 95%;
    }
}

#apply-markup-product-type-modal {
     .margin-container:after {
        top: 26px;
        right: 14px;
     }

     .margin-container {
        padding-bottom: 10px;

        label.error {
            position: absolute;
            top: 65px;
        }
     }
}

#apply-overall-markup-modal {
     .margin-container:after {
        top: 6px;
        right: 14px;
     }
}


.quotes-section {
    .print-markup-modal-trigger {
        display: none !important;
    }
}

.markup-edit-message {
    background-color: @brand-blue;
    margin-bottom: 30px;

    p {
        padding: 10px;
        margin-bottom: 15px;
        text-align: center;
        font-weight: @font-weight-semi-bold;
        font-size: @font-size-h5;
        color: @brand-white;
        margin: 0;

        i {
            padding-left: 5px;
        }
    }
}

.page-quote-markup .actions-tab .gor-accordion .accordion-header {
    font-size: @font-size-base;
}

.page-quote-markup .gor-modal {
    .input-text {
        margin: 5px 0 10px 0;
    }

    .button-set {
        margin-top: 15px;
    }
}

.mobile-markup-container {
    .heading {
        margin-top: 15px;
        font-size: @font-size-h5;
    }
    .mobile-markup {
        margin-top: 8px;

        .separator {
            text-align: center;
        }
    }
}

@media screen and (max-width: @screen-md) {
    .markup-product-details.cart-details {
        td:last-child {
            font-size: @font-size-base;
            font-weight: @font-weight-bold;
            vertical-align: top;
            padding-bottom: 22px;
        }
    }

    .markup-product-details.cart-details table tr.item td:last-child {
        line-height: 1;
    }

    .markup-edin-message {
        margin-bottom: 0;
    }

    .mobile-content.table-row-mobile {
        display: table-row;
    }
}

@media screen and (max-width: 640px){
    .product-table.markup-other-charges tr {
        td:first-child {
            padding: 20px 10px 20px 20px;
        }
    }

    #apply-markup-product-type-modal,
    #apply-overall-markup-modal {
        .section-header {
            font-size: 22px;
        }
    }
}

.hide-before-320 {
    display: none;
}

@media screen and (max-width: 320px){
   .markup-product-items,
   .markup-product-details {
        tr td:first-child{
            display: none !important;
       }
   }

   .markup-product-details {
       table.markup-other-charges tr td:first-child{
           display: block !important;
      }
   }


   .hide-on-320 {
        display: none ! important;
   }

   .hide-before-320 {
        display: block;
   }
}

.pricing-summary-table {
    .mobile-content {

        p {
            margin-bottom: 0;

            &:nth-child(odd){
                background-color: @gray-lighter;
            }
        }

        h5 {
            margin-bottom: 0;
            margin-top: 10px;
            font-weight: @font-weight-bold;
        }
    }

    tr.mobile-content:not(:last-child) {
        border-bottom: 1px solid @gray-base;
    }

    td:last-child {
        p {
            padding-left: 10px;

            &:nth-child(odd){
                margin-right: -20px;
                padding-right: 20px;
            }
        }
    }

    td:first-child {
        p:nth-child(odd) {
            margin-left: -20px;
            padding-left: 20px;
        }
    }

    td p:last-child {
        margin-bottom: 10px;
    }

    tr:last-child td {
        padding-bottom: 0;
    }
}

.margin-container {
    position: relative;

    &:after {
        content: "%";
        position: absolute;
        top: 0;
        right: 4px;
        font-size: 13px;
        line-height: 40px;
    }

    input {
        text-align: right;
        padding-right: 14px;
        padding-left: 5px;
    }
}

.markup-container {
    position: relative;

    &:before {
        content: "$";
        position: absolute;
        top: 0;
        left: 5px;
        font-size: 12px;
        line-height: 40px;
    }

    input {
        text-align: left;
        padding-left: 12px;
    }
}

.page-my-default-markups {
    .margin-container {
        &:after {
            line-height: 42px;
        }
    }
}

.page-quote-markup footer {
    margin-top: 30px;
}

@media screen and (max-width: @screen-md) {
    .page-my-default-markups {
        .markup-section {
            .markup-field {
                margin-bottom: 20px;
            }
        }
    }
}

@media screen and (max-width: 425px){
    #export-markup-modal .section-header{
        font-size: 20px;
    }
}

@media screen and (max-width: 340px){
    #export-markup-modal .section-header{
        font-size: 18px;
    }
}