//
// Modal
// --------------------------------------------------

//
// Define Modal
// --------------------------------------------------
.gor-modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    outline: 0; // Prevent Chrome on Windows from adding a focus outline.
    -webkit-overflow-scrolling: touch;
    background-color: @modal-backdrop-bg-fallback;
    background-color: @modal-backdrop-bg;
    transition: 0.3s ease-out;
    opacity: 0;

    &.gor-active {
        overflow-x: hidden;
        overflow-y: auto;
        opacity: 1;

        .modal-content{
            top: 0;
        }
    }
    &.gor-inactive {
        opacity: 0;
    }
}
.modal-content{
    position: relative;
    transition: top 0.6s cubic-bezier(0.7, 0.01, 0.3, 1) 0.2s;

    top: -100px;
    width: @modal-sm;
    margin: @modal-content-margin;
    padding: @modal-content-padding;
    background-color: @modal-content-bg;
    border: @modal-content-border;
    border-radius: @modal-content-border-radius;
    background-clip: padding-box;
    // Remove focus outline from opened modal
    outline: 0;
}
.modal-close{
    .button-reset();
    min-width: auto;
    background-color: transparent;
    display: block;
    float: right;
    padding: 0;
    margin-right: 10px;
    font-size: @font-size-base;
    color: @brand-blue;
}

.dark-modal {
    .modal-content {
        background-color: rgba(0, 0, 0, 0.75);
        width: 100%;
        max-width: 400px;
        label,
        a,
        p {
            color: @brand-white;
            font-weight: 700;
        }
    }

    .modal-wrapper {
        color: @brand-white;
        padding: 10px 30px 30px;
    }
}


.confirm-modal {
	.modal-content {
		max-width: 520px;

		.confirm-content {
		    padding-bottom: 30px;
		}

		.section-header {
		    padding-left: 29px;
		}
	}

	.modal-wrapper {
		padding: 30px;
	}

	.buttons {
		margin-top: 16px;
	}
}

.modal-popup__standard-text {
    font-family: @font-family-sans-serif;
}

.modal-popup__standard-text--vertical-offset {
    line-height: 40px;
    margin: 0;
}

.modal-popup__standard-text--bottom-offset {
	margin-bottom: 0;
	line-height: 2;
}

.modal-popup__standard-text--error {
	color: @brand-red;
}

.copy-quote-popup__wrapper {
	padding-right: @indent-sm;
	line-height: 40px;
}

.copy-quote-popup__list-wrapper {
	margin-bottom: @indent-lg;
}

.copy-quote-popup__list,
.copy-quote-popup__error-list{
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.copy-quote-popup__list {
	display: inline-block;
	width: 100%;

	&:not(:first-of-type){
		margin-left: @indent-sm;
	}
}

.modal-popup--text-bold {
	font-weight: @font-weight-bold;
	max-width: 285px;
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
	vertical-align: top;
}

.copy-quote-popup__error-number {
	color: @brand-red;
	padding-left: @indent-xs;
	display: inline-block;
	vertical-align: top;
}

.copy-quote-popup__error-list-wrapper {
	margin-bottom: @indent-lg;

	& + .copy-quote-popup__error-list-wrapper {
		margin-bottom: 0;
	}
}

.copy-quote-popup__icon {
	font-size: 30px;
	color: @brand-blue;
	position: relative;
}

.copy-quote-popup__buttons {
	display: inline-block;
	margin-top: @indent-md;
	.button {
		max-width: 148px;
		padding: 12px 14px;
	}
}

.copy-quote-popup__button--link {
	display: block;
	margin-top: @indent-sm;
}

.copy-quote-popup__cancel {
	margin-top: @indent-sm;
}

.copy-quote-popup__error-title {
	margin: 0;
	padding: @indent-md;
	background-color: @msg-error-bg;
	color: @msg-error-color;
	line-height: 16px;
}

.copy-quote-popup__warn-title {
	margin: 0;
	padding: @indent-md;
	background-color: @brand-orange;
	color: @msg-error-color;
	line-height: 16px;
}

.copy-quote-popup__error-list {

	&:last-of-type {
		margin-bottom: 25px;
	}
}

.copy-quote-popup__error-border {
	border: 2px solid @gray;
	border-top: none;
	padding-bottom: @indent-lg;
}

.copy-quote-popup__item-error {
	padding: @indent-xs 0;
	position: relative;
	cursor: pointer;

	&:first-of-type {
		border-bottom: @modal-item-border;
	}
}

.copy-quote-popup__table-wrapper {
	margin: 0 @indent-md;
}

.copy-quote-popup__item-error--hide {
	display: none;
}

.copy-quote-popup__item-table {
	width: 100%;

	tr {
		border-bottom: @table-head-border;
	}

	td {
		padding: 5px 10px;
		font-size: @font-size-small;
	}

	thead {

		tr {
			border-bottom: none;
		}

		td {
			font-weight: @font-weight-bold;
			background-color: @table-head-bg;
		}
	}
}

.copy-quote-popup__item-cell--right-border {
	border-right: @table-head-border;
}

.copy-quote-popup__print {
	text-align: right;
	margin: 0 @indent-sm 0 0;
}

.copy-quote-popup__print-text,
.copy-quote-popup__print-icon {
	cursor: pointer;
}

.copy-quote-popup__print-icon {
	padding-left: 5px;
	font-size: 20px;
}

.copy-quote-popup__item--name {
	word-break: break-all;
	hyphens: auto;
}

.copy-quote-popup__button-continue {
	margin-right: 25px;
}

.copy-quote-popup {

	.gor-accordion {

		.accordion-header {
			background: @brand-white;
			font-size: @font-size-base;
			color: @brand-black;
			padding: @indent-sm 14px;

			&:before {
				display: none;
			}

			&:after {
				color: @brand-black;
			}
		}
	}
}
.confirmation-popups .button-set .button {
	min-width: 46%;
	margin: 0 5px;
	display: inline-block;
}
// overriding:
.modal-popup {

	.modal-content {

		.copy-quote-popup__item {

            a {
				margin: 0;
			}
		}

		.copy-quote-popup__print-text {
			margin: 0;
			color: @text-color;
			text-decoration: none;
		}
	}
}

.modal-popup--error {
	.section-inner {
		background-color: @brand-red;
	}

	.error-article,
	.error-message {
		color: @brand-white;
		font-weight: @font-weight-bold;
		text-align: center;
		margin: 0;
	}

	.error-article {
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid @brand-white;
	}
}

.modal-additional-options {
	label {
		font-size: @font-size-base;
	}
}

//Medium Display
@media (min-width: @screen-md) {
    .modal-content{
        width: @modal-md;
    }
}
//Large Display
@media (min-width: @screen-lg) {
    .modal-content{
        width: @modal-lg;
    }
}

@media (max-width: @screen-xs) {
	// overriding
	.copy-quote-popup {
		.modal-content {
			.copy-quote-popup__buttons {
				text-align: center;

				.button {
					float: none;
				}
			}
		}
	}
}

@media screen and (max-width: @screen-sm) {
	.modal-popup--text-bold {
		max-width: 65%;
	}
	.copy-quote-popup__wrapper {
		line-height: 1;
	}
	.copy-quote-popup__icon {
		top: -11px;
	}
}

@media screen and (max-width: 320px) {
	// override
	.modal-popup {
		.modal-content {
			.copy-quote-popup__item {
				a {
					line-height: 1;
				}
			}
		}
	}
	.copy-quote-popup {

		.modal-content {

			.copy-quote-popup__buttons {

				.button {
					width: 100%;
					max-width: 100%;
				}
			}
		}
	}
}
