// Base Class Definition
// -------------------------

.@{fa-css-prefix},
[class^="@{fa-css-prefix}-"],
[class*=" @{fa-css-prefix}-"] {
  display: inline-block;
  font: normal normal normal @font-size-base/@line-height-base FontAwesome; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
