.page-productDetails {
	background-color: @brand-white;
}

.bundling {
	position: relative;
	margin-top: 30px;

	label span {
		font-weight: @font-weight-normal;
	}

	.results {
		display: none;
		position: absolute;
		top: 100%;
		left: 0 !important;
		border: 2px solid @gray;
		border-top: none;
		border-radius: 0;
		box-shadow: 0 10px 6px -6px #777;
		margin: 0;
		width: 100% !important;
		padding: 0;
		z-index: 1;
		background-color: @brand-white;

		span {
			display: block;
			height: 40px;
			padding: 8px 10px;

			&:hover {
				color: @brand-blue;
				font-weight: @font-weight-bold;
				background-color: @gray-lighter;
			}
		}
	}
}

.packaging {
	margin-top: 30px;
}

