//
// Breadcrumbs
// --------------------------------------------------

//
// Define Breadcrumbs
// --------------------------------------------------
.breadcrumbs{
    &:extend(.reset-list);
    margin: 16px 0 7px 0;
    padding: @breadcrumb-padding;
    font-size: 0;
    line-height: 16px;


    li{
        display: inline;
        color: @breadcrumb-color;
        line-height: 0;

        &:after {
            font-size: @breadcrumb-font-size;
            content: @breadcrumb-separator;
            padding: 0 12px;
        }

        &:last-child,
        &.active {
            color: @breadcrumb-active-color;
            
            &:after {
                display: none;
            }
        }
    }
    span {
        font-weight: @font-weight-normal;
        font-size: @breadcrumb-font-size;
    }

    a{
        color: inherit;
        text-decoration: none;
        font-size: @breadcrumb-font-size;
        font-weight: @font-weight-normal;

        .last {
            color: @breadcrumb-active-color;
        }
    }
    strong{
        font-weight: normal;
    }
}

@media screen and (max-width: @screen-sm) {
    .breadcrumbs {
        .clearfix;

        li {
            &:after {
                font-size: @font-size-small;
            }
        }

        a,
        span {
            font-size: @font-size-small;
        }
    }

}

