//
// Messages
// --------------------------------------------------

//
// Mixins
// --------------------------------------------------
.msg-variant(@color; @background; @border) {
    color: @color;
    background-color: @background;
    border-color: @border;
}


//
// Define Messages
// --------------------------------------------------
.messages{
    &:extend(.reset-list);

    > li {
        margin-bottom: @indent-md;
    }

    ul{
        &:extend(.reset-list, .container-fluid);
    }

    li.error-msg{
        padding: @msg-default-padding;
        margin-bottom: @indent-md;
        border: @msg-default-border;
        border-radius: @msg-default-border-radius;
        text-align: center;
        font-weight: @font-weight-semi-bold;
        .msg-variant(@msg-error-color, @msg-error-bg, @msg-error-border-color);
    }

    li.success-msg {
        padding: @msg-default-padding;
        margin-bottom: @indent-md;
        border: @msg-default-border;
        border-radius: @msg-default-border-radius;
        text-align: center;
        font-weight: @font-weight-semi-bold;
        .msg-variant(@msg-success-color, @msg-success-bg, @msg-success-border-color);
    }
    li.note-msg{
        padding: @msg-default-padding;
        margin-bottom: @indent-md;
        border: @msg-default-border;
        border-radius: @msg-default-border-radius;
        text-align: center;
        font-weight: @font-weight-semi-bold;
        .msg-variant(@msg-note-color, @msg-note-bg, @msg-note-border-color);
    }
}

