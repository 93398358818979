//
// Custom Selects
// --------------------------------------------------

//
// Define Custom Selects
// --------------------------------------------------
.custom-styled-select {
    position: relative;
    width: 100%;

    .outer{
        width: 100%;
        padding: @select-padding;
        font-size: @select-font-size;
        line-height: @select-line-height;
        border: @select-border;
        border-radius: @select-border-radius;
        cursor: pointer;

        color: @select-color;
        background-color: @select-bg;

        &:after{
            position: absolute;
            top: 0;
            right: 0;
            line-height: @select-line-height;
            padding: 9px 15px 9px 15px;
            color: @select-icon-color;
            content: @select-icon;
            .icon;
            border: 1px solid transparent;
            font-size: 14px;
        }

        &.open {
            &:after {
                content: @select-icon-open;
            }
        }

        &.focus:not(.open) {
            border-color: darken(@input-default-border-color, 10%);
        }
    }
    .inner {
        float: none;
        vertical-align: top;
        white-space: nowrap;
        //padding-right: (@select-padding * 1.5);
        //text-transform: capitalize;
        width: 100%;

        .inner-text {
            width: 100%;
            display: block;
            padding-right: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    select{
        -webkit-appearance: none;
        width: 100%;
        height: @select-height;
        // Prevent default select behavior on iOS/Mobile
        visibility: hidden;
    }
    &.error{
        .outer{
            border-color: @brand-red;
        }
    }
    &.disabled{
        select{
            cursor: not-allowed;
        }
        .outer {
            opacity: .5;
        }
    }
    & when (@select-has-options = true) {
        &.with-custom-options {
            position: relative;
            font-size: @font-size-base;
            color: #46565D;

            .outer {
                background-color: @gray-lighter;
            }

            select {
                opacity: 0;
                height: 1px;
                width: 1px;

            }

            ul.custom-options {
                list-style: none;
                margin: 0;
                padding-left: 0;
                position: absolute;
                top: 0px;
                left: 0px;
                visibility: hidden;
                opacity: 0;
                z-index: 50;
                max-height: 310px;
                overflow: auto;
                background: @brand-white;
                border-radius: 0;
                border: 2px solid @gray-base;
                border-top: none;
                box-shadow: 0 10px 6px -6px #777;
                width: 100%;
                transition: opacity 120ms ease-out, top 120ms ease-out, visibility 120ms ease-out;

                &.open {
                    visibility: visible;
                    top: 38px;
                    opacity: 1;

                    /* have to use a non-visibility transition to prevent this iOS issue (bug?): */
                    /*http://stackoverflow.com/questions/10736478/css-animation-visibility-visible-works-on-chrome-and-safari-but-not-on-ios*/
                    transition: opacity 120ms ease-out, top 120ms ease-out;
                }

                &.overflowing {
                    top: auto;
                    bottom: 40px;
                    transition: opacity 120ms ease-out, bottom 120ms ease-out, visibility 120ms ease-out;

                    &.open {
                        top: auto;
                        bottom: 50px;

                        transition: opacity 120ms ease-out, bottom 120ms ease-out;
                    }
                }

                li {
                    padding: 8px 12px;
                    color: @brand-black;
                    cursor: pointer;
                    white-space: nowrap;
                    width: 100%;
                    transition: all 150ms ease-out;
                    margin-bottom: 0;
                    text-transform: capitalize;

                    &.disabled {
                        opacity: 0.7;
                        cursor: default;
                        display: none;
                    }

                    &.selected {
                        font-weight: @font-weight-bold;
                        background-color: @select-border-color;
                        color: @brand-blue;
                    }

                    &.activated {
                        background-color: @select-border-color;
                        color: @brand-blue;
                    }

                }
            }
        }
    }
}

.mass-edit-list {
    .custom-styled-select {
        & when (@select-has-options = true) {
            &.with-custom-options {
                ul.custom-options {
                    li {
                        &.disabled {
                            display: list-item;
                            text-decoration: line-through;
                        }
                    }
                }
            }
        }
    }
}

// Custom Dropdown with checkboxes
.custom-dropdown {
    max-width: 400px;

    .gor-accordion {
        .accordion-header {
            width: 100%;
            height: auto;
            padding: @select-padding;
            font-size: 14px;
            line-height: 18px;
            border: 2px solid @gray-base;
            border-radius: 0;
            cursor: pointer;
            color: #333;
            background: @gray-lighter;

            &:before {
                display: none;
            }
        }

        .accordion-content {
            text-align: left;
            position: absolute;
            z-index: 12;
            background: @brand-white;
            left: 10px;
            right: 20px;
            top: 74px;
            box-shadow: 0 10px 6px -6px rgba(0,0,0,.25);
            width: 100%;
        }

        .accordion-content-inner {
            padding: 0;
        }
    }
.custom-dropdown__header {
    position: relative;
    background: @gray-lighter;
    max-width: 400px;
}

    &.disabled {
        opacity: .5;
        pointer-events: none;
    }
}

.custom-dropdown__options {
    background-color: @brand-white;
    border-left: @menu-dropdown-border;
    border-right: @menu-dropdown-border;
    border-bottom: @menu-dropdown-border;
    box-shadow: @menu-dropdown-shadow;
    padding-bottom: @indent-sm;
    transition: opacity .3s ease-out, top .12s ease-out;
}

.custom-dropdown__options--show {
    visibility: visible;
    opacity: 1;
    border-top: none;
}

.custom-dropdown__trigger {
    display: block;
    position: relative;
    width: 100%;
    padding: @select-padding;
    font-size: @font-size-h6;
    line-height: 18px;
    border: 2px solid @gray-base;
    border-radius: 0;
    cursor: pointer;
    color: @brand-black;
    text-decoration: none;
    font-weight: @font-weight-normal;
}

.custom-dropdown__arrow {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 18px;
    padding: 6px @indent-md;
    color: @brand-black;
    content: @fa-var-chevron-down;
    display: inline-block;
    font: normal normal normal 14px/1.42857143 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: 1px solid transparent;
    font-size: @font-size-base;
}

.custom-dropdown__list-item {
    padding: @indent-sm 0;
    margin: 0;

    &:hover {
        background: @gray-lighter;
    }
}

.custom-dropdown__list-item--first {
    background: @gray-lighter;
    padding: @select-padding;
    font-weight: @font-weight-bold;
    color: @link-color;
}

@media screen and (max-width: @screen-md) {
    .custom-dropdown {

        .gor-accordion {

            .accordion-content {
                left: 0;
            }
        }

        &.no-padding-mobile {
            .gor-accordion {

                .accordion-content {
                    left: 10px;
                }
            }
        }
    }
}