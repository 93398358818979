// 
// Footer
// --------------------------------------------------
.footer {
    .footer-top {
        background-color: @gray-darker;
        font-weight: bold;
        font-size: @font-size-small;
        min-height: 130px;
        padding: 17px 0;
        text-align: center;

        .footer__nav--container {
            text-align: left;
            display: inline-block;
            margin-right: percentage(123/980);
            vertical-align: top;


            &:last-child {
                margin-right: 0;
            }
        }

        ul {
            font-size: @font-size-small;
        }

        li {
            text-align: left;
            color: @gray-lighter;
            line-height: 16px;
            font-weight: @font-weight-normal;
        }

        a {
            color: @gray-lighter;
            font-weight: @font-weight-normal;
            text-decoration: none;
            white-space: nowrap;
            display: inline-block;

            &[title*=Call] {
                cursor: default;

                &:hover {
                    text-decoration: none;
                    font-weight: @font-weight-normal;
                }
            }

            &.main-link {
                font-weight: @font-weight-bold;
            }

            &:hover {
                color: @brand-white;
                font-weight: @font-weight-bold;
                text-decoration: underline;
            }

            &:after {
                display:block;
                content:attr(title);
                font-weight:bold;
                height:0;
                color:transparent;
                overflow:hidden;
                visibility:hidden;
            }
        }



        .title {
            color: @brand-white;
            text-align: left;
            margin-bottom: 3px;
        }

        .footer__nav--container:nth-child(1) {
            a {
                font-weight: @font-weight-bold;
            }
            .title {
                display: none;
                text-align: left;
            }
        }

        .customer-service a {
            text-decoration: none;

            &:hover {
                font-weight: @font-weight-bold;
                text-decoration: underline;
            }
        }
    }

    .footer-bottom {
        background-color: @gray-lighter;
        min-height: 50px;
        font-size: 0;
        text-align: center;
        padding: 0;

        .copyright {
            display: inline-block;
            color: @gray-darker;
            font-size: @font-size-small;
            margin-right: 11px;

            span {
                color: @gray-darker;
            }
        }

        .mobile-copyright {
            display: none;
        }

        .footer__nav--container {
            display: inline-block;

            ul {

                li {
                    display: inline-block;

                    &:before {
                        font-size: @font-size-small;
                        content: "|";
                        display: inline-block;
                    }

                    a {
                        font-size: @font-size-small;
                        color: @gray-darker;
                        display: inline-block;
                        font-weight: @font-weight-normal;
                        padding: 13px 12px;

                        &:hover {
                            color: @brand-white;
                            background-color: @gray-darker;
                        }
                    }
                }
            }
        }
    }
}

//
// Variables
// --------------------------------------------------

//
// If we are working with responsive, compile this
// --------------------------------------------------

& when (@is-responsive = true) {
	@media screen and (max-width: 700px) {

		.footer .footer-top {
			overflow-x: hidden;
			padding: 0;

			.container-fluid {
				padding: 0;
			}

			.title {
				display: block;
				margin-bottom: 0;
			}

			.footer__nav--container {
				width: 100%;
			}

			.gor-accordion {
				display: block;
				width: 100%;
				padding: 0;
				margin: 0;

				.accordion-header {
					font-size: @font-size-base;
					font-weight: @font-weight-bold;
					line-height: 16px;
					padding-top: 15px;
					padding-bottom: 15px;
					height: auto;
					&:before {
						display: none;
					}
				}

				&:last-child {
					margin-bottom: 15px;
				}

				ul {
				    li {
				        &:first-child {
				            margin-top: 0;
				        }
				    }
				}
			}

			ul {
				display: block;

				li {
					display: block;
					font-size: @font-size-base;
					padding: 15px 0 15px 20px;
					margin: 0;

					&:first-child {
						margin-top: 15px;
					}
				}
			}
		}

		.footer-bottom {
			height: auto;
			text-align: center;

			.copyright {
				display: none;
				width: 100%;
				font-size: 11px;
				padding-bottom: 12px;
			}

			.mobile-copyright {
				display: inline-block;
			}

			.footer__nav--container ul {
				width: 100%;
				margin-top: 13px;

				li {
					float: none;
					display: inline-block;
					border-right: none;

					a {
						font-size: @font-size-base;
						text-decoration: none;
						padding-bottom: 10px;
						padding-top: 0;
						line-height: 1;

						&:hover,
						&:focus {
							background-color: transparent;
							color: @gray-darker;
						}
					}

					&:before {
						display: none;
					}
				}
			}
		}
	}

	@media screen and (min-width: 768px) {
		.footer {
			.footer-top {
				.container-fluid {
					display: flex;
				}

				.footer__nav--container {
					display: block;
					flex: 1;
					margin-right: 0;
				}
			}
		}
	}
}