.break-word {
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-word;
}
#_asm * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
#_asm *:before,
#_asm *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
#_asm a {
	color: #428bca;
	text-decoration: none;
}
#_asm a:hover,
#_asm a:focus {
	color: #2a6496;
	text-decoration: underline;
}
#_asm a:focus {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
#_asm figure {
	margin: 0;
}
#_asm img {
	vertical-align: middle;
}
#_asm .img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
}
#_asm .img-thumbnail {
	padding: 4px;
	line-height: 1.42857143;
	background-color: #ffffff;
	border: 1px solid #dddddd;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	display: inline-block;
	max-width: 100%;
	height: auto;
}
#_asm hr {
	margin-top: 20px;
	margin-bottom: 20px;
	border: 0;
	border-top: 1px solid #eeeeee;
}
#_asm .sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
#_asm .container {
	margin-right: auto;
	margin-left: auto;
	position: relative;
	width: 1400px;
}
@media (max-width: 667px) {
	#_asm .container {
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media (min-width: 10px) {
	#_asm .container {
		width: auto;
	}
}
@media (min-width: 668px) {
	#_asm .container {
		width: 100%;
	}
}
@media (min-width: 1050px) {
	#_asm .container {
		width: 1050px;
	}
}
@media (min-width: 1400px) {
	#_asm .container {
		width: 1400px;
	}
}
#_asm .container-fluid {
	margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
}
#_asm .row {
	margin-left: -15px;
	margin-right: -15px;
}
#_asm .col-xs-1, #_asm .col-sm-1, #_asm .col-md-1, #_asm .col-lg-1, #_asm .col-xs-2, #_asm .col-sm-2, #_asm .col-md-2, #_asm .col-lg-2, #_asm .col-xs-3, #_asm .col-sm-3, #_asm .col-md-3, #_asm .col-lg-3, #_asm .col-xs-4, #_asm .col-sm-4, #_asm .col-md-4, #_asm .col-lg-4, #_asm .col-xs-5, #_asm .col-sm-5, #_asm .col-md-5, #_asm .col-lg-5, #_asm .col-xs-6, #_asm .col-sm-6, #_asm .col-md-6, #_asm .col-lg-6, #_asm .col-xs-7, #_asm .col-sm-7, #_asm .col-md-7, #_asm .col-lg-7, #_asm .col-xs-8, #_asm .col-sm-8, #_asm .col-md-8, #_asm .col-lg-8, #_asm .col-xs-9, #_asm .col-sm-9, #_asm .col-md-9, #_asm .col-lg-9, #_asm .col-xs-10, #_asm .col-sm-10, #_asm .col-md-10, #_asm .col-lg-10, #_asm .col-xs-11, #_asm .col-sm-11, #_asm .col-md-11, #_asm .col-lg-11, #_asm .col-xs-12, #_asm .col-sm-12, #_asm .col-md-12, #_asm .col-lg-12 {
	position: relative;
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
}
#_asm .col-xs-1, #_asm .col-xs-2, #_asm .col-xs-3, #_asm .col-xs-4, #_asm .col-xs-5, #_asm .col-xs-6, #_asm .col-xs-7, #_asm .col-xs-8, #_asm .col-xs-9, #_asm .col-xs-10, #_asm .col-xs-11, #_asm .col-xs-12 {
	float: left;
}
#_asm .col-xs-12 {
	width: 100%;
}
#_asm .col-xs-11 {
	width: 91.66666667%;
}
#_asm .col-xs-10 {
	width: 83.33333333%;
}
#_asm .col-xs-9 {
	width: 75%;
}
#_asm .col-xs-8 {
	width: 66.66666667%;
}
#_asm .col-xs-7 {
	width: 58.33333333%;
}
#_asm .col-xs-6 {
	width: 50%;
}
#_asm .col-xs-5 {
	width: 41.66666667%;
}
#_asm .col-xs-4 {
	width: 33.33333333%;
}
#_asm .col-xs-3 {
	width: 25%;
}
#_asm .col-xs-2 {
	width: 16.66666667%;
}
#_asm .col-xs-1 {
	width: 8.33333333%;
}
#_asm .col-xs-pull-12 {
	right: 100%;
}
#_asm .col-xs-pull-11 {
	right: 91.66666667%;
}
#_asm .col-xs-pull-10 {
	right: 83.33333333%;
}
#_asm .col-xs-pull-9 {
	right: 75%;
}
#_asm .col-xs-pull-8 {
	right: 66.66666667%;
}
#_asm .col-xs-pull-7 {
	right: 58.33333333%;
}
#_asm .col-xs-pull-6 {
	right: 50%;
}
#_asm .col-xs-pull-5 {
	right: 41.66666667%;
}
#_asm .col-xs-pull-4 {
	right: 33.33333333%;
}
#_asm .col-xs-pull-3 {
	right: 25%;
}
#_asm .col-xs-pull-2 {
	right: 16.66666667%;
}
#_asm .col-xs-pull-1 {
	right: 8.33333333%;
}
#_asm .col-xs-pull-0 {
	right: 0%;
}
#_asm .col-xs-push-12 {
	left: 100%;
}
#_asm .col-xs-push-11 {
	left: 91.66666667%;
}
#_asm .col-xs-push-10 {
	left: 83.33333333%;
}
#_asm .col-xs-push-9 {
	left: 75%;
}
#_asm .col-xs-push-8 {
	left: 66.66666667%;
}
#_asm .col-xs-push-7 {
	left: 58.33333333%;
}
#_asm .col-xs-push-6 {
	left: 50%;
}
#_asm .col-xs-push-5 {
	left: 41.66666667%;
}
#_asm .col-xs-push-4 {
	left: 33.33333333%;
}
#_asm .col-xs-push-3 {
	left: 25%;
}
#_asm .col-xs-push-2 {
	left: 16.66666667%;
}
#_asm .col-xs-push-1 {
	left: 8.33333333%;
}
#_asm .col-xs-push-0 {
	left: 0%;
}
#_asm .col-xs-offset-12 {
	margin-left: 100%;
}
#_asm .col-xs-offset-11 {
	margin-left: 91.66666667%;
}
#_asm .col-xs-offset-10 {
	margin-left: 83.33333333%;
}
#_asm .col-xs-offset-9 {
	margin-left: 75%;
}
#_asm .col-xs-offset-8 {
	margin-left: 66.66666667%;
}
#_asm .col-xs-offset-7 {
	margin-left: 58.33333333%;
}
#_asm .col-xs-offset-6 {
	margin-left: 50%;
}
#_asm .col-xs-offset-5 {
	margin-left: 41.66666667%;
}
#_asm .col-xs-offset-4 {
	margin-left: 33.33333333%;
}
#_asm .col-xs-offset-3 {
	margin-left: 25%;
}
#_asm .col-xs-offset-2 {
	margin-left: 16.66666667%;
}
#_asm .col-xs-offset-1 {
	margin-left: 8.33333333%;
}
#_asm .col-xs-offset-0 {
	margin-left: 0%;
}
@media (min-width: 668px) {
	#_asm .col-sm-1, #_asm .col-sm-2, #_asm .col-sm-3, #_asm .col-sm-4, #_asm .col-sm-5, #_asm .col-sm-6, #_asm .col-sm-7, #_asm .col-sm-8, #_asm .col-sm-9, #_asm .col-sm-10, #_asm .col-sm-11, #_asm .col-sm-12 {
		float: left;
	}
	#_asm .col-sm-12 {
		width: 100%;
	}
	#_asm .col-sm-11 {
		width: 91.66666667%;
	}
	#_asm .col-sm-10 {
		width: 83.33333333%;
	}
	#_asm .col-sm-9 {
		width: 75%;
	}
	#_asm .col-sm-8 {
		width: 66.66666667%;
	}
	#_asm .col-sm-7 {
		width: 58.33333333%;
	}
	#_asm .col-sm-6 {
		width: 50%;
	}
	#_asm .col-sm-5 {
		width: 41.66666667%;
	}
	#_asm .col-sm-4 {
		width: 33.33333333%;
	}
	#_asm .col-sm-3 {
		width: 25%;
	}
	#_asm .col-sm-2 {
		width: 16.66666667%;
	}
	#_asm .col-sm-1 {
		width: 8.33333333%;
	}
	#_asm .col-sm-pull-12 {
		right: 100%;
	}
	#_asm .col-sm-pull-11 {
		right: 91.66666667%;
	}
	#_asm .col-sm-pull-10 {
		right: 83.33333333%;
	}
	#_asm .col-sm-pull-9 {
		right: 75%;
	}
	#_asm .col-sm-pull-8 {
		right: 66.66666667%;
	}
	#_asm .col-sm-pull-7 {
		right: 58.33333333%;
	}
	#_asm .col-sm-pull-6 {
		right: 50%;
	}
	#_asm .col-sm-pull-5 {
		right: 41.66666667%;
	}
	#_asm .col-sm-pull-4 {
		right: 33.33333333%;
	}
	#_asm .col-sm-pull-3 {
		right: 25%;
	}
	#_asm .col-sm-pull-2 {
		right: 16.66666667%;
	}
	#_asm .col-sm-pull-1 {
		right: 8.33333333%;
	}
	#_asm .col-sm-pull-0 {
		right: 0%;
	}
	#_asm .col-sm-push-12 {
		left: 100%;
	}
	#_asm .col-sm-push-11 {
		left: 91.66666667%;
	}
	#_asm .col-sm-push-10 {
		left: 83.33333333%;
	}
	#_asm .col-sm-push-9 {
		left: 75%;
	}
	#_asm .col-sm-push-8 {
		left: 66.66666667%;
	}
	#_asm .col-sm-push-7 {
		left: 58.33333333%;
	}
	#_asm .col-sm-push-6 {
		left: 50%;
	}
	#_asm .col-sm-push-5 {
		left: 41.66666667%;
	}
	#_asm .col-sm-push-4 {
		left: 33.33333333%;
	}
	#_asm .col-sm-push-3 {
		left: 25%;
	}
	#_asm .col-sm-push-2 {
		left: 16.66666667%;
	}
	#_asm .col-sm-push-1 {
		left: 8.33333333%;
	}
	#_asm .col-sm-push-0 {
		left: 0%;
	}
	#_asm .col-sm-offset-12 {
		margin-left: 100%;
	}
	#_asm .col-sm-offset-11 {
		margin-left: 91.66666667%;
	}
	#_asm .col-sm-offset-10 {
		margin-left: 83.33333333%;
	}
	#_asm .col-sm-offset-9 {
		margin-left: 75%;
	}
	#_asm .col-sm-offset-8 {
		margin-left: 66.66666667%;
	}
	#_asm .col-sm-offset-7 {
		margin-left: 58.33333333%;
	}
	#_asm .col-sm-offset-6 {
		margin-left: 50%;
	}
	#_asm .col-sm-offset-5 {
		margin-left: 41.66666667%;
	}
	#_asm .col-sm-offset-4 {
		margin-left: 33.33333333%;
	}
	#_asm .col-sm-offset-3 {
		margin-left: 25%;
	}
	#_asm .col-sm-offset-2 {
		margin-left: 16.66666667%;
	}
	#_asm .col-sm-offset-1 {
		margin-left: 8.33333333%;
	}
	#_asm .col-sm-offset-0 {
		margin-left: 0%;
	}
}
@media (min-width: 1050px) {
	#_asm .col-md-1, #_asm .col-md-2, #_asm .col-md-3, #_asm .col-md-4, #_asm .col-md-5, #_asm .col-md-6, #_asm .col-md-7, #_asm .col-md-8, #_asm .col-md-9, #_asm .col-md-10, #_asm .col-md-11, #_asm .col-md-12 {
		float: left;
	}
	#_asm .col-md-12 {
		width: 100%;
	}
	#_asm .col-md-11 {
		width: 91.66666667%;
	}
	#_asm .col-md-10 {
		width: 83.33333333%;
	}
	#_asm .col-md-9 {
		width: 75%;
	}
	#_asm .col-md-8 {
		width: 66.66666667%;
	}
	#_asm .col-md-7 {
		width: 58.33333333%;
	}
	#_asm .col-md-6 {
		width: 50%;
	}
	#_asm .col-md-5 {
		width: 41.66666667%;
	}
	#_asm .col-md-4 {
		width: 33.33333333%;
	}
	#_asm .col-md-3 {
		width: 25%;
	}
	#_asm .col-md-2 {
		width: 16.66666667%;
	}
	#_asm .col-md-1 {
		width: 8.33333333%;
	}
	#_asm .col-md-pull-12 {
		right: 100%;
	}
	#_asm .col-md-pull-11 {
		right: 91.66666667%;
	}
	#_asm .col-md-pull-10 {
		right: 83.33333333%;
	}
	#_asm .col-md-pull-9 {
		right: 75%;
	}
	#_asm .col-md-pull-8 {
		right: 66.66666667%;
	}
	#_asm .col-md-pull-7 {
		right: 58.33333333%;
	}
	#_asm .col-md-pull-6 {
		right: 50%;
	}
	#_asm .col-md-pull-5 {
		right: 41.66666667%;
	}
	#_asm .col-md-pull-4 {
		right: 33.33333333%;
	}
	#_asm .col-md-pull-3 {
		right: 25%;
	}
	#_asm .col-md-pull-2 {
		right: 16.66666667%;
	}
	#_asm .col-md-pull-1 {
		right: 8.33333333%;
	}
	#_asm .col-md-pull-0 {
		right: 0%;
	}
	#_asm .col-md-push-12 {
		left: 100%;
	}
	#_asm .col-md-push-11 {
		left: 91.66666667%;
	}
	#_asm .col-md-push-10 {
		left: 83.33333333%;
	}
	#_asm .col-md-push-9 {
		left: 75%;
	}
	#_asm .col-md-push-8 {
		left: 66.66666667%;
	}
	#_asm .col-md-push-7 {
		left: 58.33333333%;
	}
	#_asm .col-md-push-6 {
		left: 50%;
	}
	#_asm .col-md-push-5 {
		left: 41.66666667%;
	}
	#_asm .col-md-push-4 {
		left: 33.33333333%;
	}
	#_asm .col-md-push-3 {
		left: 25%;
	}
	#_asm .col-md-push-2 {
		left: 16.66666667%;
	}
	#_asm .col-md-push-1 {
		left: 8.33333333%;
	}
	#_asm .col-md-push-0 {
		left: 0%;
	}
	#_asm .col-md-offset-12 {
		margin-left: 100%;
	}
	#_asm .col-md-offset-11 {
		margin-left: 91.66666667%;
	}
	#_asm .col-md-offset-10 {
		margin-left: 83.33333333%;
	}
	#_asm .col-md-offset-9 {
		margin-left: 75%;
	}
	#_asm .col-md-offset-8 {
		margin-left: 66.66666667%;
	}
	#_asm .col-md-offset-7 {
		margin-left: 58.33333333%;
	}
	#_asm .col-md-offset-6 {
		margin-left: 50%;
	}
	#_asm .col-md-offset-5 {
		margin-left: 41.66666667%;
	}
	#_asm .col-md-offset-4 {
		margin-left: 33.33333333%;
	}
	#_asm .col-md-offset-3 {
		margin-left: 25%;
	}
	#_asm .col-md-offset-2 {
		margin-left: 16.66666667%;
	}
	#_asm .col-md-offset-1 {
		margin-left: 8.33333333%;
	}
	#_asm .col-md-offset-0 {
		margin-left: 0%;
	}
}
@media (min-width: 1400px) {
	#_asm .col-lg-1, #_asm .col-lg-2, #_asm .col-lg-3, #_asm .col-lg-4, #_asm .col-lg-5, #_asm .col-lg-6, #_asm .col-lg-7, #_asm .col-lg-8, #_asm .col-lg-9, #_asm .col-lg-10, #_asm .col-lg-11, #_asm .col-lg-12 {
		float: left;
	}
	#_asm .col-lg-12 {
		width: 100%;
	}
	#_asm .col-lg-11 {
		width: 91.66666667%;
	}
	#_asm .col-lg-10 {
		width: 83.33333333%;
	}
	#_asm .col-lg-9 {
		width: 75%;
	}
	#_asm .col-lg-8 {
		width: 66.66666667%;
	}
	#_asm .col-lg-7 {
		width: 58.33333333%;
	}
	#_asm .col-lg-6 {
		width: 50%;
	}
	#_asm .col-lg-5 {
		width: 41.66666667%;
	}
	#_asm .col-lg-4 {
		width: 33.33333333%;
	}
	#_asm .col-lg-3 {
		width: 25%;
	}
	#_asm .col-lg-2 {
		width: 16.66666667%;
	}
	#_asm .col-lg-1 {
		width: 8.33333333%;
	}
	#_asm .col-lg-pull-12 {
		right: 100%;
	}
	#_asm .col-lg-pull-11 {
		right: 91.66666667%;
	}
	#_asm .col-lg-pull-10 {
		right: 83.33333333%;
	}
	#_asm .col-lg-pull-9 {
		right: 75%;
	}
	#_asm .col-lg-pull-8 {
		right: 66.66666667%;
	}
	#_asm .col-lg-pull-7 {
		right: 58.33333333%;
	}
	#_asm .col-lg-pull-6 {
		right: 50%;
	}
	#_asm .col-lg-pull-5 {
		right: 41.66666667%;
	}
	#_asm .col-lg-pull-4 {
		right: 33.33333333%;
	}
	#_asm .col-lg-pull-3 {
		right: 25%;
	}
	#_asm .col-lg-pull-2 {
		right: 16.66666667%;
	}
	#_asm .col-lg-pull-1 {
		right: 8.33333333%;
	}
	#_asm .col-lg-pull-0 {
		right: 0%;
	}
	#_asm .col-lg-push-12 {
		left: 100%;
	}
	#_asm .col-lg-push-11 {
		left: 91.66666667%;
	}
	#_asm .col-lg-push-10 {
		left: 83.33333333%;
	}
	#_asm .col-lg-push-9 {
		left: 75%;
	}
	#_asm .col-lg-push-8 {
		left: 66.66666667%;
	}
	#_asm .col-lg-push-7 {
		left: 58.33333333%;
	}
	#_asm .col-lg-push-6 {
		left: 50%;
	}
	#_asm .col-lg-push-5 {
		left: 41.66666667%;
	}
	#_asm .col-lg-push-4 {
		left: 33.33333333%;
	}
	#_asm .col-lg-push-3 {
		left: 25%;
	}
	#_asm .col-lg-push-2 {
		left: 16.66666667%;
	}
	#_asm .col-lg-push-1 {
		left: 8.33333333%;
	}
	#_asm .col-lg-push-0 {
		left: 0%;
	}
	#_asm .col-lg-offset-12 {
		margin-left: 100%;
	}
	#_asm .col-lg-offset-11 {
		margin-left: 91.66666667%;
	}
	#_asm .col-lg-offset-10 {
		margin-left: 83.33333333%;
	}
	#_asm .col-lg-offset-9 {
		margin-left: 75%;
	}
	#_asm .col-lg-offset-8 {
		margin-left: 66.66666667%;
	}
	#_asm .col-lg-offset-7 {
		margin-left: 58.33333333%;
	}
	#_asm .col-lg-offset-6 {
		margin-left: 50%;
	}
	#_asm .col-lg-offset-5 {
		margin-left: 41.66666667%;
	}
	#_asm .col-lg-offset-4 {
		margin-left: 33.33333333%;
	}
	#_asm .col-lg-offset-3 {
		margin-left: 25%;
	}
	#_asm .col-lg-offset-2 {
		margin-left: 16.66666667%;
	}
	#_asm .col-lg-offset-1 {
		margin-left: 8.33333333%;
	}
	#_asm .col-lg-offset-0 {
		margin-left: 0%;
	}
}
.ASM_close {
	float: right;
	font-size: 25px;
	font-weight: 500;
	line-height: 10px;
	text-align: right;
	color: #fff;
	width: 20px;
	height: 20px;
	margin: 0 0 0 5px;
	padding: 0 0 5px 0;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
}
.ASM_close:hover,
.ASM_close:focus {
	color: #b4bac2;
	text-decoration: none;
	cursor: pointer;
	opacity: 0.5;
	filter: alpha(opacity=50);
}
.ASM_alert {
	padding: 5px 10px;
	margin: 5px 25px 5px 0;
	border: 1px solid transparent;
	background: #ff3b30;
	color: #fff;
	line-height: 20px;
	font-size: 16px;
}
.ASM_alert-dismissable {
	padding-right: 35px;
}
.ASM_alert-dismissable .close {
	position: relative;
	top: -2px;
	right: -21px;
	color: inherit;
}
.ASM_alert-success {
	background-color: #dff0d8;
	border-color: #d6e9c6;
	color: #3c763d;
}
.ASM_alert-success hr {
	border-top-color: #c9e2b3;
}
.ASM_alert-success .alert-link {
	color: #2b542c;
}
.ASM_alert-info {
	background-color: #d9edf7;
	border-color: #bce8f1;
	color: #31708f;
}
.ASM_alert-info hr {
	border-top-color: #a6e1ec;
}
.ASM_alert-info .alert-link {
	color: #245269;
}
.ASM_alert-warning {
	background-color: #fcf8e3;
	border-color: #faebcc;
	color: #8a6d3b;
}
.ASM_alert-warning hr {
	border-top-color: #f7e1b5;
}
.ASM_alert-warning .alert-link {
	color: #66512c;
}
.ASM_alert-danger {
	background-color: #f2dede;
	border-color: #ebccd1;
	color: #a94442;
}
.ASM_alert-danger hr {
	border-top-color: #e4b9c0;
}
.ASM_alert-danger .alert-link {
	color: #843534;
}
#_asm .clearfix:before,
#_asm .clearfix:after,
#_asm .container:before,
#_asm .container:after,
#_asm .container-fluid:before,
#_asm .container-fluid:after,
#_asm .row:before,
#_asm .row:after {
	content: " ";
	display: table;
}
#_asm .clearfix:after,
#_asm .container:after,
#_asm .container-fluid:after,
#_asm .row:after {
	clear: both;
}
#_asm .center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
#_asm .pull-right {
	float: right !important;
}
#_asm .pull-left {
	float: left !important;
}
#_asm .invisible {
	visibility: hidden;
}
#_asm .hide {
	display: none !important;
}
#_asm .show {
	display: block !important;
}
#_asm .text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}
#_asm .hidden {
	display: none !important;
	visibility: hidden !important;
}
#_asm .affix {
	position: fixed;
}
@-ms-viewport {
	width: device-width;
}
#_asm .visible-xs,
#_asm .visible-sm,
#_asm .visible-md,
#_asm .visible-lg {
	display: none !important;
}
@media (max-width: 667px) {
	#_asm .visible-xs {
		display: block !important;
	}
	#_asm table.visible-xs {
		display: table;
	}
	#_asm tr.visible-xs {
		display: table-row !important;
	}
	#_asm th.visible-xs,
	#_asm td.visible-xs {
		display: table-cell !important;
	}
	.ASM_alert {
		width: 92%;
	}
}
@media (min-width: 668px) and (max-width: 1049px) {
	#_asm .visible-sm {
		display: block !important;
	}
	#_asm table.visible-sm {
		display: table;
	}
	#_asm tr.visible-sm {
		display: table-row !important;
	}
	#_asm th.visible-sm,
	#_asm td.visible-sm {
		display: table-cell !important;
	}
	#_asm .container-fluid{
		padding: 0;
	}
}

@media (min-width: 1050px) and (max-width: 1399px) {
	#_asm .visible-md {
		display: block !important;
	}
	#_asm table.visible-md {
		display: table;
	}
	#_asm tr.visible-md {
		display: table-row !important;
	}
	#_asm th.visible-md,
	#_asm td.visible-md {
		display: table-cell !important;
	}
}
@media (min-width: 1400px) {
	#_asm .visible-lg {
		display: block !important;
	}
	#_asm table.visible-lg {
		display: table;
	}
	#_asm tr.visible-lg {
		display: table-row !important;
	}
	#_asm th.visible-lg,
	#_asm td.visible-lg {
		display: table-cell !important;
	}
}
@media (max-width: 667px) {
	#_asm .hidden-xs {
		display: none !important;
	}
}
@media (min-width: 668px) and (max-width: 1049px) {
	#_asm .hidden-sm {
		display: none !important;
	}
}
@media (min-width: 1050px)  {
	#_asm .hidden-md {
		display: none !important;
	}
}
#_asm .visible-print {
	display: none !important;
}
@media print {
	#_asm .visible-print {
		display: block !important;
	}
	#_asm table.visible-print {
		display: table;
	}
	#_asm tr.visible-print {
		display: table-row !important;
	}
	#_asm th.visible-print,
	#_asm td.visible-print {
		display: table-cell !important;
	}
	#_asm .hidden-print {
		display: none !important;
	}
}
@font-face {
	font-family: 'asm_icons';
	src: url('../../common/fonts/asm_icons.eot?9817510');
	src: url('../../common/fonts/asm_icons.eot?9817510#iefix') format('embedded-opentype'),
	url('../../common/fonts/asm_icons.woff?9817510') format('woff'),
	url('../../common/fonts/asm_icons.ttf?9817510') format('truetype'),
	url('../../common/fonts/asm_icons.svg?9817510#asm_icons') format('svg');
}
.ASM_icon {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'asm_icons';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* '' */
.ASM_icon-contacts:before {
	font-family: 'asm_icons';
	content: '\e801';
	vertical-align: middle;
}
/* '' */
.ASM_icon-cart:before {
	font-family: 'asm_icons';
	content: '\e802';
	vertical-align: middle;
}
/* '' */
.ASM_icon-arrow-down:before {
	font-family: 'asm_icons';
	content: '\e803';
}
/* '' */
.ASM_icon-arrow-up:before {
	font-family: 'asm_icons';
	content: '\e804';
}
/* '' */
.ASM_icon-arrow-indicator-down:before {
	font-family: 'asm_icons';
	content: '\e805';
}
/* '' */
.ASM_icon-arrow-indicator-up:before {
	font-family: 'asm_icons';
	content: '\e806';
}
.ASM_icon-chain:before {
	font-family: 'asm_icons';
	content: '\e808';
	color: #757575;
	font-size: 37px;
}
.ASM_icon-close:before{
	font-family: 'asm_icons';
	content: '\e80d';
	color: @brand-black;
}
.ASM_icon-close:hover{
	opacity: .5;
}

/* '' */
.ASM_icon-customer-list:before {
	font-family: 'asm_icons';
	content: '\e80b';
	vertical-align: bottom;
	color: #fff;
	font-size: 28px;
	position: relative;
	top: 2px;
}
.ASM_icon-chain.ASM_chain-bind:before {
	color: #fff;
}
@media (max-width: 667px) {
	.ASM_icon-chain {
		position: absolute;
		top: auto;
		bottom: 40px;
		left: 50%;
		margin-left: -20%;
	}
	.ASM-btn-customer360{
		float: right;
		display: block;
	}
}

#_asm {
	top: 0;
	left: 0;
	right: 0;
	background: @brand-black;
	color: #000;
	z-index: 99998;
	-webkit-transition: -webkit-transform 0.3s ease-out;
	-moz-transition: -moz-transform 0.3s ease-out;
	-o-transition: -o-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
	z-index: 100;
	position: relative;
	border-bottom: 1px solid @gray-darker;
}
#_asm .ASM-btn {
	.button;
	.button-default;
	min-width: 0;
}
#_asm .ASM-btn.ASM-btn-login,
#_asm .ASM-btn.ASM-btn-bind-cart,
#_asm .ASM-btn.ASM-btn-create-account {
	.button-alternate;
	vertical-align: bottom;
}
#_asmBindForm {
	vertical-align: bottom;
}
#asmLogoutForm, #asmLogoutForm fieldset,
#_asmSessionEndForm,
#_asmBindForm,
#_asmPersonifyForm{
	display: inline-block;
	vertical-align: middle;
}
#_asmPersonifyForm {
	.ASM-btn {
		vertical-align: bottom;
	}
}
#_asm .ASM_input_error {
	position: absolute;
	color: @brand-error;
}
@media (min-width: 668px) and (max-width: 1049px) {
	#_asm .ASM-btn.ASM-btn-start-session {
		margin-left: -2px;
	}
}
@media (max-width: 667px) {
	#_asmPersonifyForm,
	#_asmBindForm {
		display: block;
	}
	#_asm .ASM-btn.ASM-btn-logout, #_asm .ASM-btn.ASM-btn-reset {
		min-width: 105px;
	}
	#_asm .ASM-btn.ASM-btn-start-session {
		margin-left: 0;
	}
}
#_asm .ASM-btn.ASM-btn-end-session {
	background: #ff3b30;
	border-color: #ff3b30;
	color: #fff;
	font-weight: 400;
	margin-top: 1px;
}
#_asm .ASM_end_session {
	vertical-align: bottom;
}
#_asm .ASM-btn.ASM-btn-bind-cart, #_asm .ASM-btn.ASM-btn-create-account {
	float: right;
	position: absolute;
	right: 16px;
	top: 22px;
	font-weight: 400;
}
@media (max-width: 667px) {
	#_asm .ASM-btn.ASM-btn-bind-cart, #_asm .ASM-btn.ASM-btn-create-account {
		top: auto;
		bottom: 0;
		right: 15px;
	}
	#_asm .ASM-btn.ASM-btn-end-session {
		margin-top: 0;
	}
}
#_asm .ASM-btn-customer-list {
	background-color: #007aff;
	border-color: #007aff;
	color: #fff;
	margin-right: 40px;
	vertical-align: bottom;
	//display: none; @todo: decide if we're keeping this button or not
}

#_asm .ASM-btn-customer-list:hover,
#_asm .ASM-btn-customer-list:focus {
	color: #fff;
	text-decoration: none;
}
@media (min-width: 668px) and (max-width: 1399px) {
	#_asm .ASM-btn-customer-list {
		margin-right: 20px;
	}
	#_asm .ASM_session .ASM-btn-customer-list .ASM_icon {
		margin-right: 0;
	}
}
#_asm button[disabled] {
	opacity: 0.45;
	filter: alpha(opacity=45);
	background: #207241;
}
#_asm .ASM-btn-customer-list.disabled {
	opacity: 0.45;
	pointer-events: none;
	cursor: default;
}
#_asm .ASM_input_holder {
	display: inline-block;
	position: relative;
	margin: 0 10px 0 0;
}
#_asm .ASM_input_holder label {
	color: #777777;
	font-weight: 600;
	font-size: 14px;
	display: block;
	text-align: left;
	margin-bottom: 1px;
}
#_asm .ASM_input_holder .ASM_autocomplete {
	display: none;
	position: absolute;
	background: #fff;
	width: 100%;
	z-index: 99999;
	border-top: 1px solid #e6e8ea;
}
#_asm .ASM_input_holder .ASM_autocomplete.ASM_autocomplete_active {
	display: block;
}
#_asm .ASM_input_holder .ASM_autocomplete ul {
	list-style: none;
	padding: 0 10px;
	margin: 0;
}
#_asm .ASM_input_holder .ASM_autocomplete ul li {
	font-size: 18px;
	line-height: 37px;
}
#_asm .ASM-input {
	.input-text;
}
#_asm .ASM-input[disabled], #_asm .ASM-input.disabled, #_asm .ASM-input[readonly], #_asm .ASM-input[readonly]:focus {
	background-color: #b4bac2;
	cursor: default;
	color: #000;
}
#_asm .ASM-input.placeholder {
	color: #757575;
}
#_asm #_asmSessionEndForm .ASM-input {
	background-color: #b4bac2;
}
#_asm #_asmSessionEndForm .ASM-input.placeholder {
	background-color: #fff;
}
#_asmSessionEndForm {
	vertical-align: bottom;
}
select#_asm .ASM-input {
	height: 40px;
	line-height: 40px;
}
textarea#_asm .ASM-input,
select[multiple]#_asm .ASM-input {
	height: auto;
}
#_asm .ASM-input:focus,
#_asm .ASM-input.ASM-input-valid,
#_asm .ASM-input.ASM-input-error {
	background: #fff;
}
#_asm .ASM-input.ASM-input-error {
	color: #ff3b30;
	border: 2px solid #ff3b30;
	padding: 5px 10px;
}
#_asm .ASM-input-error.placeholder {
	color: #ff958f;
}
#_asm .ASM_header {
	padding-top: 5px;
	padding-bottom: 15px;
}
#_asm .ASM_header .navigation.navigation--top, #_asm .ASM_session .navigation.navigation--top{
	//.full-width-padding();
}

#_asm .ASM_header .ASM_logo {
	float: left;
	font-size: 30px;
	font-weight: 300;
	line-height: 30px;
	color: #fff;
	padding: 18px 0 12px;
}
@media (max-width: 667px) {
	#_asm .ASM_header .ASM_logo span.ASM_cut_text {
		display: none !important;
	}
}
@media (min-width: 668px) and (max-width: 1399px) {
	#_asm .ASM_header .ASM_logo span.ASM_cut_text {
		display: none !important;
	}
}
#_asm .ASM_header .ASM_login {
	text-align: right;
}
#_asm .ASM_header .ASM_login .ASM-input {
	width: 200px;
}
@media (min-width: 668px) and (max-width: 1049px) {
	#_asm .ASM_header .ASM_login .ASM-input {
		width: 170px;
	}
}
#_asm .ASM_header .ASM_loggedin {
	text-align: right;
	margin-top: 21px;
	margin-right: 20px;
}
#_asm .ASM_header .ASM_loggedin .ASM_loggedin_text, .ASM_timer {
	display: inline-block;
	font-weight: 300;
	font-size: 14px;
	margin: 0 20px 0 0;
	color: #a1aebd;
}
.ASM_timer {
	margin-right: 71px;
	vertical-align: top;
}
@media (min-width: 1049px) and (max-width: 1399px) {
	.ASM_timer {
		margin-right: 88px;
	}
}
@media (min-width: 668px) and (max-width: 1049px) {
	.ASM_timer {
		margin-right: 50px;
	}
}
#_asm #sessionTimer {
	margin-right: 20px;
	font-weight: 600;
	text-align: left;
}
#_asm #sessionTimer span {
	font-weight: 300;
}
#_asm #sessionTimer .ASM_timer_count {
	display: inline-block;
	min-width: 33px;
	font-weight: 600;
}
#_asm .ASM_header .ASM_loggedin .ASM_loggedin_text .ASM_loggedin_text_name {
	font-weight: 600;
	margin-right: 20px;
}
#_asm .ASM_header .ASM_loggedin .ASM_loggedin_text .ASM_loggedin_agent_store_name{
	display: inline-block;
	vertical-align: middle;
}
#_asm .ASM_header .ASM_loggedin .ASM_loggedin_text p{
	margin:0;
	text-align: left;
}
@media (max-width: 667px) {
	#_asm .ASM_header .ASM_logo {
		float: none;
		padding: 10px 0 7px;
	}
	#_asm .ASM_header .ASM_login {
		padding-top: 10px;
	}
	#_asm .ASM_header .ASM_login .ASM_input_holder {
		display: block;
		width: 100%;
		margin-bottom: 10px;
	}
	#_asm .ASM_header .ASM_login .ASM_input_holder .ASM-input {
		display: block;
		width: 100%;
	}
	#_asm .ASM_header .ASM_loggedin {
		margin-top: 10px;
		margin-right: 0;
	}
	#_asm .ASM_header .ASM_loggedin #asmLogoutForm {
		float: right;
	}
	#_asm .ASM_control {
		margin-top: 15px;
	}
	#_asm .ASM_close_all {
		margin: 8px 0 0 0;
	}
	.ASM_timer {
		margin-right: 0;
		line-height: 40px;
	}
	#_asm .ASM_header .ASM_loggedin .ASM_loggedin_text {
		margin:0;
	}
	#_asm .ASM-btn.ASM-btn-login {
		float: left;
	}
}
#_asm .ASM_session {
	padding-top: 0;
	padding-bottom: 25px;
}
#_asm .ASM_session #_asmCustomer {
	position: relative;
}
#_asm .ASM_session span.ASM_icon {
	color: #0979ff;
	font-size: 32px;
	margin: 0 10px 0 0;
}
#_asm .ASM_session span.ASM_icon-chain {
	vertical-align: middle;
	margin: -8px 30px 0 12px;
	font-size: 27px;
}
#_asm .ASM_session .cartId {
	width: 220px;
}
@media (min-width: 668px) and (max-width: 1399px) {
	#_asm .ASM_session span.ASM_icon-chain {
		margin-left: 11px;
	}
}


#_asm .ASM_session .ASM_input_holder {
	width: 250px;
}
@media (max-width: 1049px) {
	#_asm .ASM_session .ASM_input_holder {
		width: 22vw;
	}
	#_asm .ASM_session .cartId {
		width: 20vw;
		margin-right: 10px;
	}
	#_asm .ASM-btn-customer-list {
		margin-right: 15px;
	}
}
#_asm .ASM_session .ASM_session_andor_text {
	display: inline-block;
	margin: 0 10px 8px 0;
	color: #fff;
	font-size: 19px;
	vertical-align: bottom;
}
@media (max-width: 667px) {
	#_asm .ASM_session .ASM_session_andor_text {
		display: none !important;
	}
	#_asm .ASM_session .ASM_input_holder {
		margin-bottom: 10px;
	}
}
#_asm .ASM_session .ASM_session_input_text {
	display: inline-block;
	margin: 0 60px 0 0;
	color: #a1aebd;
	font-size: 20px;
}
@media (max-width: 667px) {
	#_asm .ASM_session .ASM_input_holder {
		width: 100%;
	}
	#_asm .ASM_session span.ASM_icon-chain {
		margin-bottom: -32px;
		margin-left: -20%;
		float: left;
	}
	#_asm .ASM_session span.ASM_icon-contacts {
		margin-left: 5px;
	}
	#_asm .ASM_session .cartId {
		margin-bottom: 15px;
	}
	#_asm .ASM_timer {
		display: block;
		margin-bottom: 10px;
		margin-right: 0;
	}
	#_asm .ASM_timer .ASM-btn-reset {
		float: right;
	}
	#_asm .ASM_session .ASM-btn {
		float: left;
	}
	#_asm .ASM_session .ASM-btn.ASM-btn-customer360 {
		float: right;
	}
	#_asm .ASM-btn.ASM-btn-customer-list {
		float: none;
		width: 100%;
		margin-bottom: 10px;
		text-align: center;
	}
	#_asm .ASM_session .ASM_session_input_text {
		display: block;
		margin-bottom: 10px;
		line-height: 32px;
		margin-left: 60px;
	}
	#_asm .ASM_session .ASM_session_input_text:before,
	#_asm .ASM_session .ASM_session_input_text:after {
		content: " ";
		display: table;
	}
	#_asm .ASM_session .ASM_session_input_text:after {
		clear: both;
	}
	#_asm .ASM_session .ASM_session_input_text:before,
	#_asm .ASM_session .ASM_session_input_text:after {
		content: " ";
		display: table;
	}
	#_asm .ASM_session .ASM_session_input_text:after {
		clear: both;
	}
}

#_asm .ASM_control {
	height: 8px;
	background-color: #097aff;
	position: relative;
	-webkit-transition: background-color 0.3s ease-out;
	transition: background-color 0.3s ease-out;
}
#_asm .ASM_control .ASM_control_text {
	display: none;
	font-size: 22px;
	font-weight: 300;
	line-height: 48px;
	color: #fff;
}
#_asm .ASM_control .ASM_control_collapse {
	position: absolute;
	right: 50px;
	bottom: -1px;
	height: 22px;
	width: 82px;
	overflow: hidden;
	cursor: pointer;
}
#_asm .ASM_control .ASM_control_collapse .ASM_control_collapse_arrow {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'asm_icons';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 22px;
	position: absolute;
	top: 0;
	display: block;
	color: #fff;
	-webkit-transition: color 0.3s ease-out;
	transition: color 0.3s ease-out;
}
#_asm .ASM_control .ASM_control_collapse .ASM_control_collapse_arrow:before {
	content: '\e804';
}
#_asm .ASM_control .ASM_control_collapse .ASM_control_collapse_indicator {
	display: inline-block;
	font-family: 'asm_icons';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	font-size: 10px;
	top: 6px;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	color: #097aff;
	-webkit-transition: color 0.3s ease-out;
	transition: color 0.3s ease-out;
}
#_asm .ASM_control .ASM_control_collapse .ASM_control_collapse_indicator:before {
	content: '\e806';
}
#_asm .ASM_control.ASM_control_session_active {
	background-color: #19bc33;
}
#_asm .ASM_control.ASM_control_session_active .ASM_control_collapse_indicator {
	color: #19bc33;
}
#_asm.ASM-collapsed {
	-webkit-transform: translate(0, -100%);
	-ms-transform: translate(0, -100%);
	transform: translate(0, -100%);
	margin-top: 53px;
}
#_asm.ASM-collapsed .ASM_header, #_asm.ASM-collapsed .ASM_session {
	display: none;
}
#_asm.ASM-collapsed .ASM_control {
	height: 48px;
}
#_asm.ASM-collapsed .ASM_control .ASM_control_text {
	display: block;
}
#_asm.ASM-collapsed .ASM_control .ASM_control_collapse {
	top: 0px;
}
#_asm.ASM-collapsed .ASM_control .ASM_control_collapse .ASM_control_collapse_arrow {
	color: #fff;
}
#_asm.ASM-collapsed .ASM_control .ASM_control_collapse .ASM_control_collapse_arrow:before {
	content: '\e803';
}
#_asm.ASM-collapsed .ASM_control .ASM_control_collapse .ASM_control_collapse_indicator {
	color: #097aff;
}
#_asm.ASM-collapsed .ASM_control .ASM_control_collapse .ASM_control_collapse_indicator:before {
	content: '\e805';
}
#_asm.ASM-collapsed .ASM_control.ASM_control_session_active .ASM_control_collapse .ASM_control_collapse_indicator {
	color: #19bc33;
}

/* autocomplete */
.asmAutoComplete .ui-menu {
	list-style:none;
	display:block;
	background-color: white;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
	border: 1px solid #cccccc;
	z-index: 10000;
}
.asmAutoComplete .ui-autocomplete, #asmHover {
	padding: 7px 10px 5px;
	width: 83vw!important;
	max-width: 660px;
	position: absolute;
	cursor: default;
}
#asmAutoCompleteCartId .ui-autocomplete {
	width: 220px!important;
}
@media (min-width: 668px) and (max-width: 1049px) {
	#asmAutoCompleteCartId .ui-autocomplete {
		width: 210px!important;
	}
}
.asmAutoComplete .ui-autocomplete .ui-menu-item {
	border: none;
	font-size: 18px;
	padding: 5px 0;
}
.asmAutoComplete .ui-autocomplete .ui-menu-item:first-child {
	padding-top: 0;
}
.asmAutoComplete .ui-state-focus {
	background: none;
	color: #000;
	margin: 0;
}
#_asm .asmAutoComplete .ui-autocomplete .ui-menu-item a, .asmAutoComplete .ui-autocomplete .ui-menu-item a:hover {
	border-bottom: 1px solid #fff;
	border-top: 1px solid #fff;
	color: #000 !important;
	padding: 0;
	display: block;
}
.asmAutoComplete .ui-autocomplete .ui-menu-item span, #asmHover span {
	padding-left: 30px;
	font-size: 18px;
	background-color: #fff;
	vertical-align: top;
	display: inline-block;
}
#asmHover.disabled, #asmHover.disabled span {
	background-color: #b4bac2;
}
.asmAutoComplete .ui-autocomplete .ui-menu-item .noresult {
	padding-left: 3px;
}
.asmAutoComplete .ui-autocomplete .ui-menu-item .name, #asmHover .name {
	padding-left: 3px;
	width: 23%;
}
.asmAutoComplete .ui-autocomplete .ui-menu-item .email, #asmHover .email {
	width: 43%;
	overflow: hidden;
	text-overflow: ellipsis;
}
.asmAutoComplete .ui-autocomplete .ui-menu-item .card {
	padding-right: 3px;
}
.asmAutoComplete .ui-autocomplete a:hover, .asmAutoComplete .ui-autocomplete a:hover span {
	background-color: #eeeeee;
	cursor: pointer;
}
.asmAutoComplete .ui-autocomplete .ui-menu-item span.result {
	width: 100%;
	padding-left: 5px;
}
@media (max-width: 667px) {
	.asmAutoComplete .ui-autocomplete {
		width: auto!important;
		margin-right: 15px;
	}
	#asmHover {
		width: auto!important;
	}
	.asmAutoComplete .ui-autocomplete .ui-menu-item .name,
	.asmAutoComplete .ui-autocomplete .ui-menu-item .email,
	#asmHover .name,
	#asmHover .email {
		width: 100%;
		padding-left: 3px;
	}
	.asmAutoComplete .ui-autocomplete .ui-menu-item .date,
	.asmAutoComplete .ui-autocomplete .ui-menu-item .card,
	#asmHover .date,
	#asmHover .card {
		width: 50%;
		padding-left: 3px;
	}
}
/* end autocomplete */
#asmHover {
	position: absolute;
	background-color: #fff;
	border: 1px solid #cccccc;
	box-shadow: 0 8px 15px rgba(0, 0, 0, 0.31);
	font-size: 18px;
	z-index: 10001;
}


/* Customer List Modal */
.ASM_customer-list-modal #cboxLoadedContent {
	margin-top: 20px;
}
.ASM_customer-list-modal #cboxTitle {
	display: none!important;
}
.ASM_customer-list-modal .account-overview-table .img-profile-thumbnail {
	width: 55px;
	height: 55px;
}
.ASM_customer-list-modal .asm-account-section .account-overview-table,
.ASM_customer-list-modal .asm-account-section .pagination-bar .pagination-bar-results {
	margin: 0;
}
.ASM_customer-list-modal .asm-account-section .pagination-bar .pagination-bar-results {
	padding: 10px 30px;
	border-top: 1px solid #cccccc;
}

.ASM_customer-list-modal .asm-account-section .account-section-content .account-orderhistory-pagination .pagination-bar .sort-refine-bar {
	padding-left: 15px;
	padding-right: 15px;
}
.ASM_customer-list-modal .asm-account-section table td,
.ASM_customer360-modal .asm-customer360-tab table td{
	padding: 10px;
	vertical-align: middle;
}
.ASM_customer-list-modal .asm-account-section table .nav-order-tools{
	border-right: 0;
	padding: 0 15px;
}
@media(max-width: 668px){
	.ASM_customer-list-modal .asm-account-section table .nav-order-tools{
		padding: 0;
	}
}
.ASM_customer-list-modal .asm-card-icon{
	font-size: 30px;
}
.ASM_customer-list-modal .asm-empty-card-icon{
	color: #E4E4E4;
}
.ASM_customer-list-modal .asm-full-card-icon{
	color: #128a9e;
}
.ASM_customer-list-modal .asm-account-section .pagination-bar{
	margin: 0;
}
.ASM_customer-list-modal .asm-account-section .pagination-bar .pagination-toolbar {
	margin-left: -10px;
	margin-right: -10px;
	padding: 15px 0 20px 0;
	border: 0;
}
.ASM_customer-list-modal .asm-account-section .default-pic:before {
	content: '\e801';
}
.ASM_customer-list-modal .asm-account-section .default-pic {
	font-family: 'asm_icons';
	color: #A6A6A6;
	font-size: 55px;
	line-height: 55px;
	display: block;
	max-width: 100%;
	height: auto;
}
.ASM_customer-list-modal .customer-list-select-wrapper {
	display: inline-block;
	position: relative;
}
.ASM_customer-list-modal .customer-list-select {
	border: 0 none;
	font-size: 24px;
	line-height: 24px;
	font-weight: bold;
	cursor: pointer;
	z-index: 1;
	padding-right: 30px;
	background-color: transparent;
	color: #27394f;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.ASM_customer-list-modal .customer-list-select:focus {
	background-color: transparent;
	border: 0;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.ASM_customer-list-modal .customer-list-select-wrapper:after {
	font-family: 'asm_icons';
	content: '\e80a';
	color: #27394f;
	font-size: 30px;
	font-weight: 600;
	position: absolute;
	right: 0;
	top: 50%;
	pointer-events: none;
	z-index: 11;
	margin-top: -20px;
}
//full
.ASM_customer-list-modal .customer-list-select option {
	font-size: 14px;
	font-weight: normal;
}

/*AIF Modal*/
#_asm .ASM-btn-customer360 {
	text-decoration: inherit;
	background-color: #007aff;
	border-color: #007aff;
	color: #fff;
	margin-left: 10px;
	vertical-align: bottom;
	position: relative;
}

.ASM-customer360_icon:before {
	font-family: 'asm_icons';
	content: '\e800';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-weight: normal;
	color: #fff;
	font-size: 22px;
	vertical-align: bottom;
}

.ASM-customer360_customer-list_icon:before {
	color: #0486e0;
	position: static;
}

#_asm .ASM-btn-customer360:focus {
	outline: none;
}

.ASM_customer360-modal {
	background: @brand-white;
	padding: 1px 20px;
	box-sizing: content-box;
	z-index: 101;
}

#cboxOverlay {
	background: #000000;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.ASM_customer360-modal .loader {
	margin: 60px auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 1.1em solid rgba(0,0,0, 0.2);
	border-right: 1.1em solid rgba(0,0,0, 0.2);
	border-bottom: 1.1em solid rgba(0,0,0, 0.2);
	border-left: 1.1em solid #0486e0;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}
.ASM_customer360-modal .loader,
.ASM_customer360-modal .loader:after {
	width: 5em;
	height: 5em;
}

/* To fit customer360 button into one line width he rest of the buttons, have to be deleted after full ASM-Techne re-skinning*/
@media (min-width: 668px) and (max-width: 840px) {
	#_asm .ASM_session span.ASM_icon-chain {
		margin-left: 0;
		margin-right: 10px !important;
	}
	#_asm .ASM-btn-customer360{
		padding-right: 5px;
		margin-left: 5px;
	}
	#_asm .ASM-btn-customer-list{
		margin-right: 10px;
	}

}
/* End of customer360 button custom styling, have to be deleted after full ASM-Techne re-skinning*/

@-webkit-keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes load8 {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
/*End of AIF Modal*/

/*Modifie Techne part of responsive tables styling*/
.ASM_customer-list-modal table.techne-table td,
.ASM_customer360-modal table.techne-table td{
	cursor: pointer;
	color: #7f90a4;
}
.ASM_customer-list-modal table.techne-table td.no-padding {
	padding: 0px !important;
}
.ASM_customer-list-modal table.techne-table td.techne-table-action .hyicon {
	font-size: 24px;
}
.ASM_customer-list-modal table.techne-table > tbody > tr:nth-child(1n),
.ASM_customer360-modal table.techne-table > tbody > tr:nth-child(1n){
	background: #f9f9f9; /* ACC styleguide color*/

}
@media (max-width: 800px ) {
	.ASM_customer-list-modal table.techne-table thead,
	.ASM_customer360-modal table.techne-table thead {
		display: none;
	}
	.ASM_customer-list-modal .asm-account-section table td,
	.ASM_customer360-modal .asm-customer360-tab table td {
		padding: 0;
	}
	.ASM_customer-list-modal table.techne-table td,
	.ASM_customer360-modal table.techne-table td {
		clear: both;
		display: block;
		border-top: none !important;
		overflow-wrap: break-word;
		word-wrap: break-word;
		-ms-word-break: break-all;
		word-break: break-all;
		word-break: break-word;
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}
	.ASM_customer-list-modal table.techne-table td .floating-text,
	.ASM_customer360-modal table.techne-table td .floating-text {
		-moz-hyphens: none;
		-webkit-hyphens: none;
		-ms-hyphens: none;
		word-break: initial;
		word-wrap: initial;
	}
	.ASM_customer-list-modal table.techne-table td:before,
	.ASM_customer360-modal table.techne-table td:before {
		content: attr(data-th) "";
		width: 100%;
		display: block;
		font-size: 13px;
		font-weight: 600;
		color: #254764;
	}
	.ASM_customer-list-modal table.techne-table td > div.cellcontent {
		width: 100%;
		display: block;
		color: #7f90a4;
		font-size: 15px;
	}
	.ASM_customer-list-modal table.techne-table td.techne-table-xs-left-slot,
	.ASM_customer360-modal table.techne-table td.techne-table-xs-left-slot {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 50px;
		padding-right: 5px;
		padding-left: 10px;
	}
	.ASM_customer-list-modal table.techne-table tr,
	.ASM_customer360-modal table.techne-table tr {
		display: block;
		position: relative;
		margin-bottom: 10px;
	}
	.ASM_customer-list-modal table.techne-table tr.techne-table-xs-left,
	.ASM_customer360-modal table.techne-table tr.techne-table-xs-left {
		padding-left: 50px;
	}

	/*End of separator between Cart and Order on mobile */

	.ASM_customer-list-modal table.techne-table > tbody > tr > td {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	/*Overrides for last 2 rows being next to each other, only for .ASM_customer-list-modal*/
	.ASM_customer-list-modal table.techne-table > tbody > tr > td:last-child::before,
	.ASM_customer-list-modal table.techne-table > tbody > tr > td:nth-last-child(2)::before,
	.ASM_customer-list-modal table.techne-table > tbody > tr > td:nth-last-child(3)::before{
		display: inline;
	}
	.ASM_customer-list-modal table.techne-table > tbody > tr > td:last-child,
	.ASM_customer-list-modal table.techne-table > tbody > tr > td:last-child div ,
	.ASM_customer-list-modal table.techne-table > tbody > tr > td:nth-last-child(2),
	.ASM_customer-list-modal table.techne-table > tbody > tr > td:nth-last-child(2) div,
	.ASM_customer-list-modal table.techne-table > tbody > tr > td:nth-last-child(3),
	.ASM_customer-list-modal table.techne-table > tbody > tr > td:nth-last-child(3) div {
		display: inline-block;
		padding-left: 10px;
		vertical-align: middle;
	}
	.ASM_customer-list-modal .asm-account-section table .nav-order-tools{
		padding-bottom: 7px;
	}
	.ASM_customer-list-modal .asm-account-section a.default-pic{
		font-size: 35px;
	}
	/*Add a separator between Cart and Order on mobile */
	.ASM_customer-list-modal table.techne-table > tbody > tr > td:nth-last-child(2){
		padding-right: 30px;
	}
	.ASM_customer-list-modal table.techne-table > tbody > tr > td:last-child{
		position: relative;
		padding-left: 30px;
	}
	.ASM_customer-list-modal table.techne-table > tbody > tr > td:last-child::after{
		content: "";
		background: #cccccc;
		position: absolute;
		bottom: 20px;
		left: 0;
		height: 40%;
		width: 2px;
	}
}
.ASM_customer-list-modal table.techne-table > tbody > tr:nth-child(2n),
.ASM_customer360-modal table.techne-table > tbody > tr:nth-child(2n) {
	background: #ffffff;
	box-shadow: none;
}
.ASM_customer-list-modal table.techne-table > tbody > tr:hover, .ASM_customer-list-modal table.techne-table > tbody > tr:hover td{
	background: #f5f5f5;
}
/*Overrides for cart icon to be white on grey on hover*/
.ASM_customer-list-modal table.techne-table th:last-child,
.ASM_customer360-modal table.techne-table th:last-child{
	text-align: left;
}
/*End of Techne table styling + ASM overrides*/

/*Reduce pagination margin and padding on mobile*/
@media (max-width: 639px){
	.ASM_customer-list-modal .asm-account-section .pagination-bar .pagination{
		border: none;
		padding: 0px;
		margin-top: 10px;
	}
	.ASM_customer-list-modal .asm-account-section .pagination-bar .pagination-bar-results{
		text-align: center;
		border-bottom: 1px solid #cccccc;
		margin-bottom: 10px;
	}
	/*Mobile fix for long text in dropdown on top of modal with Customers List*/
	.ASM_customer-list-modal .customer-list-select {
		font-size: 18px;
	}
}

/*common styling for pagination in modals*/
.asm__section .pageable-fragment .pager .active {
	font-weight: bold;
}
.asm__section .pageable-fragment h3 {
	float:left;
}
.asm__section .pageable-fragment .pager {
	float:right;
}