@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i');

//
// Base Colors
// --------------------------------------------------

@body-bg:                   @gray-lighter;

@brand-red:                 #ed1c24;
@brand-green:               #6aa965;
@brand-blue:                #678695;
@brand-light-blue:          #96aab4;
@brand-edit-blue:           #019EBF;
@brand-black:               #333333;
@brand-white:               #ffffff;
@brand-offwhite:            #f5f5f5; //Grey 6
@brand-orange:              #FFB528;
@brand-light-gray:          #91a7b2;
@brand-dark-blue:           #246fa8;


@gray-base:                 #c9c9c9; //Grey 4
@gray-darker:               #494949; //Dark Grey
@gray-dark:                 #616161; //Grey 1
@gray:                      #b3b3b3; //Grey 2
@gray-light:                #d7d7d7; //Grey 3a
@gray-lighter:              #ececec; // Grey 5
@gray-lightest:             #fafafa; // Grey 7

@brand-primary:             @brand-blue;
@brand-secondary:           @gray-dark;
@brand-tertiary:            #cccccc;
@brand-forth:               #009fc2;

@brand-success:             @brand-forth;
@brand-note:                @brand-secondary;
@brand-error:               #db2323;

@header-hover:              #292929;


//
// Typography
// --------------------------------------------------

@text-color:                @brand-black;
@text-transform-none:		none;

@link-color:                @brand-blue;
@link-hover-color:          @brand-blue;
@link-remove-color:         @brand-red;
@link-remove-hover-color:   @brand-red;
@link-decoration:           underline;
@link-hover-decoration:     underline;

@font-family-sans-serif:    'Source Sans Pro', sans-serif;
@font-family-serif:         Georgia, "Times New Roman", Times, serif;
@font-family-base:          @font-family-sans-serif;
@font-family-icon:          'FontAwesome';

@font-weight-normal:        400;
@font-weight-semi-bold:     600;
@font-weight-bold:          700;

@font-size-base:            14px;
@font-size-large:           ceil((@font-size-base * 1.25)); // ~18px
@font-size-small:           ceil((@font-size-base * 0.85)); // ~12px
@font-size-mid-sm:          13px;

@font-size-h1:              44px;
@font-size-h2:              28px;
@font-size-h3:              24px;
@font-size-h4:              18px;
@font-size-h5:              16px;
@font-size-h6:              14px;
@font-size-h7:              12px;
@font-size-11:              11px;

@line-height-base:          1.428571429; // 20/14
@line-height-computed:      floor((@font-size-base * @line-height-base)); // ~20px
@line-height-custom:        1.142857142; // 16/14

@headings-font-family:      inherit;
@headings-font-weight:      @font-weight-normal;
@headings-line-height:      1;
@headings-color:            inherit;

@section-heading-font-size: 20px;

//
// Buttons
// --------------------------------------------------

@btn-default-color:             @brand-white;
@btn-default-bg:                @brand-green;
@btn-default-border-color:      @brand-green;
@btn-default-font-weight:       bold;

@btn-alternate-color:           @brand-white;
@btn-alternate-bg:              @brand-blue;
@btn-alternate-border-color:    @brand-blue;
@btn-alternate-font-weight:     bold;

@btn-default-padding:           12px 24px;
@btn-default-font-size:         @font-size-base;
@btn-default-line-height:       14px;
@btn-default-border:            none;
@btn-default-border-radius:     0;


//
// Code
// --------------------------------------------------

@code-color: #000000;
@code-bg: #f7f7f9;
@code-border-radius: 2px;

@kbd-color: #ffffff;
@kbd-bg: #999999;


//
// Forms
// --------------------------------------------------

@input-default-color:                   @text-color;
@input-default-bg:                      #ffffff;
@input-default-border-color:            @gray-base;

@input-validation-color:                @text-color;
@input-validation-bg:                   #ffffff;
@input-validation-border-color:         @brand-error;

@input-default-padding:                 9px 10px;
@input-default-font-size:               14px;
@input-default-line-height:             18px;
@custom-input-line-height:              40px;
@input-default-border:                  2px solid @input-default-border-color;
@input-default-border-radius:           0;

@input-default-vertical-spacing:        16px;
@input-default-horizontal-spacing:      16px;

@text-area-height:                      226px;

@validation-msg-color:                  @brand-white;
@validation-background-color:           @brand-red;
@validation-msg-font-size:              16px;
@validation-msg-line-height:            2em;
@validation-msg-font-style:             italic;
@validation-msg-padding:                11px 14px;

@inline-field-spacing:                  15px;
@inline-field-width:                    45%;

@inline-form-button-height:             48px;
@inline-form-button-width:              120px;

@custom-radio-height:                   28px;
@custom-radio-width:                    28px;
@custom-radio-color:                    @brand-white;
@custom-radio-icon:                     @fa-var-circle-o;
@custom-radio-icon-active:              @fa-var-dot-circle-o;
@custom-radio-icon-font-size:           25px;

@custom-checkbox-height:                22px;
@custom-checkbox-width:                 22px;
@custom-checkbox-color:                 @brand-white;
@custom-checkbox-icon:                  "";
@custom-checkbox-icon-active:           @fa-var-check;
@custom-checkbox-icon-font-size:        14px;
@custom-checkbox-icon-font-size-big:    18px;
@custom-checkbox-background:            @gray-light;
@custom-checkbox-background-green:      @brand-green;
@custom-checkbox-background-red:        @brand-red;


@radio-toggle-container-height:         40px;
@radio-toggle-border-color: 			#666666;
@radio-toggle-active-color:             #000000;
@radio-toggle-active-bgcolor:           #dedede;
@radio-toggle-inactive-color:           #dedede;
@radio-toggle-inactive-bgcolor:         #666666;

//
// Z-index
@zindex-back-to-top:                   1000;
@zindex-modal-overlay:                 100000;


//
// Grid
// --------------------------------------------------

// Set False if Theme is not responsive
@is-responsive: true;

// Number of Columns
@grid-columns:              12;
// Gutter Width
@grid-gutter-width:         20px;
// Float Breakpoint for Elements
@grid-float-breakpoint:     @screen-sm;

// Small screen / phone
@screen-xs:                  375px;
@container-xs:              (@screen-xs + @grid-gutter-width);
// Small screen / phone
@screen-sm:                  480px;
@container-sm:              (@screen-sm + @grid-gutter-width);
// Medium screen / tablet
@screen-md:                  800px;
@container-md:              (@screen-md + @grid-gutter-width);
// Large screen / desktop
@screen-lg:                  1024px;
@container-lg:              (@screen-lg + @grid-gutter-width);
// X-Large screen / desktop
@screen-xl:                  1140px;
@container-xl:              (@screen-xl + @grid-gutter-width);

// Main container wrapper width
@wrapper-width:              980px;

//
// Indents
// --------------------------------------------------

@indent-xs :            5px;
@indent-sm :            10px;
@indent-md :            15px;
@indent-lg :            20px;
@indent-xl :            30px;


//
// Tables
// --------------------------------------------------

@table-margin:          0 0 10px 0;

@table-head-bg:         @gray-light;
@table-head-padding:    10px 12px 8px 20px;
@table-head-align:      center;
@table-head-border:     1px solid @gray;

@table-cell-bg:         @brand-white;
@table-cell-alt-bg:     @brand-offwhite;
@table-cell-padding:    21px 10px;
@table-cell-align:      center;
@table-cell-border:     1px solid @gray-base;


@product-table-margin:			0;
@product-table-image-width: 	55px;
@product-table-image-height: 	55px;


//
// Messages
// --------------------------------------------------

@msg-default-color:               #ffffff;
@msg-default-bg:                  @brand-blue;
@msg-default-border-color:        transparent;

@msg-success-color:               #ffffff;
@msg-success-bg:                  @brand-blue;
@msg-success-border-color:        transparent;

@msg-note-color:                  #ffffff;
@msg-note-bg:                     @gray;
@msg-note-border-color:           transparent;

@msg-error-color:                 #ffffff;
@msg-error-bg:                    @brand-red;
@msg-error-border-color:          transparent;

@msg-default-padding:             8px 20px;
@msg-default-font-size:           14px;
@msg-default-line-height:         1;
@msg-default-border:              none;
@msg-default-border-radius:       2px;

@msg-default-vertical-spacing:    0;


//
// Pagination
// --------------------------------------------------

@pagination-color:             @brand-secondary;
@pagination-active-color:      @brand-primary;
@pagination-bg:                transparent;

@pagination-padding:           0 10px;
@pagination-font-size:         @font-size-small;
@pagination-font-family:       @font-family-base;
@pagination-line-height:       48px;
@pagination-text-transform:    uppercase;
@pagination-border:            none;
@pagination-border-radius:     0;


//
// Breadcrumbs
// --------------------------------------------------

@breadcrumb-padding:        0;
@breadcrumb-margin:         10px 0 6px;
@breadcrumb-bg:             #ddd;
@breadcrumb-font-size:      @font-size-base;
@breadcrumb-separator:      '/';
@breadcrumb-color:          @brand-black;
@breadcrumb-active-color:   @brand-red;
@breadcrumb-bg-dark:		#DDDDDD;


//
// Thumbnails
// --------------------------------------------------

@thumbnail-padding:             0;
@thumbnail-bg:                  transparent;
@thumbnail-border:              1px solid @brand-tertiary;
@thumbnail-border-radius:       6px;
@thumbnail-margin:              0 0 20px 0;

@thumbnail-copy-padding:        10px;


//
// Accordion
// --------------------------------------------------

@accordion-trigger-bg:              @gray-darker;
@accordion-trigger-padding:         16px 15px 16px 20px;
@accordion-trigger-color:           @brand-white;

@editor-accordion-padding:          8px 15px;


//
// Custom Selects
// --------------------------------------------------

@select-color:                      @input-default-color;
@select-bg:                         @input-default-bg;
@select-border-color:               @gray-lighter;

@select-height:                     48px; //perhaps we can do this better
@select-padding:                    9px 30px 9px 15px;
@select-font-size:                  @input-default-font-size;
@select-line-height:                @input-default-line-height;
@select-border:                     @input-default-border;
@select-border-radius:              @input-default-border-radius;
@select-icon:                       @fa-var-chevron-down;
@select-icon-open:                  @fa-var-chevron-up;
@select-icon-color:                 @brand-black;

@select-has-options:                true;


//
// Carousel
// --------------------------------------------------

@carousel-nav-color:           @link-color;
@carousel-hover-nav-color:     @link-hover-color;
@carousel-prev-icon:           '\f104';
@carousel-next-icon:           '\f105';
@carousel-nav-size:            40px;

//
// Menu
// --------------------------------------------------
@menu-link-padding: 40px 20px;
@menu-link-hover: @brand-black;
@menu-link-bg-hover: transparent;

@menu-dropdown-width: 250px;
@menu-dropdown-padding: 0;
@menu-dropdown-position-offset: 50%;
@menu-dropdown-background: #fff;
@menu-dropdown-shadow: 0 10px 6px -6px #777;
@menu-dropdown-border: 2px solid @gray-base;

@menu-dropdown-link-padding: 0;
@menu-dropdown-link-hover: @brand-black;
@menu-dropdown-link-bg-hover: transparent;


//
// Modal
// --------------------------------------------------

@modal-sm:                          90%;
@modal-md:                          600px;
@modal-lg:                          960px;

@modal-backdrop-bg:                 rgba(201,201,201,0.85);
@modal-backdrop-bg-fallback:        #ffffff;

@modal-content-bg:                  #ffffff;
@modal-content-margin:              80px auto;
@modal-content-padding:             0;
@modal-content-padding-sm:          0;
@modal-content-border:              none;
@modal-content-border-radius:       0;

@modal-item-border: 1px solid #979797;



//
// Tabs
// --------------------------------------------------

@tabby-tab-bg:              @brand-tertiary;
@tabby-tab-padding:         14px 20px;
@tabby-tab-color:           @text-color;
@tabby-tab-bg-active:       @brand-primary;
@tabby-tab-color-active:    #fff;

@tabby-content-padding: 	@line-height-computed;


//
// Tray
// --------------------------------------------------

@tray-container-padding:        0;
@tray-container-background:     #fff;
@tray-close-size:               @font-size-large;
@tray-close-icon:               "\e900";