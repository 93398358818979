//
// UI Kit Customizations
// --------------------------------------------------

//
// Variables
// --------------------------------------------------

// spacing between each tab header
@indent-tabs: 2px;

*:focus {
	outline: none;
}

/*
Tabs
 */

.tabs-row {
	&:extend(.clearfix all);

	padding: 0 @indent-sm;

	.tabs-tab {
		float: left;
		font-size: @font-size-base;
		background-color: transparent;
	}

	> div {
		padding: 0 @indent-sm;

		&:last-child {
			padding: 0 @indent-lg;
		}
	}
}

.gor-tabs .tabs-content {
	position: relative;
}

.gor-tabs {
	.tabs-tab {
		background-color: @gray-base;
		display: block;
		height: 40px;
		font-weight: @font-weight-bold;
		padding: 0 25px;
		margin-left: @indent-tabs;
		width: auto;
		min-width: inherit;

		> a,
		> span {
			color: @brand-black;
			display: block;
			padding: 11px 0;
			text-decoration: none;

			.page-massEditPage & {
				text-transform: capitalize;
			}
		}

		&.gor-active {
			color: @brand-black;
			background-color: @brand-white;
			position: relative;
			margin-left: 0;
			// add 2px padding to prevent tabs from shifting
			padding-left: 25px + @indent-tabs;
			padding-right: 25px + @indent-tabs;

			&:after {
				background-color: @brand-red;
				content: "";
				height: 6px;
				left: 0;
				position: absolute;
				top: -3px;
				width: 100%;
			}
		}
	}

	.tabs-tab.gor-active + .tabs-tab {
		margin-left: 0;
	}
}

.tab-disabled {
	opacity: 0.5;
	pointer-events: none;
}

/*
Accordion / Section Header
 */

.section-header {
	position: relative;
	height: 50px;
	font-size: 24px;
	font-weight: 400;
	text-align: left;
	line-height: 18px;
	padding: @accordion-trigger-padding;
	background-color: @accordion-trigger-bg;
	color: @accordion-trigger-color;

	&:before {
		background-color: @brand-red;
		content: "";
		display: block;
		height: 50px;
		left: 0;
		position: absolute;
		top: 0;
		width: 10px;
	}
}

.gor-accordion {
	margin-bottom: @line-height-computed;

	.accordion-item {
		border-bottom: none;

		&:first-child {
			border-top: none;
		}
	}
	.accordion-header {
		height: 50px;
		font-size: 24px;
		font-weight: 400;
		text-align: left;
		line-height: 18px;

		&:before {
			background-color: @brand-red;
			content: "";
			display: block;
			height: 50px;
			left: 0;
			position: absolute;
			top: 0;
			width: 10px;
		}

		&:after {
			content: @fa-var-chevron-down;
			font-size: @font-size-base;
			right: 15px;
		}

		&.gor-active {
			&:after {
				content: @fa-var-chevron-up;
			}
		}
	}
	.accordion-content {
		border-bottom: none;
	}

	.accordion-content-inner {
		padding: 17px 20px;
	}
}

/*
* Tables
*/

.mobile-content {
	font-size: @font-size-base;
	font-weight: @font-weight-normal;
	display: none;

	span {
		display: block;
	}

	+ span > strong {
		font-weight: @font-weight-normal;
	}

	&.mobile-content-condensed {
		> div {
			padding: 0;
			font-size: @font-size-small;
		}
	}
}

.page-quote,
.page-order {
	.messages {
		margin-bottom: @indent-xl;
	}
}

.account-orderhistory .actions-tab,
.actions-tab {


	.gor-accordion {
		position: relative;
		font-size: @font-size-small;
		line-height: 24px;
		margin: 0;

		.accordion-header {
			height: auto;
			background-color: @brand-white;
			border: 2px solid @brand-blue;
			color: @brand-blue;
			font-size: @font-size-small;
			padding: 8px 42px 8px 15px;

			.page-quote &,
			.page-order & {
				font-size: @font-size-base;
			}

			&:before {
				position: absolute;
				right: -2px;
				left: auto;
				background-color: @brand-blue;
				width: 2px;
				height: auto;
				bottom: -2px;
			}


			&.gor-active {
				z-index: 20;
				border-bottom-color: @brand-white;
			}
		}

		.accordion-content {
			text-align: left;
			position: absolute;
			z-index: 12;
			background: @brand-white;
			right: 0;
			top: 36px;
			box-shadow: 0 10px 6px -6px rgba(0,0,0,0.25);
			width: 100%;
			min-width: 205px;

			&.gor-transitioning:after {
				content: "";
				position: absolute;
				background-color: @brand-white;
				top: 0;
				right: 2px;
				width: 100%;
				height: 2px;
				max-width: 96px;
			}

			.content-inner {
				border: 2px solid @brand-blue;
			}

			p {
				line-height: 1.3;
				padding: 10px 60px 0 8px;
				margin-bottom: @indent-sm;
				font-weight: @font-weight-normal;

				+ p {
					padding-right: 8px;
					margin-bottom: 0;
					font-weight: @font-weight-bold;
				}
			}

			ul {
				list-style: none;
				margin: 0;
				min-width: 193px;
				padding: 8px 0;

				span {
					display: inline;
				}
			}

			p + ul {
				padding-top: 4px;
			}

			span {
				display: inline-block !important;
			}


			button,
			a {
				text-decoration: none !important;
				color: @brand-black;
				font-weight: @font-weight-normal !important;
				display: block;
				padding: 0 8px;
				font-size: @font-size-base !important;
				line-height: 24px;
				width: 100%;
				text-align: left;

				span {
					color: @brand-blue;
					padding-right: @indent-xs;
					width: 20px;
				}

				@media (min-width: 1025px) {
					&:hover {
						background-color: @gray-lighter;
					}
				}
			}
			.cart-options__item-link {
				display: flex;
				padding: 0;
			}
		}

		.accordion-content-inner {
			padding: 0;
		}
	}

}

.table {

	.visible-mobile {
		clear: both;

		.actions-tab {
			float: left;

			&.full-width {
				float: none;
			}

			.accordion-content {
				right: initial;
			}
		}
	}
}

.tabs-content .pagination-bar {
	padding: 0;
	margin-bottom: @indent-sm;
	width: auto;
}

.sort-form.horizontal-form {
	text-align: right;

	> div {
		text-align: left;
	}
}



#breadcrumbs,
.category-breadcrumbs {
	margin-top: 0;
	margin-bottom: @indent-xl;
	background-color: @breadcrumb-bg;

	.page-category & {
		margin-top: 0;
		margin-bottom: 0;
	}

	.breadcrumbs {
		display: block;
		line-height: 16px;

		.last {
			font-weight: @font-weight-bold;
			color: @brand-red;
		}
	}
}

.category-breadcrumbs--dark {
	background-color: @breadcrumb-bg-dark;
}

.page-header + .category-breadcrumbs,
.page-header + #breadcrumbs {
	margin-top: -@indent-xl;
}

.page-order-approval-dashboard {
	.page-header + #breadcrumbs {
		margin-bottom: 0;
	}
}

.page-manageUsergroups,
.page-my-company,
.page-manageUnits,
.page-organizationManagement {

	.accountActions-link span {
		display: block;
	}

	form {
		.form-list {
			.col-md-4,
			.col-md-6 {
				width: 100%;
			}
		}
	}

	h5 a,
	h5 .account-list-header-add {
		margin-top: 5px;
		font-size: @font-size-base;

		span {
			display: inline-block;
			padding: 0 @indent-xs;
		}
	}

	.modal-content {
		padding: @indent-lg;
	}

	.account-section {
		padding-top: 24px;
		padding-bottom: @indent-xl;
		margin-bottom: @indent-xl;
	}

	.buttons-set + .account-section {
		margin-top: @indent-xl;
	}

	.modal-content {
		width: 400px;
	}

	.footer {
		margin-top: 70px;
	}

	.input-box {
		max-width: 400px;
	}

	.account-section-content {

		.table td {
			vertical-align: middle;
			text-align: center;

			&:first-child {
				text-align: left;

				a {
					color: @brand-blue;
					text-decoration: none;
				}

			}

			&:last-child {
				padding-top: 18px;
			}
		}

		.table th,
		.table td {

			&,
			&:last-child {
				padding-left: @indent-sm;
				padding-right: @indent-sm;

			}

			&:first-child {
				padding-left: @indent-lg;
			}

			a {
				text-decoration: none;
			}
		}

		.button {
			margin: 0;
			display: inline-block;
		}


		button {
			margin: @indent-xl 0 @indent-sm;
		}
	}

	.control-group .custom-checkbox {
		legend {
			&:extend(label);
		}
		> div {
			position: relative;
			margin-top: @indent-sm;
		}
	}

	.approvers {
		margin-top: @indent-sm;
	}

	.account-list-header {
		margin-top: @indent-xs;
	}

	.card-select {

		> .row {
			display: flex;
			flex-wrap: wrap;
		}

		.card {
			position: relative;
			margin-bottom: @indent-lg;

			&.selected .content-inner {
				border-color: @brand-green;
			}
		}

		.content-inner {
			position: relative;
			border: 2px solid @gray;
			padding: @indent-lg @indent-sm;
		}

		.account-cards-actions {
			position: absolute;
			top: 0;
			right: 0;
			font-size: 20px;

			a {
				display: block;
				padding: @indent-xs @indent-sm;

				&.js-deselectLink,
				&.js-deselectUser {
					color: @brand-green;
				}

				&.js-selectLink,
				&.js-selectUser {
					color: @gray-light;
				}
			}
		}
	}

	.account-cards {
		position: relative;
		margin: @indent-md 0 0 0;

		.card {
			margin-bottom: @indent-lg;
		}

		.remove,
		.remove-item,
		.edit-item {
			position: absolute;
			right: 10px;
			top: 0;
		}

		.edit-item + span .remove-item {
			top: 20px;
		}

		.remove a,
		.remove-item {
			color: @brand-red;
		}
	}

	.buttons-set {
		margin-top: @indent-lg;
	}

	.pagination-bar {
		width: 100%;

		div {
			display: block;
		}
	}

	.pagination-toolbar {
		display: block;
	}

	.pagination-wrap {
		float: right;

		.pagination {
			li {
				float: none;
				display: inline-block;
			}
		}
	}

	li[class*="date"] .input-box,
	li[class*="Date"] .input-box {
		position: relative;

		.icon-calendar {
			font-size: 18px;
			content: @fa-var-calendar;
			position: absolute;
			top: 8px;
			right: 15px;
			color: @brand-blue;
			cursor: pointer;
		}
	}

	#content {
		&:not.user-edit-container {
			.accordion-header {
				background-color: transparent;
				height: auto;
				padding: 0;
				width: auto;

				&:before {
					display: none;
				}

				&:after {
					display: inline-block;
					content: @fa-var-plus-circle;
					position: relative;
					right: auto;
					color: @brand-green;
					top: 10px;
				}

				&.gor-active {
					&:after {
						content: @fa-var-minus-circle;
						color: @brand-red;
					}
				}

				a {
					display: inline-block;
					font-size: @font-size-base;
					margin-left: @indent-sm;
				}
			}
		}

		.user-edit-container {
			.accordion-content {
				top: 64px;
			}
		}
	}


	.panel-group {
		margin-top: @indent-lg;
	}

	.horizontal-form {
		.form-list li {
			margin: 0 0 @indent-sm 0;

			> div {
				float: right;
				text-align: left;
			}

			label {
				line-height: 40px;
				display: inline-block;
				width: auto;
			}
		}
	}

	.c-confirm-modal {

		.modal-content {
			width: 100%;
			padding: 0;
		}
	}
}

// spinner over modal
.modal-spinner {
	&:extend(.spinner all);

	&.modal-overlay-spinner {
		z-index: @zindex-modal-overlay;
	}
}

.company-description {
	padding-left: 2px;
	margin-top: 19px;
	font-size: 16px;
	letter-spacing: -0.1px;
	line-height: 1.3;
	margin-bottom: 24px;
	clear: both;
}

.company-settings__info {
	border: 1px solid @brand-blue;
	padding: 5px;
}

.company-settings__info-remove,
.company-settings__info-text {
	font-size: @font-size-small;
	color: @brand-black;
	font-family: @font-family-base;
	line-height: @line-height-base;
	margin: 0;
}

.company-settings__info-text {
	display: inline-block;
}

.company-settings__info-remove {
	text-decoration: underline;
	font-weight: @font-weight-bold;
	color: @brand-blue;
	cursor: pointer;
}

.company-settings__info-check {
	float: right;
	color: @brand-green;
}

.page-address-book a.remove {
	color: @brand-white;
}

.pagination-bar.top div {
	text-align: left;
}

/*
 * New Quote Modal
 */
.helpful-hint-popup,
.confirmation-popups,
.modal-popup,
#share-order,
#modal-email-order,
#modal-email-quote,
#rename-quote-modal,
#share-quote-order,
#modal-duplicate-as-quote,
#new-quote-modal-popup,
#new-quote-modal-popup-async {
	.modal-content {
		width: 380px;
		text-align: left;

		li > label {
			font-size: @font-size-large;
			margin-bottom: 10px;
		}

		a {
			display: block;
			margin-top: 7px;
		}

		.cancel-quote {
			width: 60px;
		}
	}
}

.modal-popup {

	&.copy-quote-popup {

		.modal-content {

			a {
				display: inline-block
			}
		}
	}
}

.modal-popup--wide {
	.modal-content {
		width: 490px;
	}
}

.modal-popup--med {

	.modal-content {
		width: 467px;
	}
}

.c-new-user-modal {

	.modal-content {
		width: 100%;
		max-width: 620px;
		padding: 0;
	}
	.section-inner {
		padding-left: 31px;
		padding-right: 31px;
	}


	.role-list {
		padding-left: 0;
	}
	.button-set {
		margin-top: @indent-md;
	}
}

.close-popup--block {
	display: block;
	margin-top: @indent-md;
}

.section-inner-line {
	clear: both;
	border-bottom: 1px solid @gray-base;
	width: 100%;
	padding: 0 21px;
	margin-top: @indent-lg;
}

.section-inner__list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.section-inner__title {
	padding-bottom: 10px;
	font-size: @font-size-h5;
	display: inline-block;
}

.section-inner__list-item {
	margin-bottom: 10px;
}

.c-new-group-modal,
.c-new-address-modal {

	.modal-content {
		padding: 0;
	}
}

.confirmation-popups {
	.modal-content {
		address {
			margin-bottom: @indent-lg;
		}
		a {
			display: inline-block;
		}
	}
}

.page-add-edit-address {
	.buttons-set {
		margin-top: @indent-lg;

		a {
			display: block;
			margin-top: @indent-lg;
		}
	}
}

.gor-tray {
	position: static;
	top: auto;
	transform: translateX(0);
}

.accordion-content {
	position: relative;
}

.spinner {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(236, 236, 236, 0.5);
	z-index: 1;
	display: none;

	&:before {
		.icon;
		.icon-spin;
		content: @fa-var-spinner;
		position: fixed;
		top: 50%;
		left: 50%;
		font-size: 40px;
		line-height: 1;
		margin: -@indent-lg 0 0 -@indent-lg;
	}
}

.pricing-spinner {
	&:extend(.spinner all);
	position: absolute;

	&:before {
		position: relative;
	}
}

.add-to-cart-spinner {
	position: fixed;
}

.quick-price {
	.pricing-container {
		margin-top: @indent-sm;
	}
	.ui-autocomplete {
		&.ui-menu {
			border: 1px solid @gray-darker;
			border-top: 0;
			border-radius: 0;
			z-index: 1000;
			margin: 0;
			padding: 0;
		}

		.ui-menu-item {
			&:nth-child(1) {
				border-top: 1px solid @gray-light;
			}

			span {
				display: block;
				font-weight: @font-weight-normal;
				color: @text-color;
				padding: @indent-sm;
				&:hover {
					color: @brand-blue;
					background: @gray-lighter;
					font-weight: @font-weight-bold;
				}
			}

			&.ui-state-focus span {
				color: @brand-blue;
				background: @gray-lighter;
				font-weight: @font-weight-bold;
			}
		}
	}

	.form-list li.product-option:first-child {
		margin-top: 16px;
	}
}

.helpful-hint {
	position: relative;
	display: inline-block;
	width: 14px;
	height: 13px;
	vertical-align: middle;
	top: -3px;

	th & {
		position: static;
		color: #678695;
		font-size: 15px;
		display: inline-block;
		left: auto;
		top: auto;
		overflow: visible;
		vertical-align: top;
		margin-top: -2px;

		i {
			line-height: 13px;
			position: static;
			left: auto;
			top: auto;
		}
	}

	i {
		position: absolute;
		top: -3px;
		left: 0;
		color: @brand-blue;
		font-size: 15px;
	}
}

.owl-carousel .owl-stage-outer {
	height: percentage(436/1040);
}

.question-mark {
	display: inline-block;
	background-color: @brand-green;
	width: 20px;
	height: 20px;
	border-radius: 100%;
}

.icon-exclamation-triangle {
	color: @brand-blue;
}

.ui-helper-hidden-accessible {
	display: none;
}

.table {
	.custom-styled-select {
		text-align: left;
		margin-top: 13px;

		.outer {
			.inner {
				font-size: @font-size-base;
				text-transform: capitalize;
			}
		}
	}
}

.control-group {
	&.phone,
	&.fax {
		clear: both;
	}
}

.table-header-link {
	padding-left: percentage(10/980);
	a {
		color: @brand-black;
		display: block;
		text-decoration: none;
	}

	span {
		background-color: @gray-light;
		float: left;
		padding: 10px percentage(25/970);
		margin-left: 2px;

		&.active {
			background-color: @brand-white;
			position: relative;
			margin-left: 0;
			padding: 10px percentage(27/970);

			+ span {
				margin-left: 0;
			}

			&:before {
				content: "";
				display: block;
				width: 100%;
				height: 6px;
				background-color: @brand-red;
				position: absolute;
				left: 0;
				top: -3px;
			}
		}
	}
}

.back-to-top-container {

	.back-to-top.fixed {
		position: fixed;
		bottom: 20px;
		display: block;
		z-index: @zindex-back-to-top;
	}
}

.pstrength-bar {
	height: 0 !important;
}

.page-updatePassword #updatePasswordForm {
	margin-top: 16px;
}

.pstrength-minchar {
	display: none;
}

.edit-password {
	margin-top: 17px;
}


.back-to-top {
	cursor: pointer;

	&:not(.link) {
		background-color: @brand-blue;
		padding: @indent-sm 14px;
		height: 40px;
		width: 40px;

		&:before {
			.icon;
			content: @fa-var-arrow-up;
			color: @brand-white;
			font-size: @font-size-base;

		}
	}

	.page-cartPage & {
		margin-top: 40px;
		margin-bottom: 70px;
	}
}

.color-disclaimer {
	font-size: @font-size-small;
	display: inline-block;
	color: @gray-dark;
	margin-right: 3px;
	font-weight: @font-weight-normal;
	line-height: 1.3;
}


.sort-by-label {
    float: left;
    margin: 0 @indent-md 0 0;
    font-size: @font-size-h4;
    line-height: 40px;
    font-weight: @font-weight-semi-bold;
}

body {
	&.overflow-modal {
		overflow: hidden;
	}
}

// global file uploader message hidden on multiple login page
.page-multipleLogin {
	height: auto;
	.custom-global-message {
		display: none;
	}
}

@media screen and (max-width: @screen-md) {
	.mobile-content {
		display: block;

		+ span > strong {
			font-weight: @font-weight-bold;
		}
	}
	.page-quote,
    .page-order {
		.print {
			margin-bottom: 0;
		}
		.options-top{
			margin-top: 0;
		}
	}


	.mobile-content {

		&.mobile-content-condensed {

			&.active {
				display: block;
				margin-left: 33px;
			}

			.quote-created {

				&.active {
					margin-left: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 799px) {
	.col-sm-12 + .col-sm-12 .account-section{
		margin-top: @indent-xl;
	}

}

@media screen and (max-width: @screen-sm) {
	#new-quote-modal-popup .modal-content {
		width: 80%;
	}

	.helpful-hint-popup,
	.confirmation-popups,
	.modal-popup--standard {
		.modal-content {
			width: 80%;
			text-align: left;
		}
	}

	.table-header-link {
		padding-left: @indent-xs;
	}
}

@media screen and (max-width: 374px) {
	.table-header-link {
		padding-left: 0;

		span {
			margin-left: 0;
			width: 100%;
		}
	}
}

/*
* Carousel
*/
.slider {
	&.product-grid {
		width: 100%;
		float: none;
		padding: 0 0 50px;

		a {
			color: @brand-black;
		}

		.owl-nav {
			top: 0;
			left: 0;
			transform: none;
			z-index: 100;
		}

		.owl-nav .owl-next,
		.owl-nav .owl-prev {
			padding-top: percentage(50/980);
			line-height: 1;
			color: @gray-base;
		}

		.owl-dots {
			bottom: 10px;

			.owl-dot {
				span {
					width: 15px;
					height: 15px;
					background-color: @gray-base;
				}

				&.active span {
					background-color: @brand-red;
				}
			}
		}
	}
}


.product-option.color {

	.custom-styled-select.with-custom-options ul li:not(:first-child) {
		padding-left: 42px;
		position: relative;

		span:not(.inner-text) {
			position: absolute;
			left: 10px;
			top: 50%;
			margin-top: -@indent-sm;
			display: inline-block;
			width: 20px;
			height: 20px;
			border-radius: 50%;
		}
	}

	.custom-styled-select .inner {
		overflow: visible;

		.inner-text {
			cursor: pointer;
			display: inline-block;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			vertical-align: middle;
		}

		span:not(.inner-text) {
			position: absolute;
			left: 15px;
			top: 50%;
			margin-top: -@indent-sm;
			display: inline-block;
			width: 20px;
			height: 20px;
			border-radius: 50%;
		}

		span + .inner-text {
			padding-left: 28px;
		}
	}
}

@media screen and (max-width: 600px) {
	.slider {
		&.product-grid {

			.owl-nav {
				top: auto;
				bottom: 123px;
				z-index: 1;
			}

			.owl-dots {
				padding: 0 @indent-md;
			}

			.item {
				margin-bottom: @indent-xl;
				margin-right: percentage(31/315);
				float: left;
				width: percentage(142/315);

				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}

		&.product-grid,
		&.category-grid {
		    padding-bottom: @indent-lg;
		}
	}
}

iframe {
	width: 1px !important;
	min-width: 100% !important;
}



/*
* Category Landing Page
*/
.page-category {
	.footer-top {
		margin-top: 0;
	}

	.page-header {
		margin-bottom: 0;
	}

    .category-list-item-wrapper {
        padding-bottom: @indent-lg;
        margin-bottom: @indent-lg;
        border-bottom: 1px solid @gray;

        &:last-child {
            border: 0;
        }
    }

    .category-list-item.row {
        a {
            color: @brand-black;
            text-decoration: none;
            font-weight: @font-weight-bold;
        }

        .thumb {
            a {
                img {
                    border: 2px solid @gray;
                }
            }
        }
    }
}

.category-wrapper {
	background-color: @brand-white;
}

.category-grid {
	&:extend(.product-grid all);
	&.category-items-wrapper {
		float: none;
		width: auto;
		margin: 0 -17px;
	}
	.category-list-item {
		margin-bottom: 47px;
	}
}

.file-text {
	display: block;
	font-size: @font-size-small;
	line-height: 1;
	margin-bottom: @indent-sm;
}


.file-upload {
	position: relative;

	&.error {
		.drop-zone {
			border-color: @brand-red;
		}
	}

	> div.drop-zone,
	> div.drop-zone-custom-product {
		&:extend(.input-text);
		float: left;
		padding-top: 4px;
		padding-bottom: 4px;
		line-height: 1;
		width: calc(~'100% - 104px');
		cursor: pointer;
		color: @gray-light;
		position: relative;
		z-index: 2;

		span {
			text-decoration: underline;
			font-weight: @font-weight-bold;
		}
	}

	.file-upload {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: calc(~'100% - 104px');
		padding: 0;
		opacity: 0;
		z-index: -1;
	}



	.file-added {
		#tax-exempt-file & {
			opacity: 0;
		}
	}

	.dz-file-preview {
		&:extend(.input-text);
		padding-top: @indent-xs;
		padding-bottom: 4px;
		font-size: 12px;
		line-height: 1;
		background-color: @brand-white;
		z-index: 10;
		width: calc(~'100% - 104px');
		position: relative;

		.js-delivery-jobsite &,
		#tax-exempt-file & {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
		}

		#note-files & {
			position: relative;
			border-top: 0;
		}
	}

	button {
		margin-left: 9px;
	}

	.dz-success-mark,
	.dz-error-mark {
		display: none;
	}

	.dz-size,
	.dz-filename {
		.js-delivery-jobsite &,
		#tax-exempt-file & {
			width: 100%;
		}
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.dz-error-message span {
		color: @brand-red;
	}

	.dz-size {
		line-height: 20px;
	}

	.error + label + div {
		border-color: @brand-red;
		margin-bottom: 28px;
	}

	.dz-image {
		display: none;
	}

	.dz-progress {
		display: none;
	}

	.dz-processing {
		.dz-progress {
			display: block;
			border: 1px solid @gray;
			padding: 0;
			width: 100%;
			border-radius: 3px;
			height: 10px;
			overflow: hidden;

			span {
				display: block;
				width: 0;
				height: 8px;
				background-color: @brand-green;
			}
		}

		.dz-remove {
			display: none;
		}
	}

	.dz-success .dz-progress {
		display: none;
	}

	.dz-success .dz-remove {
		display: block;
	}

	.dz-success .dz-success-mark {
		position: absolute;
		display: block;
		top: 0;
		right: 5px;
		height: 20px;

		&:before {
			.icon;
			content: @fa-var-check;
			font-size: 15px;
			color: @brand-green;
		}

		svg {
			display: none;
		}
	}

	.dz-clickable {
		font-style: italic;
	}

	+ .message {
		display: block;
		font-size: @font-size-small;
		line-height: 1.1;
		width: calc(~'100% - 104px');
		margin-top: @indent-lg;
	}
}

.custom-upload-input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.custom-upload-label {
	border: 1px solid @brand-blue;
	padding: 5px;
	font-family: @font-family-sans-serif;
	font-size: @font-size-small;
	font-weight: @font-weight-normal;
	line-height: @line-height-base;
	color: @gray-base;
	font-style: italic;
	cursor: pointer;
	margin: 0;

	.highlighted {
		font-weight: @font-weight-bold;
		text-decoration: underline;
	}
}

.show-mobile + #globalMessages {
	margin-bottom: @indent-lg;
}

.mobile-upload {
	display: none;
}

.dashboard-mobile-link {
	display: block;
	width: 100%;
	height: 100%;
	color: @brand-black;
	text-decoration: none;

	&:hover {
		color: @brand-black;
	}
}

.file-upload {

	.upload-preview {

		.dz-image {
			display: block;
		}
	}
}

@media screen and (max-width: 650px) {


	.file-upload {
		position: relative;

		>div.drop-zone {
			float: none;
		}

		.file-upload {
			width: 100%;
		}

		> div.drop-zone,
		> div.drop-zone-custom-product {
			width: 100%;
		}

		button {
			margin-left: 0;
			margin-top: @indent-xl;
			width: 100%;
		}

		.desktop-upload {
			display: none;
		}

		.mobile-upload {
			display: block;
		}


		label.error {
			bottom: 55px;
		}

		.dz-file-preview {
			width: 100%;
		}
	}
}


//
// global layout and styling helpers
// ----------------------------------------

.text-center {
	text-align: center;
}

.red {
	color: @brand-red;
}


.print {
	margin-bottom: @indent-lg;

	a {
		display: inline-block;
		position: relative;
		color: @brand-black;
		float: right;
		line-height: 1;
		text-decoration: none;
		padding-right: 22px;
		z-index: 0;

		i {
			position: absolute;
			font-size: 16px;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.print-quote{
	padding-top: 25px;
}

.section-container
{
	&.padded {
		padding: 1px @indent-lg 40px;
	}

	&.background {
		background-color: @brand-white;
	}

	&.spaced-both {
		margin-top: @indent-lg;
		margin-bottom: @indent-lg;
	}
	&.spaced-top {
		margin-top: @indent-lg;
	}
	&.spaced-bottom {
		margin-bottom: @indent-lg;
	}
}


//
// mobile conditional displays for 640px
// ----------------------------------------
@media screen and (max-width: @screen-md) {
	.hide-mobile {
		display: none !important;
	}

	.account-orderhistory .actions-tab,
	.actions-tab {
		margin-top: @indent-lg;
	}
}


@media screen and (min-width: 801px) {
	.visible-mobile {
		display: none !important;
	}
}

@media screen and (max-width: @screen-sm) {
	.modal-popup--wide {
		.modal-content {
			width: 100%;
		}
	}

	.modal-popup--med {
		.modal-content {
			width: 100%;
		}
	}
}


//
// Special Hidden Field
// ----------------------------------------

.hidden-text-input {
	height: 0px;
	padding: 0px;
	border: none;
	position: absolute;
}

.pwdMatchCheck {
	.icon-check-circle {
		color: @brand-green;
		padding: @indent-sm @indent-sm @indent-sm 0;
		font-size: 20px;
		vertical-align: middle;
	}
	&.not-visible {
		.hide;
	}
}

// Region Select width

.regionIso {
	.custom-options {
		min-width: 310px;
		border-top: @select-border !important;
	}
}

.site-map {
    background-color: @brand-white;
    margin-top: @indent-xl;

    ul {
        padding-bottom: @indent-sm;
    }

    ul li a {
        line-height: 30px;
    }
}

.page-siteMap {
    .back-to-top-container {
        margin-top: @indent-xl;
    }
}

.invalid-cell {
	position: relative;
	padding-top: 50px !important; //override long table selector
}
.custom-error {
	display: flex;
	background: @brand-red;
	overflow: hidden;
}

.custom-error__icon {
	position: relative;
	float: left;
	width: 28px;
	min-width: 28px;
	height: 28px;
	background: @brand-white;
	color: @brand-red;
	border-radius: 50%;
}
.custom-error__icon::before {
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	font-size: 20px;
}
.custom-error--wide {
	clear: both;
	padding: @indent-lg;
	margin: 0 @indent-sm @indent-md;
}

.custom-error--inline {
	float: left;
	display: inline-block;
	padding: 2px @indent-md 4px @indent-sm;
}

.custom-error--table-error {
	position: absolute;
	top: 0;
	width: 400px;
	z-index: 5;

}

.custom-error__text {
	color: @brand-white;
	margin-left: @indent-sm;
	flex-grow: 1;
}

.custom-error__text--large {
	font-size: @font-size-large;
	font-weight: @font-weight-semi-bold;
}

//MASS ATTRIBUTE EDIT
.editor-tabs__container {
	background: @brand-white;
}

.editor-tabs__heading {
	font-size: @font-size-large;
}

.editor-tabs--wide {
	padding: 0 @indent-xl;
}

.editor-tabs__filter {
	//important added to avoid deep nesting
	ul {
		width: 169px !important;
	}
	li {
		padding-left: 40px !important;
	}
}
// accordion updates
.gor-accordion {

	&.editor-accordion {

		.accordion-header {
			background: @brand-blue;
			font-size: @font-size-large;
			font-weight: @font-weight-bold;
			height: auto;
			padding: @editor-accordion-padding;
			text-transform: capitalize;

			&:before {
				display: none;
			}
		}

		.accordion-item {
			padding: 0 @indent-sm;
		}


	}
}

// editor tabs updates
.editor-tabs {

	.tabs-row {
		margin-left: 40px;
	}

	.disabled {
		opacity: .5;
		pointer-events: none;
	}

	.tabs-content {
		padding: 40px 20px;
	}
}

.select-error {
	.outer {
		border: 2px solid @brand-red;
	}
}

.select-label-error {
	color: @brand-red
}

.edit-attribute-table {
	.product-info {
		p {
			line-height: 21px;
		}
	}
}

.p-relative {
	position: relative;
}

.tag-non-standard {
	background-color: #F8F8F8;
	padding: 0 5px;
	text-decoration: underline;
	font-weight: 500;
	color: #000000;
	font-size: 14px;
	position: absolute;
	top: 8px;
	left: 150px;
	cursor: pointer;
	z-index: 10;
}

.tag-non-standard-mini {
	background-color: #F8F8F8;
	padding: 0 5px;
	text-decoration: underline;
	font-weight: 500;
	color: #000000;
	font-size: 10px;
	cursor: pointer;
	float: right;
	z-index: 10;
}

.tag-non-standard-flex {
	/*background-color: #F8F8F8;*/
	padding: 0 5px;
	/*text-decoration: underline;*/
	font-weight: 500;
	color: #ffffff;
	font-size: 14px;
	height: 23px;
    margin-top: 7px;
	margin-left: 15px;
	cursor: pointer;
	z-index: 10;
}

.cart-itemName-flex {
	display: flex;
}

#viewSizeGuideTop {
	margin-bottom: 15px;
}

.non-standard-body-modal {
	padding: 0 15px 15px 15px;
	color: @brand-black;
}

.cart-details { //override
	.edit-attribute-table {
		tr.edit {
			.row {
				.bundling {
					margin-top: @indent-md;
				}
			}
		}
	}
}

.custom-placeholder {
	.placeholder(@brand-black, normal);
}


@media screen and (max-width: @screen-md) {
	.invalid-cell {
		padding: 0 !important;
	}

	.edit-attribute-table {
		.product-info {
			p {
				line-height: normal;
				margin-top: @indent-sm;

			}
		}
	}
}
#cartMessages .messages li.error-msg ul {margin-bottom: 15px;}
