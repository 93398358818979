@media screen and (max-width: 980px) {
    .header-top {
        .site-logo {
            padding: 16px 0 17px 37px;

            a {
                width: 131px;
            }
        }
    }
}

@media screen and (max-width: 320px){
     .header-top {
        .site-logo {
            padding: 19px 0 17px 47px;
            a {
                width: 95px;
            }
        }
     }
}