.banner-component,
.rotating-images {
	margin-bottom: 30px;

	.page-header {
		margin-bottom: 0;

		.h1 {
			margin: 0 0 7px 0;
			letter-spacing: -0.1px;
			word-wrap: break-word;
		}
	}
}

.owl-carousel .owl-dots .owl-dot {
	bottom: 0;
	span {
		background: @gray-light;
	}

	&.active {
		span {
			background: @brand-red;
		}
	}
}

.page-header {
	width: 100%;
	height: 150px;
	background: url("../images/dashboard.jpg");
	background-size: cover;
	margin-bottom: 30px;
	z-index: 0;
	position: relative;

	.page-quote &,
	.page-order & {
		z-index: 1;
	}

	> .container-fluid {
		height: 100%;
		position: relative;
	}

	.container-fluid .container-fluid {
		padding-left: 0;
		padding-right: 0;
	}

	.greeting {
		position: absolute;
		color: @brand-white;
		font-size: @font-size-h5;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;

		h1 {
			margin: 0 0 7px 0;
			letter-spacing: -0.1px;
			word-wrap: break-word;
		}
	}

	.greeting__button {
		position: absolute;
		right: 30px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;
	}

	.top-greeting {
		display: block;
		font-size: @font-size-large;
		font-weight: @font-weight-normal;
		margin-bottom: -3px;
	}

	.header-button {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 30px;
		z-index: 2;

		.button {
			min-width: 0;
		}
	}
}

.page-header-overlay {
	&:after {
		content: "";
		display: block;
		position: absolute;
		background-color: #000;
		width: 100%;
		height: 100%;
		opacity: 0.4;
		top: 0;
		left: 0;
		z-index: 1;
	}
}

.section-header {
	height: 50px;
	font-size: 24px;
	font-weight: 400;
	text-align: left;
	line-height: 18px;
	padding: @accordion-trigger-padding;
	background-color: @accordion-trigger-bg;
	color: @accordion-trigger-color;
	outline: none;
	position: relative;
	width: 100%;

	&:before {
		background-color: @brand-red;
		content: "";
		display: block;
		height: 50px;
		left: 0;
		position: absolute;
		top: 0;
		width: 10px;
	}
}


.section-inner{
	padding: 20px 21px 23px 21px;
	background-color: @brand-white;

	.comments {
		margin-bottom: 20px;
	}

}

.page-websiteFeedback .section-inner {
	margin-top: 30px;
}

.cms-content {
	.clearfix;
	background-color: @brand-white;
	padding: 0 20px;
	margin: 30px 0 70px;
}

#iframe-register-form {
	min-height: 1000px;
}

.banner-component + #content {
	#breadcrumb,
	.category-breadcrumbs {
		margin-top: -30px;
	}
}

.page-multiStepCheckoutSummaryPage{

	#paypal-error-layer {

		.page-productDetails #addto-cart-layer;

		.popupCartItem{
			border: none;
		}

		.error-article{
			font-size: @font-size-h3;
			padding-bottom: 20px;
			border-bottom: 1px solid @brand-white;
			text-align: center;
		}

		.error-message{
			font-size: @font-size-h4;
			margin-bottom: 0;
			text-align: center;
		}
	}
}

@media screen and (max-width: 580px) {
	#iframe-register-form {
		min-height: 1600px;
	}
}

@media screen and (max-width: @screen-md) {
	.page-header {
		h1 {
			font-size: 30px;
		}
	}
}

@media screen and (max-width: @screen-sm) {
	.page-header {
		height: auto;
		min-height: 100px;

		h1 {
			font-size: 24px;
		}
		.greeting {
			position: relative;
			font-size: @font-size-base;
			width: 100%;
			line-height: 1.2;
			transform: translateY(0);
			margin-bottom: 24px;

			.top-greeting {
				margin-top: 24px;

				+ h1 {
					margin-top: 3px;
				}

			}

			h1 {
				margin-top: 24px;
				margin-bottom: 0;
			}

			span {
				display: block;
				margin-top: 3px;
			}
		}

		.greeting__button {
			position: relative;
			right: inherit;
			top: inherit;
			transform: none;
		}

		.greeting__no-button:not(.quote-created) {
			width: 100%;
			padding-right: 30px;
			padding-left: 30px;
			position: absolute;
			left: 0;
			transform: translateY(-50%);

			h1 {
				margin-top: 0;
				font-size: @font-size-h3;
			}

			.top-greeting {
				margin-top: 0;
				margin-bottom: 3px;
			}
		}


		.header-button {
			margin-bottom: 22px;
			position: relative;
			transform: translateY(0);
			width: 192px;
			left: auto;
			top: auto;
			right: auto;

			.button {
				width: 100%;
				padding: 12px 0;

				&.org-header-button {
					width: auto;
					padding: @btn-default-padding;
				}
			}

			.actions-tab {
				width: 120px;

				.gor-accordion .accordion-content {
					right: auto;
					left: 0;
				}

				.gor-accordion .accordion-header:before {
					left: -2px;
					right: auto;
				}
			}
		}

		.container-fluid {
			min-height: 100px;
		}
	}
}