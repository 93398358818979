//
// Tabby
// --------------------------------------------------

//
// Define Tabby
// --------------------------------------------------
.gor-tabs {
    margin-bottom: @line-height-computed;

    .tabs-tab {
        &:extend(.button);
        background-color: @tabby-tab-bg;
        color: @tabby-tab-color;

        &.gor-active {
            background-color: @tabby-tab-bg-active;
            color: @tabby-tab-color-active;
        }
    }
    .tabs-content {
        display: none;
        padding: @tabby-content-padding;

        transition: opacity .25s ease;
        opacity: 0;

        &.gor-active {
            opacity: 1;
        }
    }
}
