//
// Buttons
// --------------------------------------------------

//
// Mixins
// --------------------------------------------------

//Reset Styles
.button-reset(){
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    background-color: transparent;
    border: none;
    white-space: nowrap;
    text-decoration: none;
    min-width: 220px;
}

//Set Box Model Properties
.button-size(@padding; @font-size; @line-height; @border; @border-radius) {
    padding: @padding;
    font-size: @font-size;
    line-height: @line-height;
    border: @border;
    border-radius: @border-radius;
}

//Set Style Properties
.button-variant(@color; @background; @border-color; @font-weight) {
    color: @color;
    background-color: @background;
    border: 1px solid @border-color;
    font-weight: @font-weight;
    transition: 0.3s ease-out;

    &:focus,
    &:hover {
        color: @color;
        background-color: darken(@background, 10%);
        border-color: darken(@border-color, 10%);
    }
    &[disabled]{
        opacity: .5;
        cursor: not-allowed;
        pointer-events: none;

        &:focus,
        &:hover {
            background-color: @background;
            border-color: @border-color;
        }
    }
}

//Full Width Button
.button-block{
    display: block;
    width: 100%;
}

//
// Define Buttons
// --------------------------------------------------
.button{
    .button-reset();
    .button-size(@btn-default-padding, @btn-default-font-size, @btn-default-line-height, @btn-default-border, @btn-default-border-radius);
}

.button-default{
    .button-variant(@btn-default-color, @btn-default-bg, @btn-default-border-color, @btn-default-font-weight);
}

.button-alternate{
    /*.button-variant(@btn-alternate-color, @btn-alternate-bg, @btn-alternate-border-color, @btn-alternate-font-weight);*/
    .button-variant(@btn-default-color, @btn-default-bg, @btn-default-border-color, @btn-default-font-weight);
}

.link {
    .button-reset();
    display: inline-block;
    vertical-align: baseline;
    padding:0;
    background-color: transparent;
    line-height:inherit;
    color: @link-color;
    text-decoration: @link-decoration;
    min-width: inherit;
    font-weight: @font-weight-bold;

    &:hover {
        text-decoration:@link-hover-decoration;
    }
}

.buttons-set{
    & + .buttons-set{
        margin-top: 10px;
    }
    .button + .button{
        margin-left: 17px;
    }
    .button-block{
        & + .button-block{
            margin-top: 10px;
            margin-left: 0;
        }
    }
}

.button-narrow {
    min-width: 0;
}

.button-return-to-invoices {
    margin-top: 20px;
}

@media screen and (max-width: 640px) {
    .buttons-set{
        .button:not(.button-narrow) + .button{
            margin-left: 0;
        }
    }
}

@media screen and (max-width: @screen-sm) {
    .button:not(.button-narrow):not(.link) {
        width: 100%;
        min-width: auto;

        + .button {
            margin-top: 20px;
        }
    }
}