.page {
	overflow-x: hidden;
}

#header {
	width: 100%;
	background-color: @brand-white;
}

.header-top {
	height: 65px;
	background-color: @gray-darker;
	color: @brand-white;
	font-size: @font-size-h6;
	position: relative;

	ul {
		float: right; 

		.smart-edit-header-link-wrapper {
			float: left;
		}
	}

	li.level0 {
		position: relative;
	}

	li.level0.active {
		background-color: @brand-white;

		> a {
			background-color: @brand-white;
			color: @brand-red;

			&:after {
				position: absolute;
				bottom: -2px;
				left: 0;
				content: "";
				background-color: #ffffff;
				display: block;
				height: 2px;
				width: 100%;
				z-index: 10000;
			}
		}
	}

	.menu-wrapper {
		position: absolute;
		top: -100%;
		right: 0;
		width: 225px;
		height: auto;
		background-color: @brand-white;
		border: @menu-dropdown-border;
		visibility: hidden;
		opacity: 0;
		z-index: 100;
		transition: opaciy 0.3s ease;
		box-shadow: @menu-dropdown-shadow;

		ul {
			float: none;
			margin-bottom: 0;


			li {
				border: 0;
				width: 100%;
			}
		}

		a {
			color: @brand-black;
			width: 100%;


			&:hover {
				color: @brand-blue !important;
				background-color: transparent !important;
			}
		}

		&.show {
			top: 100%;
			visibility: visible;
			opacity: 1;
		}
	}


	.header-account-info {
		float: right;
		text-align: right;
		width: calc(~"100% - 382px");
		overflow: hidden;
		height: 48px;

		&.organization {
			width: calc(~"100% - 430px");
		}

		.image-mask {
			display: inline-block;
			width: 30px;
			height: 30px;
			background-color: #fff;
			margin: 9px 0;
			overflow: hidden;
			border-radius: 100%;

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}


	li:not(.active):not(.mini-cart-item) {
		.cart-trigger,
		> a,
		> div > a {
			&:hover:not(.button):not(.close) {
				color: @brand-white;
				background-color: @header-hover;
			}
			&:focus:not(.button):not(.close) {
				color: @brand-white;
			}
		}
	}

	li {
		float: left;
		border-right: 1px solid @gray-dark;

		&:first-child {
			border-left: 1px solid @gray-dark;
		}

		.cart-trigger,
		> a,
		> div > a {
			display: inline-block;
			padding: 12px 14px;
			color: @brand-white;
			text-decoration: none;
			font-weight: @font-weight-normal;
		}

		// Menu elements inside My Account, My Organization dropdowns
		.level0 li a {
			padding: @indent-xs @indent-md;
		}

		.active .cart-trigger {
			background-color: @header-hover;
		}

		.cart-trigger {
			padding: 10px 27px 2px 11px;
			position: relative;
			/* autoprefixer: off */
        	-ms-touch-action: none !important;
        	/* autoprefixer: on */

			.icon-cart-nci {
				font-size: 30px;
				line-height: 1;
				color: @gray-dark;

			}

			.count {
				font-size: @font-size-base;
				line-height: 10px;
				position: absolute;
				top: 6px;
				right: 13px;
				height: 24px;
				min-width: 24px;
				text-align: center;
				background-color: @gray-dark;
				border-radius: 100%;
				padding: 7px 0;
				color: @gray;

			}

			.price {
				display: none;
			}

			&.enable {
				.icon-cart-nci {
					color: @brand-white;
				}
				.count {
					color: @brand-white;
					background-color: @brand-green;
				}
			}
		}
	}

	.site-logo {
		display: none;
	}
}

.header-wrapper {
	.site-logo,
	.smartedit-site-logo-wrapper {
		float: left;
		width: percentage(128/980);
		margin-right: 41px;

		.site-logo {
			width: 100%;
		}

		a {
			display: block;
			padding-top: 16px;
			height: 70px;
		}

		img {
			display: block;
			width: 100%;
			height: auto
		}
	}
}

.site-search-wrapper {
	float: right;
	width: percentage(230/980);
	margin-top: 15px;

	.controls {
		position: relative;

		button {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			border: none;
			background: none;
			padding: 0 12px 0 6px;

			.icon-search {
				font-size: 18px;
			}
		}
	}
}

.languagecurrencycomponent {
	display: none;
}

.site-search {
	.input-text {
		padding-top: 10px;
		padding-bottom: 10px;
		border: 1px solid @gray-darker;
		padding-right: 44px;
	}
}

#search-auto-complete {
	position: relative;



	.ui-autocomplete {
		&.ui-menu {
			border: 1px solid @gray-darker;
			border-top: 0;
			border-radius: 0;
			width: 100% !important;
			top: -3px !important;
			z-index: 1000;
			margin: 0;
			padding: 0;
		}

		.ui-menu-item {
			&:nth-child(1) {
				border-top: 1px solid @gray-light;
			}
			a {
				font-weight: normal;
				color: @text-color;
				padding: 10px;
				&:hover {
					color: @brand-blue;
					background: @gray-lighter;
					font-weight: @font-weight-bold;
				}
			}

			&.ui-state-focus a {
				color: @brand-blue;
				background: @gray-lighter;
				font-weight: @font-weight-bold;
			}
		}
	}
}


.ui-helper-hidden-accessible {
	display: none;
}

//
// If we are working with responsive, compile this
// --------------------------------------------------
& when (@is-responsive = true) {
    @media screen and (max-width: @screen-lg) {
        .header-top {
            li:not(.active):not(.mini-cart-item) {
                a.cart-trigger {
                    &:hover,
                    &:focus {
                        &:not(.button):not(.close){
                            color: @brand-white;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }

	@media screen and (max-width: 980px) {
		.header-wrapper {
			.site-logo {
				display: none;
			}
		}

		.header-top {
			padding: 0;
			height: 65px;
			background-color: @brand-white;
			text-align: center;

			li .active .cart-trigger.enable {
				.icon-cart-nci {
					color: @gray-dark;
				}
			}

			.header-account-info {
				display: none;
			}

			.site-logo {
				display: block;
				padding: 19px 0 17px 47px;
				width: calc(~"100% - 147px");
				text-align: center;
				float: left;

				.simple-banner {
					display: inline-block;
				}

				a {
					width: 95px;
					height: 29px;
					display: block;

					img {
						display: block;
						width: 100%;
						height: auto;
					}
				}
			}

			ul {
				float: right;

				li {
					display: none;
					border: none;

					&:last-child {
						float: right;
						display: block;
					}
				}
			}

			li a.cart-trigger {
				padding: 18px 13px 0 8px;

				&:hover {
					background-color: transparent;
				}

				.icon-cart-nci {
					font-size: 28px;
					color: @gray-base;
				}
				.count {
					right: 0;
					top: 11px;
					background-color: @gray-base;
					line-height: 9px;
				}

				&.enable {
					.icon-cart-nci {
						color: @gray-base;
					}
				}
			}

			li:not(.has-children):not(.active):not(.mini-cart-item) {
				.cart-trigger,
				> a {
                    &:not(.button):not(.close) {
                        background-color: transparent;
                    }
				}
			}
		}

		.menu-trigger {
			margin-left: -30px;
			height: 69px;
			float: left;
			padding: 27px 30px;
			.hamburger-menu(19px, 3px, 4px, @gray-dark, @gray-dark);
		}

		.search-trigger {
			float: right;
			margin-top: 12px;
			padding: 8px 12px 22px 12px;
			height: 53px;
			transition: all 0.5s ease;

			&:focus,
			&:hover {
				color: @gray-darker;
			}

			i {
				color: @gray-dark;
				vertical-align: top;
				font-size: 24px;
				line-height: 22px;
			}

			&.active {
				background-color: @gray-darker;

				i {
					color: @brand-white;
				}
			}
		}

		.header-wrapper .site-search-wrapper {
			opacity: 0;
			position: absolute;
			display: block;
			left: 0;
			width: 100%;
			margin-top: 0;
			background-color: @gray-darker;
			padding: 16px 30px;
			transition: opacity 0.5s ease;
			z-index: 1000;
			margin-left: 0;

			&.active {
				top: 65px !important;
				opacity: 1;
			}
		}

	}

	@media screen and (max-width: @screen-sm) {
		.header-top {
			.site-logo {
				padding-right: 0;
			}
		}
	}
}