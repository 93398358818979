.reporting-component {
    margin-bottom: 30px;
}


.reporting-component.heading {
    padding-bottom: 0;
    padding-top: 10px;
}

.glossary-container {
    padding-top: 35px;

    a {
        font-size: 16px;
        text-decoration: none;
    }
}

.page-report-generate {
    .back-to-top-container {
        margin-top: 30px;
    }

    .option-checkbox {
        margin-bottom: 3px;
    }

    .all-checkbox {
        margin-bottom: 10px;
    }

    .checkbox-list {
        margin-top: 5px;
    }

    .generate-controls {
        margin-top: 20px;

        a {
            display: block;
            padding-top: 3px;
        }
    }

    select.error+label+div.ms-container,
    select.error+div.ms-container{
        background-color: red;
        .ms-list {
            border-color: @brand-red;
        }
    }

    .ms-select-container,
    .po-multiselect{
        position: relative;

        label.error {
            position: absolute;
            bottom: -20px;
        }
    }
}


.all-checkbox+label {
     .deselect-all-text {
        display: none;
     }
}


.all-checkbox:checked+label {
    .deselect-all-text {
        display: inline;
    }
    .select-all-text {
        display: none;
    }
}


#reportForm {
    padding-bottom: 20px;
}

#reportFrom[readonly],
#reportTo[readonly]{
    cursor: pointer;
    opacity: 1;
}

form[report-type="ADDRESS_BOOK"] {
    .address-book-component {
        display: block !important;
    }
}

form[report-type="ORDER_ACTIVITY"] {
    .order-activity-component {
        display: block !important;
    }
}


form[report-type="PRODUCT_ANALYSIS"] {
    .product-analysis-component {
        display: block !important;
    }
}


form[report-type="SYSTEM_ACTIVITY"] {
    .system-activity-component {
        display: block !important;
    }
}

form[report-type="METRIC_DASHBOARD"] {
    .metric-dashboard-component {
        display: block !important;
    }
}


form[report-type="INVOICE_LISTING"] {
    .invoice-listing-component {
        display: block !important;
    }
}


#report-view-modal {
    -webkit-overflow-scrolling: auto;

    .cancel-report-view {
        display: block;
        margin-top: 7px;
    }

    img {
        width: 100%;
    }
}

.page-report-dashboard, .page-invoices {
     .pagination-bar .custom-styled-select {
         width: 152px;
     }


     .dashboard-section table td {
         font-weight: @font-weight-semi-bold;

         span.date-holder,
         span.date-divider {
             display: inline;
         }

         span.date-divider {
             color: @gray-dark;
         }

         span.date-holder {
             font-weight: @font-weight-semi-bold;
         }

         .action-container {
             min-width: 165px;

             .action {
                 display: inline-block;
                 text-align: center;
                 padding: 0 3px;

                 a {
                     text-decoration: none;
                     font-weight: @font-weight-normal;
                 }

                 &:not(:first-child){
                     position: relative;

                     &:before {
                         content: "";
                         width: 1px;
                         height: 13px;
                         background-color: @brand-blue;
                         display: block;
                         top: 3px;
                         position: absolute;
                     }

                     &:before {
                         left: -2px;
                     }


                 }
             }
         }

     }
 }

.export-invoice-button {
    margin-right: 15px;
}

.po-multiselect {
    .js-search-po {
        border-bottom: 0;
    }

    .ms-selection {
        .ms-list {
            height: 239px;
        }
    }

}

.report-warning-msg__text{
    font-size: @font-size-base;
    color: @brand-error;
    font-weight: @font-weight-bold;
}

.page-invoice {
    .pagination-bar .custom-styled-select {
        width: 152px;
    }


    .dashboard-section table td {
        font-weight: @font-weight-semi-bold;

        span.date-holder,
        span.date-divider {
            display: inline;
        }

        span.date-divider {
            color: @gray-dark;
        }

        span.date-holder {
            font-weight: @font-weight-semi-bold;
        }

        .action-container {
            min-width: 125px;

            .action {
                display: inline-block;
                text-align: center;
                padding: 0 3px;

                a {
                    text-decoration: none;
                    font-weight: @font-weight-normal;
                }

                &:not(:first-child){
                    position: relative;

                    &:before {
                        content: "";
                        width: 1px;
                        height: 13px;
                        background-color: @brand-blue;
                        display: block;
                        top: 3px;
                        position: absolute;
                    }

                    &:before {
                        left: -2px;
                    }


                }
            }
        }

    }
}

.generate-button-mobile {
    display: none;
}

.invoice-amount-label {
    line-height: 40px;
    font-weight: @font-weight-normal;

    &.text-right {
        text-align: right;
    }
}

.invoice-top-space {
    margin-top: 20px;
}

.invoice-summary-desktop {
    table {
        margin-bottom: 0;

        .scu-cell {
            padding-left: 10px;
            text-align: center;
        }
    }
}

.invoice-table--align-top{
    td{
        vertical-align: middle;
    }
}

.invoice-cell--upper-case{
    text-transform: uppercase;
}

#invoice-email-popup .modal-content {
    max-width: 540px;

    .button-container {
        margin-top: 20px;
    }
}


@media (max-width: @screen-md) {
    .glossary-container {
        padding-top: 0;
    }

    .page-report-dashboard {
        tr.mobile-content {
            display: table-row;

            td {
                padding: 0;
            }

            td.table-holder {
                border: 1px solid @gray-light;
            }
        }
    }

    .page-invoices {
        tr.mobile-content {
           display: table-row;

           td {
               padding: 0;
           }

           td.table-holder {
               border: 1px solid @gray-light;
           }
        }
    }

    .generate-button-mobile {
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }
}


@media screen and (max-width: 640px) {
    .page-report-dashboard {
        .show-mobile {
            background-color: @brand-white;
            padding: 20px 20px 0;

            .custom-styled-select.with-custom-options {
                font-weight: @font-weight-bold;
            }
        }

        .gor-tabs {
            .tabs-content {
                padding: 20px;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .page-report-dashboard {
        .page-header {
            max-height: 150px;
        }
    }
}

.dashboard-section table.report-dashboard-mobile {
    width: 100%;
    margin-bottom: 0;

    td {
        width: 50%;
        border-width: 0;
        padding: 5px 10px;
        text-align: left;

        &:last-child {
            border-left: 1px solid @gray-light;
        }
    }
}