//
// Content Items
// --------------------------------------------------

//
// Define Thumbnails
// --------------------------------------------------
.thumbnail {
    display: block;
    padding: @thumbnail-padding;
    margin: @thumbnail-margin;
    background-color: @thumbnail-bg;
    border: @thumbnail-border;
    border-radius: @thumbnail-border-radius;

    .copy{
        padding: @thumbnail-copy-padding;
    }
}