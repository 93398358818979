@import (reference) "mixins";
@import (reference) "grid";


//
// Utility classes
// --------------------------------------------------

//Layout Helpers
.clearfix {
    *zoom: 1;

    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
        // Fixes Opera/contenteditable bug:
        // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
        line-height: 0;
    }

    &:after {
        clear: both;
    }
}

.center-block {
    .center-block();
}

.text-center {
    text-align: center;
}

.display-inline-block {
    display: inline-block;
}


.display-block {
    display: block !important;
}

.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

.reset-list{
    margin-bottom: 0;
    padding-left: 0;
    text-indent: 0;
    list-style: none;
}

.bold {
    font-weight: @font-weight-bold;
}

.semi-bold {
    font-weight: @font-weight-semi-bold;
}

.weight-normal {
    font-weight: @font-weight-normal;
}
//Content Helpers
.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.invisible {
    visibility: hidden;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.scroll-horizontal--desktop {
	@media (min-width: @screen-md) {
		overflow-x: scroll;
		padding-bottom: @indent-xs;
		margin-bottom: @indent-xs;
        width: 100%;

		&::-webkit-scrollbar {
			height: 7px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 5px;
			background-color: @gray;
		}
	}
}

.italic {
    font-style: italic;
}

.row-flex {
    display: flex;

    @media (max-width: @screen-md) {
        flex-wrap: wrap;
    }
}

.no-padding {
    padding: 0!important;
}

//Responsive Helpers
.visible-sm,
.visible-md,
.visible-lg,
.visible-600,
.visible-640 {
    display: none !important;
}

[v-cloak] {
    display: none;
}

.visible-sm {
    @media (max-width: @screen-sm) {
        display: block !important;
    }
}

.visible-md {
    @media (max-width: 980px) {
        display: block !important;
    }
}

.visible-lg {
    @media (max-width: @screen-lg) {
        display: block !important;
    }
}

.visible-640 {
    @media (max-width: 640px) {
        display: block !important;
    }
}
.visible-600 {
    @media (max-width: 600px) {
        display: block !important;
    }
}

.hidden-sm {
    @media (max-width: @screen-sm) {
        display: none !important;
    }
}

.hidden-md {
    @media (max-width: @screen-md) {
        display: none !important;
    }
}

.hidden-lg {
    @media (max-width: @screen-lg) {
        display: none !important;
    }
}

.hidden-640 {
    @media (max-width: 640px) {
        display: none !important;
    }
}

.hidden-600 {
    @media (max-width: 600px) {
        display: none !important;
    }
}
