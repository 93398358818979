.show-for-print {
    display: none !important;
}

@media print {

    body,html {
        margin-top:0%;
        display:block;
        height:100%;
    }

    * {
        background: transparent !important;
        color: black !important;     // Black prints faster: h5bp.com/s
        box-shadow: none !important;
        text-shadow: none !important;
    }

    .show-for-print {
        display: block !important;
    }

    .hide-for-print {
        display: none !important;
    }

    table.show-for-print {
        display: table !important;
    }

    thead.show-for-print {
        display: table-header-group !important;
    }

    tbody.show-for-print {
        display: table-row-group !important;
    }

    tr.show-for-print {
        display: table-row !important;
    }

    th.show-for-print,
    td.show-for-print {
        display: table-cell !important;
    }

    // Display the URL of a link after the text
    a,
    a:visited {
        text-decoration: underline;
    }

    // Prevent page breaks in the middle of a blockquote or preformatted text block
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    // h5bp.com/t
    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    // Avoid page breaks after a heading
    h2,
    h3 {
        page-break-after: avoid;
    }

    .print,
    .header-button,
    button,
    .button {
        display: none !important;
    }

    #header,
    #footer {
        display: none !important;
    }

    .back-to-top-container {
        display: none !important;
    }

    .page-header {
        height: auto;
        margin-bottom: 0;

        .greeting {
            position: static;
            transform: translateY(0);
        }

        h1 {
            padding-top: 10px;
            padding-bottom: 20px;
        }
    }

    .print-break-before {
        page-break-before: always;
        margin-top: 20px;
    }

    table {
        tr {
            th:first-child,
            td:first-child {
                border-left: 1px solid @gray-base !important;
            }

            th:last-child,
            td:last-child {
                border-right: 1px solid @gray-base !important;
            }

            th {
                border-bottom: 1px solid @gray-base !important;
            }
        }

        tfoot {
            tr {
                border-bottom: 1px solid @gray-base !important;
            }
        }

        &.order-info-table {
            th:first-child,
            td:first-child {
                border-left: 0 !important;
            }

            th:last-child,
            td:last-child {
                border-right: 0 !important;
            }
        }
    }

    .print-no-border {
        table {
            tr {
                th:first-child,
                td:first-child {
                    border-left: 0px !important;
                }

                th:last-child,
                td:last-child {
                    border-right: 0px !important;
                }
            }
        }
    }

    .section-header {
        border: 1px solid @gray-base;
        border-bottom: 0;

        &:before {
            content: none;
        }
    }

    .account-section {
        padding: 0 0 16px !important;
        margin-top: 20px;

        .headline {
            border-color: @gray-base !important;
            padding-bottom: 10px;
        }

        table {
            th:first-child,
            td:first-child {
                border-left: 0 !important;
            }

            th:last-child,
            td:last-child {
                border-right: 0 !important;
            }
        }
    }

    .print-height-auto {
        height: auto !important;
    }

    .print-branding {
        padding-bottom: 10px;
        margin-bottom: 10px;

        .brand-container {
            border-bottom: 1px solid @gray-base;
            padding-bottom: 10px;
        }

        .site-logo{
            float: left;

            img {
                width: 150px;
            }
        }

        .header-account-info {
            float: right;

            p {
                margin: 0;
                text-align: right;
                font-weight: @font-weight-semi-bold;
            }
        }
    }

    .print-col-6 {
        width: 50%;
    }

    .product-table td.product-image {
        padding-left: 10px;
    }

    .markupPrintTarget.hide-total {
        .print-total {
            display: none !important;
        }
    }

    .markupPrintTarget.hide-brand-price {
        .print-brand-price {
            display: none !important;
        }
    }

    .markupPrintTarget.hide-markup {
        .print-markup {
            display: none !important;
        }
    }

    .markup-pricing-summary.hide-markup.hide-brand-price.hide-total {
        display: none !important;
    }

    .markup-product-details.cart-details table tfoot tr.table-tr-bottom td div {
        line-height: 40px;
    }

    .markup-product-details.cart-details table tfoot tr.table-tr-bottom:first-child td {
        padding-top: 0;
    }

    .markup-product-details.cart-details table tfoot tr.table-tr-bottom:last-child td {
        padding-bottom: 0;
    }

    .markupPrintTarget:not(.hide-total):not(.hide-markup):not(.hide-brand-price):not(.markup-pricing-summary) {
        thead th {
            padding: 10px 5px;
        }

        tbody td {
            padding: 20px 5px;
        }

    }

    .markupPrintTarget:not(.markup-pricing-summary){
        tfoot tr td {
            white-space: nowrap;
        }
    }

    .markup-pricing-summary table td:not(:first-child):not(:last-child) {
        padding-left: 10px;
    }

    .markup-product-details.cart-details table tfoot tr.table-tr-bottom:nth-child(3) td {
        padding-bottom: 0;
    }


    //Invoice Detail Print Styles
    .invoice-detail-wrapper {

        -webkit-print-color-adjust: exact !important; // preserve background colors when printing

        .account-section .headline {
            border-bottom: 2px solid @brand-red !important;
        }

        .accountOrderDetailOrderTotals .print-col-6 {
            width: 100% !important;
        }

        .freight-info {
            border-bottom: 1px solid lightgrey;
        }

        .invoice-collections-notice {
            text-transform: none !important;
        }

        .invoice-header {
            font-size: @font-size-h2 !important;
        }

        .breadcrumbs-section, .invoice-print, .invoice-options-wrapper, .invoice-summary-mobile, .page-header {
            display: none !important;
        }

        .order-totals {
            border: 0 !important;

            td {
                font-weight: @font-weight-bold !important;
            }

            .freight-items {
                font-weight: @font-weight-normal !important;
            }
        }

        .page-header {
            display: none !important;
        }

        .section-header {
            background-color: @gray-dark !important;
            color: @brand-white !important;
        }

        .section-header::before {
            display: block !important;
            background-color: @brand-red !important;
            content: "" !important;
            height: 50px !important;
            left: 0 !important;
            position: absolute !important;
            top: 0 !important;
            width: 10px !important;
        }

        .table th {
            background-color: @gray-base !important;
            max-width: 40px;
            font-size: 9px;

            :first-child {
                padding: 10px 12px 8px 20px !important;
            }
        }
    }

    // Copy Quote case:
    .copy-quote-popup {
        .modal-content {
            width: 100% !important;
        }

        .section-header {
            border: @modal-item-border !important;
        }

        .copy-quote-popup__error-border {
            border: none !important;
        }

        .copy-quote-popup__error-list {
            &:not(:first-of-type) {
                margin-top: 15px !important;
            }
        }

        .copy-quote-popup__list-wrapper {
            margin-bottom: 20px !important;
        }

        .copy-quote-popup__item-error {
			display: block !important;
            padding-top: 0 !important;
        }
    }
    // Print Quote Modal
    #printSection, #printSection * {
        visibility:visible;
    }
    #printSection {
        position:absolute;
        left:0;
        top:0;
        width: 100%;
    }
   #assemblies-modal-table {
       width: 100%;
       tr {
           border-bottom: 1px solid @brand-black;
       }
   }
}