.checkout-steps {
	overflow-y: hidden;
	overflow-x: visible;
	margin-bottom: 30px;

	.icon-check-circle {
		display: none;
	}

	a {
		float: left;
		padding: 0;
		position: relative;
		text-decoration: none;
		width: 25%;
		z-index: 0;

		.icon-exclamation-circle {
			display: none;
		}

		&:nth-child(1) {
			padding-left: 0;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 20px 0 20px 10px;
				border-color: transparent transparent transparent @gray-lighter;
			}

			div {
				&:before {
					left: 0;
				}
				&:after {
					display: none;
				}
			}
		}

		&:last-child {
			padding-right: 13px;

			&:after {
				right: 0;
				top: -6px;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				z-index:  1;
			}
			&:after {
				border-color: rgba(194, 225, 245, 0);
				border-left-color: @gray-light;
				border-width: 26px 0 26px 13px;
				z-index: 2;
			}

			&.done {
				&:after {
					border-left-color: @brand-light-blue;
				}
			}
		}

		div {
			background-color: @gray-light;
			color: @gray-dark;
			font-weight: @font-weight-bold;
			padding: 10px 0;
			text-align: center;
			text-decoration: none;
			width: 100%;

			&:before,
			&:after {
				left: 0;
				top: -6px;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				z-index:  1;
			}
			&:after {
				border-color: rgba(194, 225, 245, 0);
				border-left-color: @gray-light;
				border-width: 26px 0 26px 13px;
				z-index: 2;
			}
			&:before {
				left: 3px;
				border-color: rgba(194, 225, 245, 0);
				border-left-color: @gray-lighter;
				border-width: 26px 0 26px 13px;
			}
		}

		&.active {
			+ .step-head {
				div {
					&:after {
						border-left-color: @brand-blue;
					}
				}
			}

			div {
				background-color: @brand-blue;
				color: @brand-white;
			}
		}

		&.error {
			+ .step-head {
				div {
					&:after {
						border-left-color: @brand-red;
					}
				}
			}

			div {
				background-color: @brand-red;
				color: @brand-white;
			}

			.icon-exclamation-circle {
				display: inline-block;
				font-size: 16px;
				line-height: 20px;
				margin-left: 6px;
				vertical-align: top;
			}
		}

		&:last-child.active {
			&:after {
				border-left-color: @brand-blue;
			}
		}

		&.done {
			div {
				color: @brand-white;
				background-color: @brand-light-blue;

				&:after {
					border-left-color: @brand-light-blue;
				}
			}

			+  .step-head {
				background-color: @brand-light-blue;

				&:last-child {
					background-color: transparent;
				}

				div {
					&:after {
						border-left-color: @brand-light-blue;
					}
				}
			}
		}
	}

}

.term-teaser {
	max-height: 476px;
	overflow: hidden;
}


#generalInformationForm {
	.col-left h2 {
		margin-bottom: 13px;
	}
}

.page-multiStepCheckoutSummaryPage {
	.col-left,
	.col-right {
		width: 50%;
	}
	footer {
		margin-top: 70px;
	}

	.cart-details {
		padding: 0;
	}

	.table {
		margin-bottom: 20px;
	}
}

.step-body {
	.total-wrapper {
		background-color: @brand-white;
		padding: 20px;
		margin-bottom: 30px;

		h2 {
			margin: 0 0 5px 0;
		}

		.subtotals {
			display: table;

			> div {
				display: table-row;

				> span {
					display: table-cell;

					&:nth-child(1) {
						padding-right: 10px;
					}

					&:nth-child(2) {
						text-align: right;
						font-weight: @font-weight-bold;
					}
				}
			}
		}
	}
}

#i18nAddressForm {
	.jobsiteContact,
	.phoneNumber,
	.shipToEmail,
	.salesOrderContact,
	.salesOrderPhone,
	.salesOrderEmail {
		opacity: 0;
		height: 0;
		width: 0;
		margin: 0;
		overflow: hidden;
	}
}

.page-multiStepCheckoutSummaryPage {
	.back-to-top {
		margin-top: 30px;
	}
}

.checkout-shipping {
	.back-to-top {
		display: none;
	}

	.content-wrapper {
		max-width: none !important;
	}

	#nciAddressForm {
		button[type="submit"] {
			display: none;

			+ a {
				display: none;
			}
		}

	}

	.js-shipping-method {
		label {
			white-space: nowrap;
		}
	}

	.checkout-warning {
		margin-bottom: 10px;
	}

	.checkout-warning__img--small {
		width: 20px;
		height: auto;
	}
}

.checkout-upload-subtext {
    font-weight: @font-weight-bold;
}

.checkout-shipping,
.step-body-form  {
	background-color: @brand-white;
	margin-bottom: 30px;
	padding: 0 20px 40px;

	h2 {
		margin-bottom: 8px;

		span {
			font-size: 20px;
			font-weight: @font-weight-normal;
		}
	}

	em {
		display: block;
		margin-bottom: 16px;
	}

	.col-right {
		em {
			margin-bottom: 9px;
		}
	}

	.col-left {
		padding-right: percentage(55/980);
	}

	.col-right {
		float: left;
		max-width: 447px;
		padding: 0 0 0 percentage(50/980);
		position: relative;

		&:after {
			background-color: @gray-lighter;
			content: "";
			height: calc(~"100% - 147px");
			position: absolute;
			left: 0;
			top: 64px;
			width: 1px;
		}
	}

	.reset-list li {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.custom-checkbox label {
		font-weight: @font-weight-bold;
	}
}

.step-body-form  .content-wrapper,
.checkout-shipping .content-wrapper {
	width: 100%;
	max-width: 607px;
}

.step-body-form  .content-wrapper {
	.js-form-type-container {
		display: none;
		// Align left with radio label
		margin-left: 18px;
	}
}

#popup_address_validation_addressbook,
#popup_duplicated_delivery_addresses_form,
#popup_address_validation {
	.modal-content {
		max-width: 500px;
	}

	.modal-wrapper {
		padding: 30px;
	}

	.buttons {
		margin-top: 16px;
	}
}
.duplicated-address {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	.duplicated-address__option {
		word-wrap: break-word;
		margin-bottom: 10px;
	}
}

#PurchaseOrderNumber {
	max-width: 250px;
}

.payment-notice {
	line-height: 1.3;
	max-width: 370px;
}

.payment-form {
	display: none;

	.content-wrapper {
		max-width: 375px;

		.reset-list > li {
			margin-bottom: 0;
		}
	}
	&.account li.po-number {
		display: none;
	}
}

.amount {
	.amount-notice {
		font-size: 11px;
		line-height: 14px;
	}

	input {
		max-width: 144px;
	}
}

.cvv-container li {
	float: left;
}

.selected-card-container {
	font-size: 30px;
}

.cvv {
	width: 75px;
	margin-right: 20px;
	position: relative;
	white-space: nowrap;

	label.error {
		position: absolute;
		bottom: 0;
		left: 0;
	}

	input.error {
		margin-bottom: 19px;
	}

	+ li {
		clear: right;
		height: 40px;
		overflow: hidden;
		width: auto;

		i {
			display: inline-block;
			line-height: 40px;
			font-size: 50px;
			margin-top: -5px;

			&:last-child {
				margin-left: 5px;
			}
		}
	}
}


.step-body-form .credit-card {
	margin-top: 10px;
	position: relative;

	li {
		float: left;
		margin-bottom: 0;
		margin-right: 10px;

		&:last-child {
			margin-right: 0;
		}

		input {
			display: block;
			width: 0;
			height: 0;
			opacity: 0;

			&:checked + label + label,
			&:checked + label {
				color: @brand-black;
			}

			&.error + label + label{
				margin-bottom: 19px;
			}
		}

		label:not(.error) {
			display: block;
			color: @gray;
		}

		label.error {
			position: absolute;
			left: 0;
			bottom: 0;
		}

		i {
			display: block;
			font-size: 30px;
			line-height: 1;
		}
	}

	input.error + label{
		margin-bottom: 30px;
	}

	.error {
		position: absolute;
		top: 33px;
		left:0;
	}
}

.checkout-success__body__headline {
	margin-top: 45px;
	font-size: 36px;
	color: @brand-black;
	line-height: 1;
	text-align: center;
}

.page-orderConfirmationPage {
	#content {
		margin: 0 auto;
		width: 100%;
		background: #ffffff url("../images/b2b2c-ConfirmationBanner.png") bottom center no-repeat;
		background-size: 100% auto;
		text-align: center;
		min-height: auto;

		> .container-fluid {
			padding-bottom: percentage(470/1040);
		}
	}

	.button {
		display: inline-block;
	}
}

.continue__shopping {
	text-align: center;
}

h2 {
	vertical-align: top;
	.custom-styled-select {
		max-width: 320px;
		margin-left: 14px;
	}
}

.js-change-address,
.js-address-book,
.js-pickup-address,
.pickup-container {
	display: none;
}

.pickup-container {
	.custom-styled-select.with-custom-options ul.custom-options li {
		white-space: normal;
		line-height: 1.5;
	}
}


.js-change-address {
	font-size: @font-size-base;
	vertical-align: middle;
	margin-left: 10px;
}
.billing-info,
.js-pickup-address,
.js-address-book {
	padding-top: 5px;

	table td {
		vertical-align: top;
		padding-right: 10px;

		&:last-child {
			font-weight: @font-weight-bold;
		}
	}
}

.js-shipping-method,
 .js-delivery-address {
	 opacity: 0;
	 height: 478px;
	 overflow: hidden;

	 .loaded & {
		 opacity: 1;
		 height: auto;
		 overflow: visible;
	 }

	 .address-book-select-wrapper {
		 width: 50%;
	 }
 }

.review-section {
	background-color: @brand-white;
	padding: 0 20px 30px;
	margin-bottom: 20px;

	h2 {
		font-size: @font-size-h3;
	}

	p {
		font-size: @font-size-small;
		margin-bottom: 22px;

		&:last-child {
			margin-bottom: 18.5px;
		}
	}

	.section-subtitle {
		display: block;
		font-size: @font-size-h5;
		font-weight: @font-weight-bold;
		margin-bottom: 1px;
	}

	.col-right {
		padding-left: percentage(30/940);
		border-left: 1px solid @gray-light;

		> div {
			margin-bottom: 14px;

			&:last-child {
				margin-bottom: 7px;
			}
		}
	}

	table {

		td {
			vertical-align: top;
			padding: 0;
		}

		tr:last-child td {
			padding-top: 1px;
		}

		td:last-child:not(.file) {
			font-weight: @font-weight-bold;
			padding-left: 17px;
		}
	}

	.file > i {
		font-size: @font-size-small;
		margin-left: 0;
		margin-right: 3px;
	}

	.terms {
		font-size: @font-size-h5;
		text-decoration: none;
	}

	i {
		display: inline-block;
		font-size: 16px;
		font-weight: @font-weight-normal;
		margin-left: 6px;
		line-height: 1;
		vertical-align: middle;
	}

	.totals-table {
		margin-bottom: 0;
		border: none;

		td {
			border-bottom: none;
			font-size: @font-size-base;
			font-weight: @font-weight-normal;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				font-weight: @font-weight-normal;
			}
		}

		.cart-total {
			border: none;
			border-top: 1px solid @gray-light;
			padding: 10px 0 0 0;
		}
	}

	a.edit {
		float: right;
		font-size: @font-size-base;
		color: @brand-black;
		text-decoration: none;
		margin-top: 2px;
	}
}

.review-section{
	&.terms {
		margin-bottom: 30px;
		.order-button {
			margin-top: 0;
		}
	}
}

.place-order-form {
	.order-button{
		margin-top: 0;
	}
}

.terms label {
	font-weight: @font-weight-normal;
}

.back-top,
.order-button {
	text-align: right;
	margin-top: -10px;
}

.back-top {
	margin-top: 40px;

	> div {
		display: inline-block;
	}
}

.term-condition-link {
	clear: both;
	display: inline-block;
	margin-bottom: 20px;
	margin-top: 20px;
}

.js-delivery-jobsite .file-upload {
	max-width: 380px;

	input {
		min-height: 40px;
	}
}

.download-form {
	margin-top: 20px;

	a {
		display: block;
		position: relative;
		padding-left: 20px;

		i {
			bottom: 0;
			left: 0;
			position: absolute;
		}
	}
}

.address-book-autocomplete {
	margin-top: 20px;
	position: relative;
	.address-book-autocomplete__clear {
		position: absolute;
		right: 10px;
		top: 54%;
		cursor: pointer;
	}
	.address-book-autocomplete__field {
		padding-right: 18px;
		&::-ms-clear {
			height: 0;
			width: 0;
		}
	}
	.address-book-autocomplete__suggestions {
		.ui-autocomplete {
			z-index: 10;
		}
		.ui-menu-item {
			// overriding default item styling since anchors tags are not being used for the element content
			padding: 10px 5px !important;
		}
		.ui-state-focus {
			color: #678695;
			background-color: #ececec;
			font-weight: 700;
		}
	}
}

// B2B2C Checkout updates

.b2c-checkout {

	.checkout-steps {

		a {
			width: 50%;
		}
	}

	.address-book-select-wrapper {
		width: 100%;
	}

	.review-section {
		margin-top: 0;
	}
}

@media screen and (max-width: @screen-md) {

	.js-delivery-address {
		.address-book-select-wrapper {
			width: 100%;
		}
	}
}

@media screen and (max-width: 650px) {
	.checkout-steps {
		margin: 0 -30px 27px;
		height: 40px;
		overflow: hidden;

		a:nth-child(1) {
			&:before,
			.title:before {
				display: none;
			}

			.title {
				padding-left: 0;
			}
		}

		a:last-child {
			padding-right: 0;

			&:after {
				display: none;
			}
		}

		.title {
			padding-left: 13px;
			span {
				display: none;
			}
		}

		.icon-check-circle {
			font-size: 16px;
			line-height: 20px;
			margin-left: 6px;
			vertical-align: top;
		}
	}
}

@media screen and (max-width: 640px) {
	.product-table tfoot .subtotal-wrapper {
		table {
			tbody tr td:last-child {
				padding-top: 0;
			}
		}
	}
}

@media screen and (max-width: 340px) {
	.checkout-steps {
		margin: 0 -15px 27px;
	}
}

@media screen and (max-width: 840px) {
	.review-section {
		.col-left,
		.col-right {
			width: 100%;
			max-width: none;
			padding: 0;
			border-left: 0;

			&:after {
				display: none;
			}

			border-left: 0;
			border-right: 0;
		}


		.col-left {
			padding-bottom: 30px;
			border-bottom: 0;
		}

		.col-right {
			margin-top: @indent-lg;
		}
	}
}

@media screen and (max-width: @screen-sm) {
	.step-body-form {
		.col-left,
		.col-right {
			width: 100%;
			max-width: none;
			padding: 0;
			border-left: 0;

			&:after {
				display: none;
			}

			border-left: 0;
			border-right: 0;
		}


		.col-left {
			padding-bottom: 30px;
			border-bottom: 0;
		}

		.col-right {
			border-top: 2px solid @gray-lighter;
			margin-top: 0;
			h2 {
				margin-top: 30px;
			}
		}
	}

	.js-change-address {
		width: 100%;
		margin-left: 0;
	}

	.page-orderConfirmationPage {
		.button {
			display: inline-block;
		}
	}
}

@media screen and (max-width: 340px) {
	.checkout-steps {
		margin: 0 -15px 27px;
	}

	.order-info {
		.col-right {
			padding-left: 0;
			border-left: none;

			> div {
				margin-bottom: 19px;
				padding-top: 19px;
				border-top: 1px solid @gray-light;
			}
		}

		.value-order {
			padding-bottom: 19px;
		}
	}

	.checkout-button-container {
		.button,
		button {
			width: 100%;
			margin-bottom: 10px;
		}
	}
}

.checkout-global-messages {
    margin-top: -30px;
    margin-bottom: 30px;
}
@media screen and (min-width: @screen-md) {
	.address-book-autocomplete {
		width: 80%;
	}
}
