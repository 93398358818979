@cart-header-height: 50px;

.cart-empty {
	&:extend(.h4);
	margin-top: 20px;
}

.cart-details {
	padding: 0 @indent-xl 40px;

	h1 {
		position: relative;
		height: @cart-header-height;
		padding: 13px 0 13px 15px;
		color: @brand-white;
		display: inline-block;
		margin: 0;

		span {
			float: right;
			font-size: @font-size-base;
			margin: 5px percentage(28/920) 0 0;

			&.inner-text {
				display: block;
				width: 98%;
			}
		}
	}

	.buttons-set-cart {
		text-align: right;
		padding-top: 20px;
	}

	.table-tr-bottom {
		td {
			height: 50px;
			vertical-align: middle;
			padding-top: 0;
			padding-bottom: 0;
			border: 0;
			font-size: @font-size-base;
			font-weight: @font-weight-normal;
		}

		.item-total,
		.subtotal-wrapper {
			float: right;
			text-align: right;
			width: 68%;
			padding-bottom: 10px;
			padding-top: 10px;

			table {
				float: right;

				td {
					padding-top: 0;
					padding-right: 10px;
					height: auto;
					text-align: right;
				}
			}
		}

		.item-total {
			float: left;
			text-align: left;
			width: 25%;
		}
	}

	tr.edit {
		position: relative;
		text-align: left;

		.product-info {
			margin-bottom: 20px;
			padding-left: 10px;
			width: percentage(903/@wrapper-width);
		}

		.col-md-6 {
			position: static;
		}

		.row {
			max-width: 727px;

			.bundling {
				margin-top: 20px;
			}

			.custom-styled-select {
				margin-top: 0;
			}
		}

		.js-delete-row,
		.add-length {
			display: none;
		}

		.product-image {
			width: percentage(57/960);

			a {
				width: 100%;
			}
		}

		.page-productDetails {
			padding-left: 10px;
			margin-left: percentage(77/@wrapper-width);
		}

		.custom-styled-select {
			font-weight: @font-weight-normal;
		}
	}
	.cart-table--sorting {
		.gor-accordion {
			margin-bottom: 0;

			&:not(:last-child) {
				.table.shopping-cart-items {
					tfoot {
						display: none;
					}
				}
			}
		}

		.editor-accordion .accordion-item,
		.accordion-content-inner {
			padding: 0;
		}

		.editor-accordion .accordion-item {
			margin-top: @indent-xl;
		}

		.table.shopping-cart-items {
			margin-bottom: 0;

			li {
				margin-bottom: 0;
			}

			.product-option {
				margin-bottom: @indent-lg;
			}

			.product-options-box {
				.info-wrapper {
					margin: 0 0 @indent-lg;
				}
			}
		}
	}
}

.cart-header {
	background: @gray-darker;
	margin-top: @indent-md;
	border-left: 10px solid @brand-red;

	select.custom-styled-select {
		width: 104px;
		border-width: 0 0 0 1px;
		background: @gray-darker;
		color: @brand-white;
		border-color: @gray-dark;
		font-size: @font-size-base;
		padding: 14px 0 16px @indent-xs;
		font-weight: @font-weight-bold;
	}

	.custom-styled-select {
		float: right;
		width: auto;
		height: @cart-header-height;

		.outer {
			background-color: transparent;
			border-color: @gray-dark;
			border-width: 0 0 0 1px;
			color: @brand-white;
			font-weight: @font-weight-bold;
			font-size: @font-size-base;
			margin: 0;
			text-transform: capitalize;
			padding: 0 44px 0 @indent-md;
			text-align: right;
			height: 100%;
			line-height: @cart-header-height;

			&:hover {
				cursor: pointer;
			}

			&:after {
				padding: 0;
				right: 20px;
				top: 17px;
				font-size: 10px;
				color: @brand-white;
			}

			&.open {
				padding: 5px 43px 15px 10px;
				border-width: 1px 1px 0 1px;

				&:before {
					content: '';
					width: calc(~"100% - 2px");
					height: 1px;
					bottom: -1px;
					background: @gray-darker;
					position: absolute;
					left: 1px;
					z-index: 100;
				}
			}
		}

		&.with-custom-options ul.custom-options {
			padding: 11px 0 7px;
			min-width: 163px;
			left: auto;
			right: 0;
			border: 1px solid @gray-dark;
			background: @gray-darker;
			transition: none;

			&.open {
				top: 50px;
			}

			li {
				&:first-child {
					display: none;
				}

				padding: 9px 20px;
				background: @gray-darker;
				color: @brand-white;
				font-weight: @font-weight-normal;
				font-size: @font-size-base;
				text-transform: none;
				line-height: 1;
			}
		}
	}
}

.custom-styled-select.without-dropdown {
	.outer {
		cursor: default;
		margin-bottom: 0;

		&:after {
			display: none;
		}
	}
}

.cart-subtotal {
	float: right;
	font-size: @font-size-base;
	color: @brand-white;
	line-height: @cart-header-height;
	padding-right: @indent-lg;
}

.cart-table {
    .product-table {
        tr {
            th.header{
                &:nth-child(2) {
                    width: 7%;
                }
                &:nth-child(3) {
                    width: 13%;
                }
                &:nth-child(4) {
                    width: 14%;
                }
                &:nth-child(5) {
                    width: 15.5%;
                }
                &:nth-child(6) {
                    width: 10%;
                }
            }

        }
    }
}

.cart-options__item {
	display: flex;
	align-items: baseline;
	cursor: pointer;
	color: @text-color;
	font-size: @font-size-base;
	padding: 2px 15px;

	&:hover {
		background-color: @gray-lighter;
	}

	.icon {
		color: @brand-blue;
	}
}

.cart-options__item-text {
	display: inline-block;
	padding: 0 0 0 @indent-xs;
	line-height: 1.2;
}

.cart-options__item-link {
	.icon-remove {
		&:before {
			border: 1px solid @brand-blue;
			padding: 0 1px;
			font-size: 11px;
		}
	}
}
.assemblies-modal-trigger {
	text-decoration: underline;
	cursor: pointer;
}
.assemblies-modal .modal-content {
	width: 769px;
}
.assemblies-modal .section-inner {
	background-color: rgba(103, 134, 149, 0.97);
	color: @brand-white;
	padding: 35px 40px;
}
.assemblies-modal .assemblies-modal__heading {
	margin-top: 0;
	margin-bottom: 15px;
	font-weight: @font-weight-semi-bold;
}
.assemblies-modal .assemblies-modal__summary {
	max-width: 460px;
}
.assemblies-modal .assembly-table {
	margin-top: 25px;
	width: 100%;
}
.assemblies-modal .assembly-table th,
.assemblies-modal .assembly-table td {
	padding-bottom: 18px;
}
.assemblies-modal .assembly-table .assembly-table__heading {
	text-align: left;
}
.assemblies-modal .assembly-table .assembly-table__heading--centered {
	text-align: center;
}
.assemblies-modal .assembly-table tbody tr:first-of-type {
	border-top: 2px solid @brand-white;
	& td {
		padding-top: 18px;
	}
}
.assemblies-modal .assembly-table tbody tr:last-of-type {
	border-bottom: 2px solid @brand-white;
}
.assemblies-modal .assemblies-modal__close {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 24px;
	color: @brand-offwhite;
}
.assemblies-modal__footer {
	padding-top: 18px;
}
.assemblies-modal__print {
	text-decoration: underline;
	cursor: pointer;
	font-weight: @font-weight-semi-bold;
}
.consolidated-lengths {
	display: inline-block;
	float: left;
	margin: @indent-xs 0 15px;
	padding: 8px 0 @indent-xs;
	border-top: 1px solid @gray-base;
	border-bottom: 1px solid @gray-base;
}

.consolidate-lengths__qty {
	display: inline-block;
	width: 60px;
	font-weight: @font-weight-normal;
}

.cart-select {
	background-color: @brand-white;
	margin-top: @indent-sm;
}

.cart-select-msg {
	&:extend(.container-fluid all);

	position: relative;
	padding: 16px 20px 14px;
	background-color: @brand-note;
}

.cart-select-msg__icon {
	position: absolute;
	top: 14px; left: 20px;
	margin: 0 15px 0 0;
    width: 30px;
    height: 30px;
    background: @brand-white;
	border-radius: 50%;

    &:before {
        transform: translate(-50%,0);
        position: absolute;
        top: 3px;
        left: 50%;
		font-size: @font-size-large;
		color: @brand-note;
	}
}

.cart-select-msg__text {
	display: block;
	padding-left: 45px;
	font-size: 16px;
	line-height: 28px;
	color: @brand-white;
}

.cart-select-header {
	display: flex;
	align-items: center;
	padding: @indent-sm @indent-md;
	background-color: @brand-green;
}

.cart-select-header--red {
	background-color: @brand-red;
}

.cart-select-header__text {
	color: @brand-white;
	font-family: @font-family-sans-serif;
	font-size: @font-size-large;
	font-weight: @font-weight-semi-bold;
	display: inline-block;
	margin-right: @indent-lg;
}

.cart-select-header__submit,
.cart-select-header__cancel,
.cart-select-list__button {
	padding: 0;
	font-weight: @font-weight-bold;
	font-family: @font-family-sans-serif;
	font-size: @font-size-base;
	background-color: transparent;
	border: none;
}

.cart-select-header__submit {
	color: @btn-default-bg;
	background-color: @btn-default-color;
	margin-left: auto;
	padding: @indent-sm 25px;
}

.cart-select-header__submit:disabled,
.cart-select-header__submit[disabled] {
	opacity: .5;
}

.cart-select-header__submit--red {
	color: @link-remove-color;
}

.cart-select-header__cancel {
	color: @btn-default-color;
	text-decoration: underline;
	cursor: pointer;
	margin-left: @indent-sm;
}

.cart-select-list {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: @indent-md;

	.custom-checkbox {
		margin: 0;
	}
}

.cart-select-list-wrapper {
	border: @table-cell-border;
}

.cart-select-list__item {
	margin: 0;
	flex: 1;
}

.cart-select-list__item-wrapper {
	display: block;

	& + & {
		margin-top: 10px;
	}
}
.cart-select-list__item-checkbox {
	display: inline-block;
	vertical-align: top;
}

.cart-select-list__button {
	text-decoration: underline;
	color: @brand-blue;
}

.cart-select-list__label {
	font-family: @font-family-base;
	font-size: @font-size-base;
	color: @text-color;
	font-weight: @font-weight-normal;
}

.mass-edit-page {
	select + .custom-options {
		display: flex;
		flex-wrap: wrap;
	}
}

.mass-edit-invalid-link {
	display: block;
	text-align: left;
	clear: both;
}

.mae-error-item-row {
	td {
		padding: 16px 0 0 !important; //override .table td:first-child
		border-bottom: 0;
	}
}
/*
* Mini Cart
*/
.mini-cart {
	.mini-products-list {

		li.mini-cart-item{
			&.no-padding {
				padding: 0;
			}
		}

		.table {
			margin-bottom: 0;
			th {
				&:first-child {
					width: 70%;
					padding-left: 10px;
				}
			}
			td {
				span:not(.empty) {
					display: inline;
				}
				.label {
					font-weight:@font-weight-normal;
				}
				.item-qty {
					span {
						font-weight:@font-weight-normal;
					}
				}

				.assembly-table {
					td {
						padding: 0;
						font-size: @font-size-h7;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 980px) {
	.mini-cart .mini-products-list li {
		display: block;
	}

	.cart-select-list__item-checkbox {
		margin-bottom: 10px;
	}
}

@media screen and (max-width: @screen-md) {
	.assemblies-modal .modal-content {
		width: 90%;
	}
	.assemblies-modal .section-inner {
		padding: 35px 20px;
	}
	.cart-details {
		tr.edit {
			div.product-image {
				width: 57px;
				margin-bottom: 20px;
			}
			.product-info {
				text-align: left;
				width: calc(~"100% - 57px");
			}
			.page-productDetails {
				text-align: left;
				padding-left: 0;
				margin-left: 0;

				.lineNote-wrapper,
				.bundling {
					margin-top: 22px;
				}
			}
		}

		.subtotal-wrapper {
			padding-right: @indent-sm;
		}
	}

	.cart-table {
        .product-table {
            tr {
                th.header{
                    &:nth-child(6) {
                        width: 15%;
                        text-align: center;
                        padding-right: percentage(20/@wrapper-width);
                    }
                }

            }
        }
    }

	.cart-select-header {
		flex-wrap: wrap;
	}

	.cart-select-header__text {
		padding-bottom: 10px;
	}

	.cart-select-header__buttons {
		width: 100%;
		text-align: center;
	}

	.cart-select-header__submit {
		margin-left: 0;
	}

	.cart-select-list__item {
		padding-bottom: @indent-sm;
	}

	.mass-edit-invalid-link {
		margin-bottom: @indent-md;
	}
}


@media screen and (max-width: 640px) {
	.cart-details {

		h1 {
			span {
				text-align: right;
			}
		}
	}

	.cart-details {
		tr.edit {
			div.product-image {
				width: 57px;
				margin-bottom: 20px;
			}

			.product-info {
				width: percentage(237/315);

			}
		}
	}

	.cart-select-list {
		display: block;
	}

	.cart-select-list__item {
		width: 100%;
		padding-bottom: 10px;
	}

	.cart-select-list__item-checkbox {
		margin: 20px 0 0 10px;
	}

	.buttons-set-cart {
		text-align: center;
		.button {
			margin: 0;

			&:first-child {
				float: left;
			}

			&:nth-child(2) {
				float: right;
			}
		}
	}
	.shopping-cart-items {

		.custom-error {
			margin-bottom: @indent-sm;
		}
	}
/*
* Mini Cart
*/
	.mini-cart {
		.mini-products-list {
			.table {
				td {

					&.reset-padding {
						padding: percentage(20/313);
					}
				}
			}
		}
	}
}

// Assembly cart table
.table {

	&.product-table {

		.assembly-table-cell {
			padding: 0 @indent-sm 0 0;
			border: none;
			font-size: @font-size-base;
			font-weight: @font-weight-normal;
		}

		.assembly-table-cell--border-bottom {
			border-bottom: @table-head-border;
			font-weight: @font-weight-semi-bold;
		}

		.assembly-table-cell--centered {
			text-align: center;

		}
	}
}


@media screen and (max-width: 380px) {
	.cart-details {
		h1 {
			> span {
				display: none;
			}
		}

		.subtotal-wrapper {
			padding-right: 0;
		}
	}
}

@media screen and (max-width: 340px) {
	.table-tr-bottom {
		td:first-child {
			display: none;
		}
	}
	.product-table td:last-child {
		padding-right: 5px;
		padding-left: 5px;
		text-align: center;
	}

	.cart-details {
		padding: 40px 15px;
	}

	.product-table {
		a:not(.link) {
			display: block;
			margin-bottom: 5px;
		}
		.product-info p span.separator {
			display: none;
		}
	}

	.cart-details {
		tr.edit {
			div.product-image {
				padding-left: percentage(20/313);
			}

			.page-productDetails {
				text-align: left;
				margin-right: percentage(20/313);
				margin-left: percentage(20/313);
			}
		}
	}
}

#cartMessages {
    margin-top: -30px;
}

.page-cartPage {
	.confirm-modal {
		.modal-wrapper {
			padding: 0 @indent-xl @indent-xl;
		}
	}
}

.custom-error--orange {
	background: @brand-orange;
}

.custom-error__text--cart {
	line-height: 12px;
	padding: 3px 0;
}

.custom-error__icon--cart {
	background: @brand-orange;
	color: @brand-white;
}

.custom-error--cart {
	max-width: 282px;
}