// UI Kit Components
@import "../../common/less/uikit";

// Hybris Addon Styles
@import "../../common/less/common";


@import "components/variables";
@import "components/theme";

.slides span a {
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    color: #333 !important;
}