.page-productDetails {

	footer {
		margin-top: 50px;
	}

	.product-description-container p:first-child {
		display: inline;
	}
	
	.custom-length-message {
		color: @brand-red;
		font-weight: @font-weight-semi-bold;
		font-size: @font-size-11;
		margin-top: 0;
		text-transform: @text-transform-none;
	}
	
	.standard-length-message {
		color: @brand-dark-blue;
		font-weight: @font-weight-bold;
		font-size: @font-size-11;
		display: inline;
		text-transform: @text-transform-none;
	}

	.product-description-container p:nth-child(2) {
		margin-top: 20px;
	}

	.part-number {
		margin-bottom: 10px;
		margin-top: -10px;
		font-weight: @font-weight-normal;
	}

	#content #breadcrumb {
		display: block;
		background-color: #efefef;
	}

	.product-image {
		float: left;
		margin-top: 41px;
		width: percentage(440/980);

		.product-image-carousel {
			border: 2px solid @gray-light;

			img {
				display: block;
				max-width: none;
				width: 100%;
				height: percentage(436/1040);

			}

			.owl-dots {
				display: none;
			}
		}

		.image-thumbs {
			.reset-list();
			text-align: left;
			margin: 15px -20px;

			li {
				border: 2px solid #d7d7d7;
				display: inline-block;
				margin: 0 20px;
				max-width: 120px;


				a,
				img {
					display: block;
				}

				&.active {
					border: 6px solid #494949;
				}
			}
		}

	}

	.product-shop {
		float: left;
		margin: 15px 0 20px percentage(60/980);
		width: percentage(480/980);
		position: relative;


		h1 {
			font-size: 30px;
			font-weight: @font-weight-semi-bold;
			margin-bottom: 10px;
		}

		.info-wrapper {
			width: 100%;
			max-width: 382px;

			.not-enabled {
			    border: 2px solid @gray-base;
			}
		}

		form {
			position: relative;
		}
	}

    .upload-document-wrapper {
        width: 100%;
        max-width: 382px;
        margin: 20px 0 0 0;

        .file-text {
            font-size: @font-size-base;
        }

        button {
            margin: 0 0 0 9px;
        }

        .dz-file-preview {
            clear: both;
            border-top: 0;
            position: relative;
        }
    }

	#product-tabs {
		background-color: @gray-lighter;
		padding-bottom: 57px;

		.tabs-header > div {
			background-color: transparent;
			padding-bottom: 0;
		}

		.accordion-content {
			padding: 0;
			background-color: @brand-white;

			div.inner {
				padding: 40px 40px 20px 40px;
			}
		}

		.inner > ul li {
			margin-bottom: 26px;

			&.col-md-4:nth-child(3n+1) {
				clear: left;
			}

			&.col-md-6:nth-child(2n+1) {
				clear: left;
			}
		}


	}

	.accordion-header {
		display: none;
	}

	.assembly__accordion-header {
		display: block;
		height: auto;
		padding-left: 0;
		border-bottom: 1px solid @brand-light-gray;
		background: none;
		text-transform: uppercase;
		font-size: @font-size-h4;

		&:before {
			display: none;
		}
	}

	.assembly__accordion-content--inner {
		padding: 17px 0;
	}

	.assembly__accordion-table {
		width: 100%;
	}

	.assembly__accordion-table--heading {
		font-weight: @font-weight-normal;

		&:first-child {
			text-align: left;
		}
	}

	label {
	    font-weight: @font-weight-normal;

	    span {
            &.label-text {
                font-weight: @font-weight-bold;
                font-size: @font-size-base;
                padding-right: 10px
            }
		}
	}

	.configurable-option {
		> .form-list > li {
			margin-bottom: 21px;

			&.color {
				margin-bottom: 18px;
			}

			&:last-child {
				margin-bottom: 25px;
			}
		}

		.color-disclaimer {
			margin-top: 18px;
		}

		.js-add-to-cart > button {
			margin: 20px 0;
		}
	}

	.button {
		margin: 30px 0;
	}

	#new-quote-modal-popup,
	#new-quote-modal-popup-async {
	    .button {
	        margin: 0;
	    }
	}

	.assembly-button-set {
		max-width: 382px;

		button {
			padding: 12px @indent-sm;
		}

		.button {

			&+ .button { // override
				margin-left: 25px;
			}
		}

		a.button {
			margin: 0;
		}
	}

	.custom-trim-punch-file-container {
		label {
			&.error {
				position: absolute;
				left: 0;
				bottom: -20px;
			}
		}
	}
}

.col-md-6.download-link {
	text-align: right;

	a {
		margin: 12px 0;
		display: inline-block;
	}
}


.page-cartPage {
    .page-productDetails{
        .upload-document-wrapper .file-text {
            font-weight: @font-weight-normal;
            line-height: 18px;
            margin-bottom: 5px;
            margin-top: 4px;
        }

        .upload-document-wrapper .file-text.custom-punch-files {
            line-height: 18px;
            margin-bottom: 5px;
        }

        .upload-document-wrapper{
            margin-top: 0;
            margin-bottom: 20px;
        }

        .custom-trim-punch-file-container {
            margin-top: 20px;
            margin-bottom: 0;
        }
    }
}


.documents-download {
	padding-bottom: 26px;
	margin-bottom: 20px;
	border-bottom: 1px solid @gray-light;

	div.custom-styled-select {
		max-width: 250px;
	}
}

@media (max-width: @screen-md){
    .documents-download {
        padding-bottom: 0;
    }

    .col-md-6.download-link a {
        margin-bottom: 20px;
    }
}

.product-documentations {
	&__link {
		text-decoration: none;
		color: @brand-black;
		font-weight: @font-weight-normal;
		display: inline-block;
		position: relative;
		padding-left: 21px;
		line-height: 1.2;

		i {
			color: @brand-blue;
			display: inline-block;
			margin-right: 8px;
			position: absolute;
			left: 0;
			top: -2px;
		}
	}
	&__link--alternate {
		text-decoration: underline;
		color: @brand-blue;
		&:hover {
			text-decoration: none;
		}
	}
}

.page-productDetails {

	#addto-cart-layer {
		background-color: transparent;

		.popupCartItem {
			padding-bottom: 8px;
			margin-bottom: 12px;
			border-bottom: 1px solid @brand-light-gray;

            span.empty {
                background-color: @brand-white;
            }
		}

		.modal-content {
			background-color: transparent;
			max-width: 379px;

			.button {
				display: inline-block;
				margin-top: 0;
				background-color: #96aab4;
				color: @brand-white;
				transition: background 0.3s ease;

			    + .close{
					float: right;
					background: seagreen;

					&:hover {
						background-color: darken(seagreen, 5%);
					}
				}

				+ .button {
					margin-left: 14px;
				}

				&:hover {
					background-color: darken(#96aab4, 20%);
				}
			}
		}

		.links {
			margin-top: 20px;
		}

		.itemName {
			font-size: @font-size-h3;
		}

		.itemName--condensed {
			line-height: 25px;
			margin-bottom: @indent-md;
		}

		.section-inner {
			padding: 35px 50px 13px 41px;
			background-color: rgba(103, 134, 149, 0.97);
			color: @brand-white;

			&.error {
				background-color: rgba(237, 28, 36, 0.97);
				padding-bottom: 10px;

				h4 {
					margin-top: 0;
					font-size: 21px;
				}

				.button {
					border-color: @brand-white;
					background-color: @brand-white;
					color: @brand-red;
				}

				.links {
					margin-top: 23px;
				}

				.messages li.note-msg,
				.messages li.error-msg {
					text-align: left;
					padding: 0;
					font-weight: @font-weight-normal;
					margin: 9px 0;
					background-color: transparent;

					&:first-child {
						margin-top: 0;
					}
				}
			}
		}

		table.length {
			margin-top: 5px;

			td:not(:first-child) {
				text-align: right;
				vertical-align: top;

				&.qty {
					padding: 0 0 0 10px;
				}

				&.separator {
					padding: 0 10px;
				}
			}

			td:first-child {
				padding-right: 10px;
			}
		}

		.options {
			span + span {
				&:before {
					content: ", ";
				}
			}
		}

		.variants {
			text-transform: capitalize;
		}

		.add-text {
			font-weight: @font-weight-semi-bold;
			margin-bottom: 25px;
		}
	}

	.product-options .title,
	label {
		.helpful-hint {
			vertical-align: middle;
			top: -2px;
			margin-left: 3px;
		}
	}

	.product-options .title .helpful-hint i {
		color: @brand-white;
	}
}

.rental-info label {
	font-weight: @font-weight-normal;
	font-size: @font-size-base;
	line-height: @line-height-base;
}

.qty {
    &.accessories,
    &.coil {
        background-color: @brand-offwhite;
        padding: 5px 20px 15px;

        input {
            max-width: 100px;
        }
    }

    &.accessories {
        span {
            margin-left: 10px;
            padding-top: 10px;
        }
	}

	&.coil {
	    span {
	        margin-left: 34px;
            padding-top: 16px;
	    }
	}
}


.product-description {
	margin-top: 20px;

	.features {
		.h4 {
			margin: 17px 0 17px 0;
			font-weight: @font-weight-semi-bold;
		}

		ul {
			&:extend(.row all);
			padding: 0;
			list-style: none;


			li {
				position: relative;
				padding: 0 10px 0 20px;
				float: left;
				width: 100%;

				&:before {
					position: absolute;
					content: "\2022";
					display: inline-block;
					font-size: 25px;
					top: -2px;
					line-height: 1;
					left: 5px;
				}
			}
		}
	}
}

@media (min-width: @screen-sm) {
	.product-description {
		.features {
			ul li {
				width: 100%;
				&:nth-child(3n+1) {
					clear: left;
				}
			}
		}
	}
}

@media (min-width: @screen-md) {
	.product-description {
		.features {
			ul li {
				width: percentage(1/3);
			}
		}
	}
}

.product-option {
	label {
		text-transform: capitalize;
	}
}

.color-swatch {
	&:extend(.clearfix all);
	margin: 9px -8px 0;

	li {
		display: block;
		float: left;
		height: 40px;
		width: 40px;
		margin: 0 8px 7px;

		&.disable {
			display: none;
		}

		a {
			border: 3px solid @brand-white;
			box-shadow: 0 0 0 3px @brand-white;
			background-color: @brand-blue;
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 100%;
			overflow: hidden;

			&.selected {
				box-shadow: 0 0 0 3px @brand-black;
			}

			&:hover:not(.disable) {
				box-shadow: 0 0 0 3px @brand-black;
			}
		}
	}
}

.product-description {
	margin-bottom: 25px;

	h2 {
		margin-top: 0;
	}
}

.mobile-product-name {
	display: none;
	font-size: 30px;
	font-weight: @font-weight-semi-bold;
	position: relative;

	.add-to-favorite {
		display: block;
		position: static;
		margin-top: 10px;
	}
}

.mobile-part-number {
	display: none;
}

.linear-foot-pricing {
	margin-top: 10px;
	font-size: @font-size-small;

	em {
		display: inline-block;
		color: @gray-dark;
		margin-right: 3px;
	}
}

.pdp-pricing-loader {
    position: relative;

	img {
    width: 22px;
    height: 22px;
    position: absolute;
}

	span {
    line-height: 22px;
    padding-left: 27px;
}
}

.non-standard-container {
	background-color: #EDEDED;
	padding: 10px;
	margin-top: 30px;
	width: 100%;
    max-width: 382px;

	.non-standard-text {
		display: flex;
		i {
			font-size: 22px;
			margin-right: 10px;
		}
		p {
			font-weight: bold;
			font-size: 14px;
			margin: 0;
		}
	}

	.non-standard-links {
		display: flex;

		&__link {
			color: #000000;
			font-weight: 500;
			font-size: 13px;
			margin-top: 9px;
		}

		&__link--secondary {
			margin-right: 25px;
			font-size: 14px;
			color: #69A965;
			margin-left: 28px;
			font-weight: 700;
		}
	}
}

.variant-qty-message{
	color: @brand-black;
	font-size: @font-size-base;
	line-height: @input-default-line-height;
	font-weight: @font-weight-normal;
	text-align: center;
	margin: 0;
}
.variant-qty-message--shifted{
	line-height: @custom-input-line-height;
	padding-left: 25px;
}

.quantity-wrapper{
	display: inline-block;
	vertical-align: top;
}

.quantity-input-wrapper{
	max-width: 100px;
	text-align: center;
}

.add_to_cart_form {
	button {
		margin: 30px 0 17px 0;
	}

	.custom-checkbox {
		width: 100%;

		label {
			font-size: @font-size-base;
			font-weight: @font-weight-normal;
		}
	}
}

.packaging .error {
	color: @brand-red;
	margin-top: 10px;
	display: block;
}

.custom-length,
.product-length {
	margin: 0;
	padding: 0;
	width: 100%;
	background-color: @brand-offwhite;

	table.table-length {
		margin-bottom: 0;

		td,
		th {
			background-color: @brand-offwhite;
			border: 0;
			font-size: @font-size-small;
			padding: 0;

			label {
				margin: 0;
			}

			input {
				padding: 4px 0;
				text-align: center;

				&.not-enabled {
					cursor: default;
					background-color: transparent;
					border: 0;
					color: @brand-black;
					opacity: 1;

				}
			}

			input::-webkit-input-placeholder {
				opacity: 1;
				color: @brand-black;
			}
			input::-moz-placeholder {
				opacity: 1;
				color: @brand-black;
			}
			input:-ms-input-placeholder {
				opacity: 1;
				color: @brand-black;
			}
			input:-moz-placeholder {
				opacity: 1;
				color: @brand-black;
			}
		}

		th {
			text-align: left;
			font-weight: @font-weight-bold;
			padding-top: 11px;
			padding-bottom: 6px;
		}

        th.table-length-header {
            background-color: @brand-primary;
            color: @brand-white;
            font-weight: @font-weight-bold;
            padding: 5px 10px;

            p {
                margin: 0;
                line-height: 20px;
                font-size: @font-size-base;
            }

            .helpful-hint {
                vertical-align: middle;
                margin-left: 3px;
                position: relative;
                top: -3px;

                i {
                    color: @brand-white;
                }
            }
        }

		td {
			padding: 11px 0;
			vertical-align: top;
		}

		tr:first-child:not(.piecemark) td:not(.piecemark) {
			padding-top: 0;

			.js-delete-row {
				margin-top: -20px;
			}
		}

		tr.error-container {
			td {
				padding: 0 10px;
				border-bottom: 4px solid @brand-white;

				label {
					padding: 0 0 10px;
					color: @brand-red;
					font-size: @font-size-small;
					line-height: 13px;
				}
			}
		}

		td.line-total-inches {
			position: relative;

			.js-delete-row {
				position: absolute;
				right: percentage(5/100);
				top: 50%;
				margin-top: -13px;
				font-size: 20px;
			}
		}

		td.line-id {
			color: @brand-green;
			font-size: 10px;
			font-weight: @font-weight-bold;
		}

		&.min-reach {
			.js-delete-row {
				display: none;
			}
		}

		&:not(.piecemark) {
			td.line-id {
				width: percentage(28/382);
				text-align: center;
				vertical-align: middle;
			}

			td.line-qty {
				width: percentage(86/382);
				padding-right: percentage(18/382);
			}

			td.line-feet {
				width: percentage(84/382);
				padding-right: percentage(18/382);
			}

			td.line-inches {
				width: percentage(58/382);
			}

			td.line-total-inches {
				width: percentage(98/382);
				padding-right: percentage(30/382);
				position: relative;

				.js-delete-row {
					position: absolute;
					right: percentage(5/100);
					top: 50%;
					margin-top: -13px;
					font-size: 20px;
				}
			}

            td.or-separator {
                width: percentage(30/382);
                font-size: @font-size-base;
				vertical-align: top;
				line-height: 30px;
            }

			td.line-id {
				color: @brand-green;
				font-size: 10px;
				font-weight: @font-weight-bold;
			}
		}

		&.piecemark {
			th:nth-child(2) {
				padding-left: percentage(10/382);
			}
			td.line-piece-mark {
				width: percentage(80/382);
				text-align: center;
				padding-right: percentage(10/382);
				padding-left: percentage(10/382);
			}

			td.line-qty {
				width: percentage(65/382);
				padding-right: percentage(10/382);
			}

			td.line-feet {
				width: percentage(60/382);
				padding-right: percentage(7/382);
			}

			td.line-inches {
				width: percentage(57/382);
			}

			td.line-total-inches {
				width: percentage(100/382);
				padding-right: percentage(30/382);
			}

			td.line-id {
				position: absolute;
				color: @brand-offwhite;
				height: 54px;
			}

			 td.or-separator {
                width: percentage(25/382);
                font-size: @font-size-base;
                vertical-align: top;
				line-height: 30px;
             }

			td.table-multiples-cell {
				padding-bottom: 0;
			}
		}
	}
}

.product-length {
	margin-top: 30px;
}

.custom-length {
	table.table-length {
		&:not(.piecemark) {
			td:first-child {
				width: percentage(29/382);
				text-align: center;
				border: 0;
			}

			td.line-feet {
				width: percentage(117/382);
				padding-right: percentage(7/382);
				border: 0;
			}

			td.line-inches {
				width: percentage(117/382);
				padding-right: percentage(19/382);
				border: 0;
			}

			td.line-total-inches {
				width: percentage(119/382);
				padding-right: percentage(30/382);
				border: 0;
			}
		}
	}
}

.price {
	background-color: @brand-white;
	padding-top: 30px;
	position: relative;

	table {
		float: right;
		font-size: @font-size-small;
		width: 100%;

		td {
			text-align: right;
		}

		td:nth-child(2) {
			width: 20%;
		}

		tr:nth-child(2) {
			td {
				padding-bottom: 5px;
			}
		}

		td:last-child {
			width: 15px;
			text-align: right;

			.helpful-hint {
				margin-top: 3px;
			}
		}

		tr.total {
			font-size: @font-size-base;
			border-top: 2px solid @gray-light;

			td {
				padding-top: 5px;
			}
		}

		tr.error {
			color: @brand-red;

			td {
				text-align: center;
			}
		}
	}
}

.custom-length-container {
	label {
		display: block;
		margin: 20px 0;
	}
}

.qty {
	position: relative;
	background-color: @brand-white;

	span {
		left: 100px;
		position: absolute;
		top: 24px;
	}
}

.product-options {

	.title {
		background-color: @brand-primary;
		color: @brand-white;
		font-weight: @font-weight-bold;
		padding: 5px 10px;
	}

	.option-content {
		background-color: @brand-offwhite;
		padding: 10px 0 3px 0;

		> ul > li {
			padding: 0 10px;
		}

		> ul > li:not(.control):not(.no-border) {
			margin-bottom: 10px;
			padding-bottom: 10px;
			border-bottom: 2px solid @brand-white;

			&[class*="SD_TYPE"] {
				border: 0;
				padding-bottom: 0;
			}

			&:last-child {
				margin-bottom: 0;
				border-bottom: 0;
			}
		}

		.custom-styled-select .outer {
			background-color: @gray-lighter;
		}

		.no-border + li,
		.no-border + .hide + li {
			margin-top: 5px;
		}
	}

	.control {
		float: left;
		min-width: 40%;
		padding-right: 10px;
	}

	.degree {
		position: relative;

		&:after {
			content: '°';
			font-size: 30px;
			font-weight: @font-weight-bold;
			position: absolute;
			top: 22px;
			left: 113px;
		}

		input {
			max-width: 100px;
		}
	}


}

.add-length {
	background-color: @brand-offwhite;
	padding: 8px 4px;

	a {
		position: relative;
		color: @brand-black;
		font-size: @font-size-base;
		text-decoration: none;
		display: inline-block;
		padding-left: 36px;
		margin-left: @indent-xs;

		i {
			position: absolute;
			display: inline-block;
			color: @brand-green;
			vertical-align: middle;
			line-height: 1;
			// 24px size for + icon
			font-size: @font-size-h3;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.product-length {
	.add-length{
		padding: 0;

		a {
			display: inline-block;
			padding: 8px 4px 8px 45px;
			margin: 0;
			height: 100%;
			width: 100%;
			transition: 0.3s all ease-out;
			&:focus {
				color: @brand-white;
				background-color: @brand-blue;
			}
		}

		i{
			left: 9px;
		}
	}
}

.add-to-favorite {
	color: @brand-black;
	font-size: 0;
	margin-bottom: 20px;

	i {
		color: @brand-red;
		font-size: @font-size-base;
	}

	span {
		display: inline-block;
		font-size: @font-size-base;
		margin-left: 5px;
	}

	&:hover {
		i:before {
			content: @fa-var-heart;
		}
	}
}

// Assembly Products
.assembly {
	background: @brand-white;
}

.assembly__chosen-length {
	font-size: @font-size-large;
}

.assembly__table {
	width: 100%;
}

.assembly__heading {
	font-size: @font-size-h5;
	font-weight: @font-weight-semi-bold;
	border-bottom: 2px solid @gray-dark;
}

.assembly__heading-title {
	text-align: left;
	padding-bottom: 7px;
	font-size: @font-size-base;
}

.assembly__row {
	font-weight: @font-weight-semi-bold;
	border-bottom: 1px solid @gray-light;
}

.assembly__cell {
	padding: 14px 0;
	line-height: @font-size-base;
}

.assembly__cell--centered {
	text-align: center;
}

.assembly__item-name {
	display: block;
}

.assembly__item-number {
	font-size: @font-size-small;
	font-weight: @font-weight-normal;
}

.assembly__remove {
	font-size: @font-size-large; //17?
}

.assembly__info {
	font-size: @font-size-small;
	font-style: italic;
	color: @gray-dark;
	margin-top: 7px;
}

.assembly__input {
	width: 107px;
}

.assembly-qty-table {
	position: fixed;
	visibility: hidden;
}

.assembly__option-title {
	background-color: @brand-primary;
	color: @brand-white;
	font-weight: @font-weight-bold;
	padding: @indent-xs @indent-sm;
}

.assembly__option-title i {
	color: @brand-white;
}

.assembly__option-content {
	background-color: @brand-offwhite;
	padding: @indent-sm 0 3px 0;
}

.assembly-pdp {
	.confirm-modal {
		.modal-wrapper {
			padding: 0 @indent-xl;
		}
		.confirm-content {
			padding-bottom: 0;
		}
	}
}

@media screen and (max-width: 1024px) {

	.add-to-favorite {
		&:hover {
			i.icon-heart:before {
				content: @fa-var-heart;
			}
			i.icon-heart-o:before {
				content: @fa-var-heart-o;
			}
		}
	}

	.page-productDetails {

		.accordion-content {
			padding: 0 20px;

			> .inner {
				padding: 20px 0 40px;
			}
		}

		#content .gor-accordion {

			.accordion-header {
				background-color: @gray-light;
				color: @brand-black;
				height: 40px;
				font-size: @font-size-h5;
				font-weight: @font-weight-semi-bold;
				margin-top: 20px;
				padding: 11px 15px 11px 20px;
				text-transform: capitalize;

				&:before {
					height: 40px;
				}

				&:first-child {
					margin-top: 0;
				}
			}
		}

		#product-tabs {
			ul {
				display: flex;
				flex-wrap: wrap;
			}

			li {
				padding-right: 50px;
			}

			.accordion-content > .inner {
				padding: 20px 20px 0 20px;
			}
		}

		.assembly__part-number {
			margin-top: @indent-sm;
		}
	}
}

@media screen and (max-width: 800px) {
	.page-productDetails .accordion-header {
		display: block;
	}

	.col-md-6.download-link {
		text-align: left;
	}

	.page-productDetails {
		.tabs-header {
			display: none;
		}
		.assembly-button-set {

			button {
				padding: @btn-default-padding;
				width: 100%;

			}

			.button {
				margin: @indent-lg 0 0;

				& + .button {
					margin-left: 0;
				}

				& + a.button {
					margin: @indent-lg 0 0;
				}

			}
		}
	}
}

@media screen and (max-width: 799px) {

	.page-productDetails {

		#product-tabs {
			li {
				&.col-md-4 {
					&:nth-child(3n+1) {
						clear: none;
					}
				}

				&.col-md-6 {
					&:nth-child(2n+1) {
						clear: none;
					}
				}
			}
		}

	}
}

@media screen and (max-width: 600px) {
	.product-image-carousel {
		max-width: 440px;
		margin-left: auto;
		margin-right: auto;
	}

	.non-standard-container {
		max-width: none;
	}

	.info-wrapper .add-to-favorite {
		display: none;
	}

	.mobile-product-name {
		display: block;
	}

	.page-productDetails {
		.part-number:not(.mobile-part-number) {
			display: none;
		}
	}

	.page-productDetails {

		.mobile-part-number {
			display: block;
			font-size: @font-size-base;
			font-weight: @font-weight-normal;
			margin-top: 8px;
		}

		.product-image,
		.product-shop {
			margin-left: 0;
			width: 100%;
			h1 {
				display: none;
			}
		}

		.product-image {
			margin-top: 0;
		}

		.product-shop .info-wrapper {
			max-width: none;
		}

		.upload-document-wrapper {
			width: calc(~'100% - 104px');
			button {
				margin: @indent-sm 0 0;
			}
		}

		.custom-trim-punch-file-container {
			label {
				&.error {
					position: relative;
					left: 0;
					top: 3px;
					margin-bottom: @indent-sm;
				}
			}
		}
	}

	.tabs-header {
		display: none;
	}

	.custom-length,
    .product-length {
        table.table-length {
            &.piecemark {
                td.line-piece-mark {
                    width: percentage(100/382);
                }

                td.line-qty {
                    width: percentage(80/382);

                }
                td.line-feet {
                    width: percentage(50/382);

                }
                td.line-inches {
                    width: percentage(57/382);
                }
            }
        }
    }

	.assembly {
		margin: @indent-xl 0;
	}

	.assembly__info {
		margin-bottom: 0;
		padding-bottom: @indent-sm;
	}
}

.page-productDetails {
	.custom-length,
	.product-length {
		background: none;
	}
}

@media screen and (max-width: @screen-sm) {
	.page-productDetails {

		#addto-cart-layer {
			.section-inner {
				padding: 35px 20px 35px 21px;
			}
			.modal-content {
				.button {

					+ .button {
						margin-left: 7px;
					}
				}
			}
		}

		.assembly-button-set {
			max-width: 100%;
		}
	}
}

@media screen and (max-width: @screen-xs) {
    .custom-length,
    .product-length {
        table.table-length {
            &.piecemark {
                th {
                    font-size: 11px;
                }
            }
        }
    }
}
