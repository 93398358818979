@import (reference) "../components/variables";

.user-login-wrapper {
	max-width: 400px;
	margin: 110px auto 200px;
	h1 {
		position: relative;
		height: 50px;
		padding: 13px 30px;
		background: @gray-darker;
		color: @brand-white;
		margin: 0;
		text-transform: capitalize;
		&:before {
			background-color: @brand-red;
			content: "";
			display: block;
			height: 50px;
			left: 0;
			position: absolute;
			top: 0;
			width: 10px;
		}
	}
	.user-login-form {
		padding: 30px;
		background: rgba(0, 0, 0, .75);

		&.multiple-login-form {
			min-height: 200px;
		}

		label,
		a,
		p {
			color: @brand-white;
			font-weight: 700;
		}
		.login-links li {
			margin-top: 10px;

			label {
				font-size: @font-size-base;
			}

		}
		.control-group {
			margin-bottom: 16px;
		}
	}
	.login-page-link{
		background-color: @brand-white;
		padding: 9px 30px 11px;

		a {
			color: @brand-blue;
			font-size: @font-size-base;
			line-height: 1.3;
		}
	}
}

.tab-select {
	padding-bottom: 20px;

	.custom-styled-select.with-custom-options .outer {
		background-color: @brand-white;
	}
}

.forgotten-pwd {
	.modal-content {
		background-color: rgba(0, 0, 0, 0.75);
		width: 100%;
		max-width: 400px;
	}

	.modal-wrapper {
		color: @brand-white;
		padding: 30px;
	}

	.col-md-6 {
		width: 100%;
	}
}

.page-invoice-details {
	background: @brand-white;
}

.invoice-collections-notice {
	text-transform: uppercase;
	margin-top: 20px;
}

.invoice-detail-wrapper {
    padding-bottom: 30px;
	background: @brand-white;

	.invoice-summary-desktop {
		table {
			tr {
				td:first-child {
					border-left: 1px solid @gray-base;
				}

				td:last-child {
					border-right: 1px solid @gray-base;
				}
			}
		}
	}
}

.invoice-header-container {
	margin-top: 30px;

	.invoice-header {
		font-size: @font-size-h1;
	}
}

.invoice-options-wrapper {
	width: 125px;
	float: right;
	font-size: @font-size-base;
}

.invoice-print {
	margin-top: 10px;
	float: right;
	color: #1DA2C3;

	&:hover {
		cursor: pointer;
	}
}

.invoice-product-attribute {
	font-weight: @font-weight-normal;
}

.page-multipleLogin {
	.site-logo {
		float: none;
		margin: 0 auto;
	}
	.header-top {
		display: none;
	}
	.footer-top {
		margin-top: 0;
	}
}

.logged-out,
.page-multipleLogin .header-wrapper,
.page-login {
	.site-search-wrapper,
	.site-search,
	.header-top,
	#nav-container {
		display: none;
	}

	.site-logo {
		width: 131px;
		float: none;
		display: block;
		margin: 0 auto;

		a {
			// vertically center logo
			padding-top: 16px;
		}
	}

	.footer-top {
		margin-top: 0;
	}
}

.page-multipleLogin .header-wrapper,
.page-login {
	.footer-top {
		display: none;
		margin-top: 0;
	}
}

.userLogin {
	background: url('../../common/images/nci-bg.jpg') no-repeat 50% 50%;
	background-size: cover;
	min-height: 550px;
}

.account-list-search-wrapper {
	margin-bottom: 37px;

	.heading {
		font-size: @font-size-large;
	}

	.account-list-search {
		width: 100%;
		margin-top: 6px;
		position: relative;
		max-width: 505px;

		.input-text {
			padding-right: 44px;
		}

		button {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			border: none;
			background: none;
			padding: 0 12px 0 6px;

			.icon-search {
				font-size: 18px;
			}
		}
	}
}

.account-section-content {

	.account-orderhistory {
		padding: 24px 20px;
		background-color: @brand-white;

		.table {
			td {
				text-align: center;
				padding-left: 1%;
				padding-right: 1%;

				a:not(.order-id) {
					font-size: @font-size-base;
					font-weight: @font-weight-bold;
					text-decoration: none;
				}

				&:nth-child(1) {
					padding-left: @indent-sm;
					padding-right: @indent-sm;
				}

				&:last-child {
					vertical-align: middle;
					text-align: center;
					padding: 10px 16px;
				}
			}
		}
	}
}

.page-order,
.page-order-approval-details{
	.product-table {
		margin-bottom: 20px;
	}
}



.order-info {
	margin-bottom: 20px;
	margin-top: 20px;

	td {

		vertical-align: top;
		padding: 21px 10px;
	}

	.table {
		margin-bottom: 0;

		> tbody > tr > td:first-child {
			padding-left: 20px;
		}
	}
}

.options-top {
	margin: 20px auto 10px;
}

.order-info-table {

	.show-mobile {
		display: none;
	}

	.file a {
		text-align: left;
	}

	tr td {
		border: 0;
		font-size: @font-size-base;
		line-height: 1;
		text-align: left;
		padding: 0 0 10px 0;
		font-weight: @font-weight-semi-bold;

		a {
			display: block;
			margin-top: 3px;
			text-decoration: none;
			word-break: normal;
			white-space: normal;

			i {
				display: inline-block;
				margin-right: 5px;
			}
		}
	}

	td:first-child {
		padding: 0 15px 0 0;
		vertical-align: top;
		font-weight: @font-weight-normal;
	}

	td:last-child {
		width: auto;
	}
}

.no-data {
	padding-bottom: 20px !important;
}

.order-acknowledgment {
	background-color: @brand-white;
	padding: 24px 20px;

	.title {
		font-size: @font-size-h5;
		font-weight: @font-weight-bold;
		line-height: 1;

		strong {
			font-size: @font-size-h4;
		}
	}

	li {
		float: left;
		width: 122px;
		margin-right: percentage(44/980);
		margin-top: 10px;
		line-height: 1.2;

		a {
			position: relative;
			color: @brand-black;
			display: block;
			font-weight: @font-weight-normal;
			padding-left: 21px;
		}

		i {
			color: @brand-blue;
			line-height: 1;
			font-size: @font-size-h5;
			left: 0;
			position: absolute;
			top: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.page-quotes {
	.table td {
		white-space: normal;
		border: 1px solid #c9c9c9;
	}

	.table th {
		white-space: normal;
		border: 1px solid #b3b3b3;
	}

	.table {

		.use-template-cell {
			width: 160px;
		}
	}
}

//common quotes and orders landing
.dashboard-table {

	.custom-checkbox {
		display:none;
	}
	.quote-order-name {
		line-height: 16px;
	}
}

.page-quotes,
.page-orders {

	.quote-created {

		&.active {
			margin-left: 33px;
		}
	}
}

.company-cell {
	width: 152px;
	word-wrap: break-word;
}

.accordion-content-inner {

	.js-copy-quote,
	.js-copy-quote-ajax-trigger {
		text-decoration: none;
		color: @brand-black;
		font-weight: @font-weight-normal;
		display: block;
		padding: 0 8px;
		font-size: @font-size-base !important; //override needed
		line-height: 24px;
		width: 100%;
		text-align: left;
		cursor: pointer;

		span {
			color: @brand-blue;
			padding-right: @indent-xs;
		}
	}
}

//quotes landing
.selected-quotes{
	display: none;
	background: @brand-green;
	color: @brand-white;
	padding-top: @indent-sm;
	font-size: @font-size-large;
	font-weight: @font-weight-semi-bold;
	margin-bottom: 21px;
}

.selected-quotes__button {
	background: @brand-white;
	color: @brand-green;
	font-weight: @font-weight-bold;
	font-size: @font-size-base;
	margin-left: @indent-lg;
	margin-right: @indent-lg;
	padding-left: 13px;
	padding-right: 12px;
}

.selected-quotes__cancel {
	color: @brand-white;
	font-size: @font-size-base;
	padding-right: 8px;
}

.selected-quotes__copy {
	display: inline-block;
	margin: 0 0 0 @indent-lg;
	vertical-align: middle;
}

.selected-quotes__info {
	font-size: @font-size-base;
	background: @brand-white;
	color: @text-color;
	padding: @indent-sm @indent-sm @indent-sm @indent-lg;
	text-align: left;
	margin-top: @indent-sm;
}

.action-disabled {
	opacity: .5;
	pointer-events: none;
}

.account-section-content {

	.table th {
		white-space: normal;
		border: 1px solid #b3b3b3;
	}

	.table td {
		white-space: normal;
		border: 1px solid #c9c9c9;
	}

	.table td {
		vertical-align: middle;
		text-align: right;
		padding: 18px percentage(20/940) 14px percentage(20/940);

		&:nth-child(1) {
			text-align: left;
		}

		&:last-child {
			padding-top: 12px;
			padding-left: percentage(27/940);
		}
	}

	.button {
		display: block;
		margin: 20px auto 6px;
	}
}

.list-message {
	font-size: @font-size-large;
	margin-bottom: 20px;

	span {
		color: @brand-red;
	}
}

.account-section {
	height: 100%;
	padding: 0 20px 16px;
	background-color: @brand-white;

	.headline {
		&:extend(.h2 all);
		font-size: @font-size-h3;
		padding-bottom: 4px;
		margin-top: 15px;
	}

	.col-left,
	.col-right {
		float: left;
		width: 50%;
	}

	.col-right {
		float: right;
	}

	.link {
		text-align: left;
		white-space: normal;
		word-break: normal;
	}
}

.other-information {
	.section-subtitle {
		display: block;
		font-size: @font-size-h5;
		font-weight: @font-weight-bold;
		margin-bottom: 1px;
	}

	.headline {
		margin-bottom: 17px;
	}

	.col-left {
		border-right: 1px solid @gray-light;
	}

	.col-right {
		padding-left: percentage(30/940);

		> div {
			margin-bottom: 14px;

			&:last-child {
				margin-bottom: 7px;
			}
		}
	}

	td {
		vertical-align: top;
	}

	tr:last-child td {
		padding-top: 1px;
	}

	td:last-child {
		font-weight: @font-weight-bold;
		padding-left: 17px;
	}

	.terms {
		font-size: @font-size-h5;
		text-decoration: none;
	}

	i {
		display: inline-block;
		font-size: 16px;
		font-weight: @font-weight-normal;
		margin-left: 6px;
		line-height: 1;
		vertical-align: middle;
	}
}

.details-separator {
	margin-top: 20px;
}

.order-approval-detail {
	.product-table {
		td {
			&:first-child {
				padding-left: 20px;
			}

			&:nth-child(2) {
				text-align: center;
				padding: 21px 5px;
			}
		}
	}
}

.page-invoice-details {
	.order-totals {
		min-height: 200px;
		border: @table-cell-border;
	}
}

.quote-items {
	.table {
		margin: 0;
	}

	&.cart-details {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.detail-sort .custom-styled-select.with-custom-options {

	width: 155px;
	float: right;
	margin-bottom: 10px;

	span.outer.open:before {
		content: "";
		display: block;
		width: calc(100% - 3px);
		height: 2px;
		background-color: #f5f5f5;
		position: absolute;
		bottom: 0;
		right: 2px;
		z-index: 51;
	}

	ul.custom-options {
		border-top: 2px solid #d7d7d7;
		width: 194px;
		right: 0;
		left: auto;
	}
}

.sort-table {
	th {
		cursor: pointer;

		&:last-child {
			cursor: default;
		}
	}
}

.page-quote {
	.cancel-panel {
		margin-top: 0px;
		margin-bottom: 70px;
	}

	.container-fluid + .container-fluid:not(.cancel-panel) {
		&.order-info {
			margin-top: 10px;
		}
	}

	.actions-tab {
		.gor-accordion {
			.accordion-content {
				&.gor-active,
				&.accordion-content {
					&:after {
						max-width: 102px;
					}
				}
			}
		}
	}
}

.page-quotes {
	footer {
		margin-top: 50px;
	}

	table tr td.no-data {
		text-align: center;
	}

	.cancel-panel {
		margin-top: 20px;
	}
}

.page-dashboard,
.page-category,
.page-quote,
.page-account,
.page-updatePassword,
.page-address-book,
.page-favorites,
.page-CustomerService-,
.page-contactUs,
.page-siteMap,
.page-order,
.page-orders,
.page-my-default-markups,
.page-report-generate,
.page-report-dashboard,
.page-invoice-details,
.page-invoices {
	footer {
		margin-top: 50px;
	}
}

.page-category {
	#page {
		background-color: @brand-white;
	}
}


.cancel-panel {
	margin-top: 30px;

	+ .cancel-panel {
		margin-top: 20px;
	}

	+ .cancel-actions {
		margin-top: 0;
	}

	.col-md-8 > div {
		float: right;
	}
}



.totals-table {
	width: 100%;
	border-bottom: 1px solid @gray-light;
	margin-top: -20px;

	tr:nth-child(1) {
		td {
			padding-top: 13px;
		}
	}

	tr:last-child td {
		padding-bottom: 15px;
	}

	td {
		padding-top: 9px;
	}

	td:last-child {
		width: percentage(124/441);
		text-align: right;
		border-left: 1px solid @gray-light;
	}

	+ .total {
		margin-top: 14px;
		text-align: right;
		display: block;
		width: 100%;

		> strong {
			display: inline-block;
			margin-left: 4px;
		}
	}
}

.grand-total-summary {
	float: right;
	margin-top: 20px;

	.total{
		float: right;
		font-weight: @font-weight-bold;
	}
}

.approval-buttons-set {
	margin-top: 30px;
	margin-bottom: 70px;
}

.disable-link > div {
	display: inline-block;

	&:first-child {
		margin-right: 10px;
	}
}

.page-updatePassword,
.account-page {
	.cancel-actions {
		display: block;
		margin-top: 20px;
	}
}

.tax-exempt-row + tr a {
	word-break: break-all;
}

.page-orders,
.page-quotes {
	.gor-tabs .tabs-content {
		transition: opacity 1ms ease;
	}

}

// My Org User/Group/Addresses common styles
.org-tabs {

	.tabs-content {
		padding: 0;
	}
}

.m-trigger {
	float: right;
}

// override default select for white bg
.org-company {
	margin-bottom: 40px;

	label {
		font-weight: normal;
	}

	.custom-styled-select {

		&.with-custom-options {

			.outer {
				background: @brand-white;
			}
		}
	}
}

.org-search {
	width: 100%;
	max-width: 418px;
}

.org-search__form {
	position: relative;
}

.org-search__input {
	border-width: 1px;
}

.org-search__button {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	border: none;
	background: none;
	padding: 0 12px 0 6px;
}

.org-search__heading {
	font-size: @font-size-h4;
	margin-bottom: @indent-sm;
}

.org-search__count {
	color: @brand-red;
}

.org-sort-by {
	float: right;
	margin: 35px 0 0;
}

.section-inner {

	&.general-info {
		padding: 0;
	}
}

.myorg-entries-table {
	th {
		cursor: pointer;
	}
}

.general-info__section {
	padding: 20px 30px 23px;
	border-left: 1px solid @gray-light;
}

.general-info__section--no-border {
	border-left: none;
}

.roles-section {

	.icon-question-circle {
		font-size: 20px;
		position: absolute;
		left: 82px;
		top: 12px;
	}
}

.org-status {
	clear: both;
}

.role-list {

	.custom-checkbox {
		display: block;
		margin-bottom: @indent-md;

		+ .custom-checkbox {
			margin-left: 0;
		}
	}

	label {
		font-weight: normal;
		font-size: @font-size-base;
	}
}

.section-heading {
	h4 {
		margin: 0 0 @indent-xs;
		font-weight: @font-weight-semi-bold;
		font-size: @section-heading-font-size;

		&.bottom-offset {
			margin-bottom: 40px;
		}
	}

	&.bottom-offset {
		margin-bottom: 20px;
	}
}

.section-heading__inner {
	margin-bottom: @indent-xl;
}

.section-heading__text--condensed {
	line-height: 1.2;
	font-size: @font-size-mid-sm;
}

.section-inner-info {
	font-size: @font-size-small;
	display: inline-block;
	line-height: @line-height-custom;
}

.section-inner__alt-heading {
	display: inline-block;
	font-size: @font-size-h5;
	font-weight: @font-weight-bold;
	line-height: @line-height-custom;
	margin-bottom: @indent-sm;
}
.section_inner__image {
	max-width: 105px;
}

.general-info-list {
	> li {
		padding: 0;
	}
}

// Users list in my org add new user
.c-users-list {
	max-height: 475px;
	overflow: auto;
}
.c-users-list--short {
	max-height: 115px;
}

.c-users-list__item {
	margin: 0;
}

.c-users-list__label {
	font-weight: normal;
	font-size: @font-size-base;
}

.c-users-list__checkbox {
	display: block;
	position: relative;
	margin: 0 @indent-md @indent-md 0;
}

.user-details-approvers {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.add-user-load-more {
	margin-bottom: 20px;
}

.message-set__success,
.message-set__error {
	margin: 15px 0;
	font-weight: @font-weight-bold;
}

.message-set__success {
	color: @brand-green;
}

.message-set__error {
	color: @brand-red;
}

.message-set__success--top-aligned {
	margin-top: 0;
}

//Edit User
.general-info-list {
	li {
		width: 100%;
		margin-bottom: 18px;
	}
}

.reset-list__item {
	margin-bottom: 18px;
}


.viewall {
	clear: both;
	font-size: @font-size-base;
}

//override
.gor-modal-viewall {
	&.modal-popup {
		.modal-content {
			a {
				display: inline-block;
				&:extend(.clearfix all);
			}
		}
	}
}

@media screen and (min-width: @screen-lg) {
	.table th {
		max-width: 70px;
		font-size: 12px;
		padding: 9px;
	}
}

@media screen and (max-width: @screen-lg){
	.table th {
		max-width: 50px;
		font-size: 12px;
		padding: 0;
	}

	.user-login-wrapper {
		margin: 60px auto 100px;
	}
}

@media screen and (min-width: @screen-md) {
	.invoice-grid {
		margin-top: 20px;

		.left {
			padding-left: 0;
		}

		.right {
			padding-right: 0;
		}
	}

	.invoice-grid-info {
		width: 50% !important;
	}
}

@media screen and (max-width: @screen-lg) {
	.selected-quotes {
		text-align: center;
	}

	.selected-quotes__copy {
		margin: 0 @indent-xs @indent-sm;
	}
}

@media screen and (max-width: @screen-md) {

	.invoice-grid {
		margin-bottom: 20px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.user-login-wrapper {
		margin: 50px auto 100px;
		padding: 0 30px;
	}

	.account-section-content {
		.table td:not(:nth-child(1)):not(:last-child) {
			text-align: center;
		}
	}

	.order-info-table {
		.show-mobile {
			display: table-row;
		}

		td:first-child,
		td {
			padding-bottom: 10px;
		}

		tr.tax-exempt-row {
			td {
				padding-bottom: 0;
			}

			+ tr td {
				padding-bottom: 14px;
			}
		}
	}

	.accountOrderDetailOrderTotals {
		.col-sm-12 + .col-sm-12 {
			margin-top: 20px;

			.account-section {
				margin-top: 0;
			}
		}
	}

	.m-trigger {
		float: left;
	}

	.org-tabs {
		.tabs-header {
			display: block;
		}
	}

	.org-search {
		width: 100%;
		margin: @indent-lg 0;
	}

	.org-sort-by {
		margin: 55px 0 0;
	}

	.section-heading {
		padding: 0;
		margin-bottom: @indent-md;
	}

	.general-info__section {
		padding: @indent-sm 30px;
		border-left: none;
	}

	.edit-user-col {
		padding: 0;
	}

	.usergroup-inputs {

		li {
			margin-bottom: 18px;
		}
	}

	.page-quotes {

		.quote-template-action-tab {
			max-width: 160px;
		}
	}
}

@media screen and (max-width: @screen-sm) {
	.invoice-header-container .invoice-header {
		font-size: @font-size-h2;
	}

	.page-massEditPage {
		.show-mobile {
			display: block;
		}
	}

	.page-quotes {
		.quote-template-action-tab {
			max-width: 100%;
		}
	}
}

@media screen and (min-width: 641px) {
	.invoice-summary-mobile {
		display: none;
	}

	.page-orders,
	.page-quotes,
	.page-report-dashboard,
	.page-massEditPage,
	.page-my-company {
		.show-mobile {
			display: none;
		}
	}
}

@media screen and (min-width: 641px) and (max-width: 767px) {
	// quotes page with 5 tabs, reduce spacing to fit
	.page-quotes {
		.gor-tabs {
			.tabs-tab {
				padding: 0 14px;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.myorg-entries-table {
		.responsive-table-link {
			display: inline-block;
			font-size: @font-size-base;
			margin-bottom: 10px;
		}

		.responsive-table-cell {
			font-size: @font-size-base;
		}
	}

	.actions-tab {
		float: left;

		.gor-accordion {
			.accordion-content {
				right: inherit;
			}
		}
	}

	.cart-details {

		.actions-tab {
			margin-top: 0;
		}
	}

	.page-order,
	.page-quote,
	.cart-details {

		.gor-accordion {

			.accordion-content {
				right: 0;
				left: inherit;
			}
		}
	}
}

@media screen and (max-width: 640px) {
	colgroup {
		display: none;
	}

	.page-orders,
	.page-quotes,
	.page-my-company,
	.page-massEditPage {
		.tabs-row {
			display: none;
		}
	}

	.page-orders .show-mobile {
		display: block;
	}

	.invoice-summary-desktop {
		display: none;
	}

	.invoice-summary-table-mobile {
		width: 90%;

		tr {
			border: 0;
			padding: 0;

			td {
				border: 0;
				padding: 0;

				font-size: @font-size-base;
			}
		}
	}

	.invoice-items-table-mobile {
	    td {
	        font-size: @font-size-base;
	    }
	}

	.content-inner {
		.pagination-bar {
			margin-right: 20px;
		}
	}

	.order-info .table {
		th,
		td {
			display: none;

			&:first-child,
			&:last-child {
				display: table-cell;
			}
		}
	}

	.account-section {
		.col-left,
		.col-right {
			width: 100%;
		}
	}

	.order-info-table {
		width: 100%;

		.show-mobile {
			display: table-row;
		}

		tr td {
			padding-bottom: 10px;
		}

		td:first-child {
			padding-right: 3px;
			padding-bottom: 10px;
			width: 71% !important;
		}

		td:nth-child(2) {
			padding: 0 0 10px 0;
		}

		td:last-child {
			padding-right: 0 !important;
			text-align: left !important;
		}
	}

	.other-information {
		.col-left {
			border-right: none;
		}

		.col-right {
			padding-left: 0;

			> div {
				margin-bottom: 19px;
				padding-top: 19px;
				border-top: 1px solid @gray-light;
			}
		}

		.value-order {
			padding-bottom: 19px;
		}
	}

	.account-section-content {
		.account-orderhistory {
			padding-left: 1%;
			padding-right: 1%;

			.table th,
			.table td {
				&:nth-child(1) {
					width: 151px;
				}

				&:last-child {
					width: 104px;
					text-align: left;
					padding-left: percentage(20/274);
					padding-right: percentage(10/274);
				}
			}
		}
	}

	.page-orders {
		.table {
			th {
				&:nth-child(1) {
					padding-left: 20px;
				}
			}

			td:nth-child(1) {
				padding-left: 25px;
			}
		}
	}

	.order-acknowledgment {
		.title {
			strong {
				display: block;
				margin-bottom: 6px;
			}
		}

		li {
			margin-right: percentage(31/275);

		}
	}

	.page-orders,
	.page-quotes {

		.empty {
			padding: 0 20px;
		}
		.gor-tabs .tabs-content {
			padding: 20px;
		}

		.show-mobile {
			&.strong {
				background-color: @brand-white;
				padding: 20px 20px 0;
				font-weight: @font-weight-bold;
			}
		}
	}

	.table:not(.dashboard-table):not(.product-table):not(.table-length):not(.itemized-list-table) td:first-child {
		.order-info-table {
			tr.tax-exempt-row td {
				padding-bottom: 5px;
			}

			td {
				padding-left: 0;
				padding-top: 0;
				width: auto;

				&:first-child {
					.break-word();
				}
			}
		}
	}

	.order-approval-detail {
		.product-table {
			tr {
				th:first-child {
					padding-left: 20px;
				}

				td {
					&:first-child {
						padding: 21px 10px 21px 20px;
					}

					&:nth-child(2) {
						text-align: center;
						padding: 21px 5px;
						word-break: break-all;
					}
				}
			}

			tbody {
				tr {
					td:last-child {
						text-align: center;
						padding-right: 5px;
					}
				}
			}
		}
	}

	.m-trigger {
		width: 100%;
	}

	.org-search {
		max-width: 100%;
	}
	.org-sort-by {
		margin: 35px 0 0;
	}
}

.modify-checkout {
	margin-top: @indent-xl;

	.orderBackBtn + .orderBackBtn {
		margin-left: @indent-md;
	}
}

//Address book list view

.scrollTo-list ul,
.manage-address-list, .manage-contact-list {
	.reset-list;
}


.manage-address-list, .manage-contact-list {
	border: 3px solid rgba(0,0,0,0.2);
	max-height: 583px;
	overflow-y: auto;

	&.list-add-new {
		max-height: none;
		overflow-y: visible;
	}

	&:first-child {
		border-bottom: 0;
	}
	&:last-child {
		border-top: 0;
	}
	&::-webkit-scrollbar {
		width: 10px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #ccc;
		border-radius: 10px;
	}


	li {
		padding: 5px 14px;
		clear: both;
		border-bottom: 1px solid @gray-light;

		&:last-child {
			border-bottom: 0;
		}

	}
	.letter-separator {
		background-color: @gray-light;
		display: block;
	}

	.new-address-item, .new-contact-item {
		background-color: @gray-lighter;
		position: relative;
		padding: 0;

		.icon {
			color: @brand-green;
			font-size: 24px;
			line-height: 1;
			position: absolute;
			left: 5px;
			top: 50%;
			transform: translateY(-50%);
		}
		a {
			display: block;
			padding: 8px 0 8px 30px;
			color: @brand-black;
			text-decoration: none;
			&:hover {
				color: @brand-blue;
			}
		}
	}

	.address-list-item, .contact-list-item {
		cursor: pointer;

		&.gor-active {
			background-color: @brand-blue;
			color: @brand-white;
		}
	}
}
.mb-15 {margin-bottom:15px;}

.contactFormOptions { background-color: #ececec;
    padding: 7px 10px; border:2px solid #c9c9c9;
    width: 32%;}

.contactFormOptions option { background:#ececec;}

.scrollTo-list {
	float: left;

	a {
		padding: 12px;
		text-decoration: none;
		color: @brand-black;
		text-transform: uppercase;

		&:hover {
			text-decoration: underline;
		}
	}
}


.address-list-wrapper {
	float: left;
	margin-bottom: @indent-lg;
	width: 50%;
}
.scrollTo-list + .address-list-wrapper {
	width: calc(~'50% - 48px');
}

.selected-address-panel {
	padding: @indent-xl;
	border-top: 1px solid @gray-light;

	.button {
		min-width: inherit;
	}

	.top, .left, .right {
		margin-bottom: @indent-md;
	}

	.buttons-set {
		margin-top: @indent-md;
	}

	.close {
		display: none;
	}
}

@media screen and (max-width: @screen-md) {
	//mobile & tablet

	.selected-address-panel {
		clear: both;

		.close {
			display: block;
		}
	}

	.product-table td {
		padding: @indent-lg 0;
	}

	.address-list-wrapper {
		width: 100%;
	}

	.scrollTo-list + .address-list-wrapper {
		width: calc(~'100% - 48px');
	}

	.scrollTo-list {
		float: right;
	}

	.userLogin {
		min-height: 525px;
	}

	.user-login-wrapper {
		margin: 40px auto 60px;
	}

	.section-heading {
		.section-inner-title--offset-mobile {
			margin-top: 20px;
		}
	}
}


#updateProfileForm {
	.col-sm-12, .col-md-6 {
		width: 100%;
	}

	.buttons-set {
		padding-top: @indent-md;
	}
}


.markup-section {
	margin-bottom: 30px;

	.heading {
		font-size: @font-size-h3;
		font-weight: @font-weight-bold;
		margin-bottom: 5px;
	}

	.markup-field {
		margin-bottom: 10px;
		height: 77px;
	}

	.buttons-set {
		margin-bottom: 20px;
	}
}

.account-default-markups {
	h4 {
		margin-bottom: 5px;
		font-weight: @font-weight-bold;
	}

	table {
		margin-bottom: 5px;

		td {
			height: 23px;
		}
	}

}

@media screen and (min-width: 769px) {
	//desktop only

	.new-address-form {
		width: 50%;
	}

	.selected-address-panel {
		float: left;
		width: 50%;
	}
}

@media screen and (max-width: @screen-md) {
	.account-list-search-wrapper {
		max-width: none;
		margin-bottom: 20px;

		.account-list-search {
			max-width: none;
		}
	}
}

@media screen and (max-width: @screen-sm) {
	.selected-quotes__button {
		display: block;
		margin: @indent-sm @indent-lg;
	}
	.selected-quotes__copy {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 374px) {
	.account-section-content .account-orderhistory {
		padding-top: 0;
	}
}


.global-message-after-breadcrumb {
	margin-top: -30px;
	margin-bottom: 30px;
}

#breadcrumbs.quote-list-page-breadcrumbs{
	margin-bottom: 0;
}

.margin-top-30 {
	margin-top: 30px;
}


.freight-items {
	padding-right: 35px;
}

// Invoice Export Styles
.dual-list .list-group {
	margin-top: 8px;
}

.invoice-datepicker-container {

	.invoice-datepicker {
		width: 150px;
		height: 40px;
		font-size: @font-size-base;
	}
}

.invoice-export-po-list {
	font-weight: @font-weight-normal;
}

.list-left li, .list-right li {
	cursor: pointer;
}

.list-arrows {
	padding-top: 100px;
}

.list-arrows button {
	margin-bottom: 20px;
}

.invoice-summary-mobile {
    .order-info {
        table {
            border: 1px solid @gray-light;
        }

        .invoice-summary-table-mobile {
            width: 100%;

            tr td {
                padding-left: 10px;
                padding-bottom: 10px;
                width: 50% !important;
                text-align: left !important;
            }
        }
    }
}

//b2b2c quotes/orders
.b2c-table-heading,
.b2c-approve-reject {
	width: 150px !important;
}
