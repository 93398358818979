//MASS ATTRIBUTE EDIT
.editor-tabs__container {
    background: @brand-white;
}

.editor-tabs__heading {
    font-size: @font-size-large;
}

.editor-tabs--wide {
    padding: 0 @indent-xl;
}

.editor-tabs__filter {
    //important added to avoid deep nesting
    ul {
        width: 169px !important;
    }
    li {
        padding-left: 25px !important;
    }
}

.clearSelection {
    color: #678695;
    font-weight: 700;
    text-decoration: underline;
	cursor:pointer;
}

// editor accordion updates
.gor-accordion {

    &.editor-accordion {

        .accordion-header {
            background: @brand-blue;
            font-size: @font-size-large;
            font-weight: @font-weight-bold;
            height: auto;
            padding: @editor-accordion-padding;
            text-transform: capitalize;

            &:before {
                display: none;
            }
        }

        .accordion-item {
            padding: 0 @indent-sm;
        }


    }
}

// editor tabs updates
.editor-tabs {

    .tabs-row {
        margin-left: 20px;
    }

    .disabled {
        opacity: .5;
        pointer-events: none;
    }

    .tabs-content {
        padding: 40px @indent-lg;
    }
}

.editor-hidden-items {
    font-size: @font-size-large;
    padding-left: 60px;
}

.mass-edit-buttons-set,
.mass-edit-confirm-modal {
    button {
        margin-bottom: @indent-lg;
    }
}

.mass-edit-confirm-modal {
    button {
        margin-right: @indent-lg;
    }
}

// confirm mass edit modal
.mae-modal__button {
    width: 100%;
}

.mae-modal__icon {
    font-size: @font-size-h3;
    color: @brand-primary;
    position: relative;
    top: -5px;
}

.page-massEditPage {

    h2 .helpful-hint {
        margin-left: @indent-xs;
    }

}

@media screen and (max-width: @screen-md) {
    .editor-tabs--wide {
        padding: 0;
    }

    .editor-hidden-items {
        padding-left: @indent-lg;
    }
}


@media screen and (max-width: @screen-sm) {
    .mass-edit-confirm-modal,
    .mass-edit-buttons-set {

        button {
            display: block;
            width: 100%;
            margin-right: 0;
        }
    }
}