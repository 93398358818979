@import (reference) "mixins";

//
// Forms
// --------------------------------------------------

//
// Mixins
// --------------------------------------------------

.input-reset(){
    display: block;
    width: 100%;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    outline: none;
}

//
// Normalize Elements
// --------------------------------------------------

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    // Chrome and Firefox set a `min-width: min-content;` on fieldsets,
    // so we reset that to ensure it behaves more like a standard block element.
    // See https://github.com/twbs/bootstrap/issues/12359.
    min-width: 0;
}

label{
    display: block;
    margin-bottom: 2px;
    font-family: @font-family-base;
    font-size: @font-size-h5;
    font-weight: @font-weight-bold;
    line-height: 24px;
}

input[type="search"]{
    box-sizing: border-box;
}

//
// Inputs
// --------------------------------------------------

.input-text{
    .input-reset();
    padding: @input-default-padding;
    font-size: @input-default-font-size;
    line-height: @input-default-line-height;
    border: @input-default-border;
    border-radius: @input-default-border-radius;

    color: @input-default-color;
    background-color: @input-default-bg;
    transition: 0.3s ease-out;

    //Reset iOS Styles
    -webkit-appearance: none;

    .placeholder(@gray-light);

    &:focus{
        border-color: darken(@input-default-border-color, 10%);
    }

    &[disabled],
    &[readonly]{
        cursor: not-allowed;
        opacity: .5;
    }

    // Reset height for `textarea`s
    textarea& {
        .resizable(none);
        height: @text-area-height;
    }

    &.error,
    &.validation-failed{
        color: @input-validation-color;
        background-color: @input-validation-bg;
        border-color: @input-validation-border-color;

        .placeholder(@input-validation-color);
    }
}

//Inline Form
.inline-form{
    &:extend(.clearfix all);

    .input-box,
    .button{
        float: left;
    }
    .input-box{
        .calc-helper(width, 100%, ~"100% -"@inline-form-button-width);
    }
    .input-text{
        .border-right-radius(0);
    }
    .button{
        width: @inline-form-button-width;
        height: @inline-form-button-height;
        padding: 0;

        .border-left-radius(0);
    }
}

ul.form-list {
    &:extend(.row);
}

//Horizontal Form
.horizontal-form {
    label{
        margin-bottom: 0;
        line-height: @inline-form-button-height;
    }
    .form-list{
        li{
            .make-row();
        }
    }
}

.grid-form{
    .form-list{
        li{
            .make-row();
        }
        @media (max-width: @grid-float-breakpoint) {
            li{
                margin-bottom: 0;
            }
            [class^="col-"]{
                margin-bottom: @input-default-vertical-spacing;
            }
        }
    }
}

//Custom Form Elements
.custom-checkbox,
.custom-radio{
    position: relative;
    display: inline-block;

    input[type="checkbox"],
    input[type="radio"]{
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 4;

        &[disabled] + label,
        &[readonly] + label{
            opacity: .5;
        }
    }
    label{
        display: inline-block;
        vertical-align: top;
        margin-bottom: 0;
        line-height: 1;
    }
    + .custom-checkbox,
    + .custom-radio{
        margin-left: @custom-checkbox-width;
    }
}

.custom-checkbox{
    label{
        display: table-cell;
        height: @custom-checkbox-height;
        padding-left: @custom-checkbox-width + 8px;
        vertical-align: middle;
        text-transform: capitalize;
        cursor: pointer;

        &:before{
            position: absolute;
            left: 0;
            top: 0;
            .icon;
            box-sizing: border-box;
            vertical-align: middle;
            padding: 3px 0 7px;
            text-align: center;
            display: inline-block;
            content: @custom-checkbox-icon;
            width: @custom-checkbox-width;
            height: @custom-checkbox-height;
            margin-right: 8px;
            font-size: @custom-checkbox-icon-font-size;
            color: @custom-checkbox-color;
            border: 2px solid @custom-checkbox-background;
            border-radius: 2px;
            background-color: @brand-white;
            line-height: 10px;
        }
    }

    span {
        text-transform: none;
    }

    input[type="checkbox"]{
        width: @custom-checkbox-width;
        height: @custom-checkbox-height;
        cursor: pointer;

        &:checked + input + label:before,
        &:checked + label:before {
            background-color: @custom-checkbox-background;
            content: @custom-checkbox-icon-active;
        }
    }
}

.checkbox--green {
    label {
        padding-left: 33px;
    }
    input[type="checkbox"]{

        &:checked + input + label:before,
        &:checked + label:before {
            border: 2px solid @custom-checkbox-background-green;
            background-color: @custom-checkbox-background-green;
        }
    }

}


.checkbox--red {

    input[type="checkbox"]{

        &:checked + input + label:before,
        &:checked + label:before {
            border: 2px solid @custom-checkbox-background-red;
            background-color: @custom-checkbox-background-red;
            font-size: @custom-checkbox-icon-font-size-big;
            content: @fa-var-times;
        }
    }

}

.checkbox--selected {
    input[type="checkbox"], label {
        cursor: unset;
    }
}

.custom-radio{
    input[type="radio"]{
        width: @custom-radio-width;
        height: @custom-radio-height;

        &:checked + label:before{
            background-color: @custom-checkbox-color;
            content: @custom-radio-icon-active;
        }
    }
    label{
        &:before{
            .icon;
            box-sizing: border-box;
            vertical-align: middle;
            padding: 0;
            text-align: center;
            display: inline-block;
            content: @custom-radio-icon;
            width: @custom-checkbox-width;
            height: @custom-checkbox-height;
            margin-right: 8px;
            color: @custom-checkbox-background;
            border: 0;
            background-color: transparent;
            line-height: 28px;
            font-size: @custom-radio-icon-font-size;
            position: relative;
            top: -5px;
        }
    }


}
.radio--green {
    input[type="radio"] {
        &:checked + label:before {
            background-color: @custom-checkbox-background-green;
            border: 2px solid @custom-checkbox-background-green;
            //border-radius: 50%;
            content: '';
        }
    }
    label{
        font-weight: @font-weight-normal;
        &:before{
            content: '';
            color: @custom-checkbox-background;
            top: -1px;
            border: 2px solid @custom-checkbox-background;
            border-radius: 50%;
        }
    }
}

//
// Validation Messages (supports protoype validation & jquery validation)
// --------------------------------------------------
label.error,
.validation-advice{
    color: @brand-error;
    text-transform: initial;
    font-size: 12px;
    line-height: 13px;
    padding-top: @indent-xs;
}

// Asterisk icon for required fields
label {
    .icon-asterisk {
        color: @brand-error;
        font-size: 8px;
        vertical-align: top;
        padding-left: 3px;
    }
}

//Inline Validation Messages
//(WARNING that long messages may not look great without additional styling or considerations)
.inline-validation{
    li,
    .field{
        position: relative;
    }
    .field{
        label.error,
        .validation-advice{
            //right: (@input-default-horizontal-spacing + @input-default-padding);
        }
    }
    label.error,
    .validation-advice{
        position: absolute;
        top: 50%;
        right: @input-default-padding;
        margin-top: -(@validation-msg-line-height/2);
    }
    textarea + label.error,
    textarea + .validation-advice{
        top: @input-default-padding;
        margin-top: 0;
        line-height: @input-default-line-height;
    }
}

//
// Form List
// --------------------------------------------------

.form-list{
    &:extend(.reset-list);

    li{
        margin-bottom: @input-default-vertical-spacing;

        &.error .help-inline {
            color: @brand-error;
        }
    }
    .fields{
        &:extend(.clearfix all);
        margin-left: -@input-default-horizontal-spacing;
        margin-right: -@input-default-horizontal-spacing;

        .field{
            float: left;
            width: 50%;
            padding-left: @input-default-horizontal-spacing;
            padding-right: @input-default-horizontal-spacing;
        }
    }
    .field-group{
        .field{
            margin-top: (@input-default-vertical-spacing/2);

            &:first-child{
                margin-top: 0;
            }
        }
    }
    .half {
        width:50%;
    }
}

.buttons-set {
    a:not(.button) {
        display: block;
        margin-top: 8px;
    }
}

.form-calendar {
    position: relative;

    i.icon-calendar {
        position: absolute;
        right: 10px;
        top: 10px;
    }
}

@media screen and (max-width: @grid-float-breakpoint) {
    .form-list {
        .fields {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;

            .field {
                float: none;
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: @input-default-vertical-spacing;
            }
        }
        .half {
            width:100%;
        }
    }
    .inline-validation{
        .field{
            label.error,
            .validation-advice{
                right: @input-default-padding;
            }
        }
    }
}