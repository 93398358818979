/*********

Dashboard Page

********/

.col-left {
	float: left;
	width: percentage(690/@wrapper-width);
	padding-right: 10px;
}

.col-right {
	float: right;
	width: percentage(290/@wrapper-width);
	padding-left: 10px;
}

.quick-search-box {
	position: relative;

	#input_quick {
		padding-right: 35px;
	}

	button {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		border: none;
		background: none;
		padding: 0 12px 0 6px;

		.icon-search {
			font-size: 18px;
		}
	}
}

.configurable-option-quick {
	.product-search-result {
		margin-top: 20px;

		li {
			margin-bottom: 5px;
		}
	}
}

.dashboard-section {
	margin-bottom: 30px;

	.gor-accordion  .accordion-content-inner {
		padding: 0;

		&.scroll-horizontal--desktop {
			padding-bottom: @indent-xs;
		}
	}

	table {
		margin-bottom: 15px;

		th:first-child {
			padding-left: 20px;
		}

		td {
			&:first-child {
				font-size: @font-size-base;
			}

			&:last-child {
				width: 170px;
			}
		}

		td:not(:last-child) span:not(.empty) {
			display: block;
			font-weight: @font-weight-normal;
			font-size: @font-size-small;

			&.archive {
				display: inline-block;
				font-weight: @font-weight-semi-bold;
			}
			&.quote {
				display: inline;
			}
		}
	}

	.button {
		min-width: auto;

		+ .button {
			margin-left: 17px;
		}
	}

	&.quotes {
		table {
			td {
				&.status {
					&.awaiting {
						color: @brand-red;
					}
				}
			}

		}
	}
}
.page-orders,
.quotes-section {
	.tabs-row > .container-fluid  {
		background-color: transparent;
		padding: 0 @indent-lg;
	}

	.container {
		background-color: @brand-white;
	}

	.quote-sort-select {
		float: right;
		min-width: 132px;
	}

	.notice {
		padding-top: @indent-sm;
		margin-bottom: 10px;
	}

	.message-container {
		float: left;
		font-size: @font-size-large;
	}
}

.invoice-section {
	padding: 0 @indent-lg;

	.message-container {
		float: none;
		display: inline-block;
	}

	.table td {
		border: @table-cell-border;
	}
}

.helpful-link {
	.gor-accordion .accordion-content-inner {
		background-color: @brand-white;
		padding: 15px 0 27px;
	}

	li {
		padding: 0 15px 0 22px;
		margin-bottom: 15px;
		line-height: 1;

		a {
			line-height: 1.3;
		}
	}
}

.selling-plant {
    &.dashboard-section{
        .gor-accordion {
            .accordion-content-inner {
                background-color: @brand-white;
                padding: 17px 20px;
            }
        }
    }
	.plan-info,
	.plant-images {
		float: left;
	}

	.plan-info {
		margin-top: -5px;
		width: 100%;
		line-height: 1.3;

		strong {
			font-size: @font-size-h5;
		}

		a,
		span {
			color: @brand-black;
			font-weight: @font-weight-semi-bold;
			text-decoration: none;
		}

		.email {
		    color: @brand-blue;
		    font-weight: @font-weight-bold;
		    text-decoration: underline;
		}
	}

	.plant-images {
		margin: 0 0 10px 0;

		img {
			width: 100%;
			height: auto;
			display: block;
		}

		+ .plan-info {
			width: percentage(168/243);
		}
	}
}

.notification-link {
	margin-right: @indent-xs;
	&:hover {
		text-decoration: none !important; //override
	}
}
div.notification {
	position: relative;
	display: inline-block;
	width: 24px;
	height: 24px;
	background-color: @brand-red;
	color: @brand-white;
	font-size: @font-size-base;
	text-align: center;
	border-radius: 100%;
	font-weight: @font-weight-bold;
	line-height: 22px;
	z-index: 0;
	margin-right: @indent-xs;

	+ div {
		display: inline-block;
	}

	.accordion-header & {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		float: none;
		display: inline-block;
		margin-left: 12px;

		+ span {
			left: 140px;
			display: inline-block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-size: @font-size-small;
			font-weight: @font-weight-normal;
		}
	}
}

span.archive {
	display: inline-block;
	font-size: @font-size-small;
	background-color: @gray;
	padding: 3px 9px;
	color: @brand-white;
	font-weight: @font-weight-semi-bold;
	margin-bottom: 3px;

}


tr.archive .last-update {
	font-weight: @font-weight-bold;
}


span.empty {
	display: inline-block;
	width: 100%;
	height: 1px;
	border-top: 1px solid @brand-black;
	max-width: 42px;
	vertical-align: middle;
}

.pricing-catalog {
	.accordion-content-inner {
		background-color: @brand-white;
	}

	li {
		font-size: @font-size-h5;
		padding: 14px 15px 14px 20px;
		border-top: 1px solid @gray;

		&:first-child {
			border-top: none;
		}
	}

	a {
		color: @brand-black;
		text-decoration: none;
		font-weight: @font-weight-normal;
		letter-spacing: -0.4px;
		position: relative;
		padding-left: 20px;
		display: block;
		line-height: 16px;

		&:before {
			.icon;
			content: @fa-var-file-text-o;
			color: @brand-blue;
			display: inline-block;
			margin-right: 5px;
			position: absolute;
			left: 0;
			top: -3px;
		}
	}
}

.quotes-archived {
	background-color: @brand-blue;
	color: @brand-white;

	li {
		padding: 7px 20px 6px;
		line-height: 1.2;
	}

	span {
		float: left;
		width: percentage(180/275);
	}

	button {
		color: @brand-white;
		float: right;
		font-size: @font-size-small;
		line-height: 1.2;
		text-align: right;
		width: percentage(53/275);
		font-weight: @font-weight-bold;
		text-decoration: underline;
		white-space: normal;
	}
}

.page-orders {
	table {
		margin-bottom: 15px;

		th:first-child {
			padding-left: 20px;
		}

		td {
			&:first-child {
				padding-left: 20px;
				font-size: @font-size-base;
			}

			&:last-child {
				width: 104px;
			}
		}

		span {
			display: block;
			font-weight: @font-weight-normal;
			font-size: @font-size-small;

			&.archive {
				display: inline-block;
				font-weight: @font-weight-semi-bold;
			}

			&.empty{
				display: inline-block;
			}
		}
	}
}

.page-quotes,
.page-orders,
.page-dashboard {

	.dashboard-table {
		th {
			&:first-child {
				max-width: 138px;
				padding-left: @indent-sm;
			}
		}
	}
}

.page-dashboard {
	// required for fitting 9 columns in 680px container
	.table-permissions {

		th {
			padding: @indent-sm @indent-xs;

			&:first-child {
				padding: @indent-sm @indent-xs @indent-sm @indent-sm;
			}
		}

		td {
			padding: @indent-sm @indent-xs;
		}
	}
}

.page-invoices {

	.dashboard-table {

		th {
			&:first-child {
				padding-left: @indent-sm;
				min-width: 90px;
			}
		}

		td {
			&:first-child {
				font-size: @font-size-small;
				padding-left: @indent-sm;
			}
		}
	}
}

.page-dashboard {

	.dashboard-table {

		td {

			&:first-child {
				padding-left: @indent-sm;
				padding-right: @indent-sm;
			}
		}


		&.table-permissions {

			td {

				&:first-child {
					width: 136px;
				}
			}
		}
	}
}


@media screen and (max-width: @screen-md) {
	.col-left {
		width: 100%;
		padding-right: 0;
	}

	.col-right {
		border-left: 0;
		width: 100%;
		padding-left: 0;
	}

	.col-md-left,
	.col-md-right {
		width: percentage(341/(@screen-md - 60));
	}

	.col-md-left {
		float: left;
	}

	.col-md-right {
		float: right;
	}
}

@media screen and (max-width: 640px) {
	.page-orders,
	.quotes-section {
		.tabs-content {
			padding: 20px 0;
		}

		.pagination-bar {
			margin-right: 0;
		}

		.message-container {
			padding: 0 20px 0 0;
		}
	}
}

@media screen and (max-width: 600px) {
	.col-md-left,
	.col-md-right {
		width: 100%;
	}
	.dashboard-section {
		.button {
			width: auto;
			display: inline-block;

			+ .button {
				margin-left: 0;
				float: right;
			}
		}
	}

	div.notification {
		top: -4px;
	}

	span.archive {
		margin-top: 8px;
	}

	.quotes-archived {
		li {
			font-weight: @font-weight-bold;
			font-size: @font-size-small;
		}

		button {
			text-align: left;
		}
	}
}

.quick-price {

	.pricing-container {
		font-size: 16px;

		em {
			font-size: @font-size-base;
		}
	}

	.error {
		font-size: @font-size-small;
		color: @brand-red;
	}

	.linear-foot-pricing {
		margin: 0;
		font-size: 16px;

		> span {
			display: block;
		}

		em {
			font-size: 16px;
		}
	}

    .on-top {
        font-size: 16px;

        a {
            display: inline-block;
            margin-top: 20px;
        }
    }

	.quick-search-loader {
        position: absolute;
        width: 22px;
        top: 50%;
        right: 35px;
        transform: translateY(-50%);
    }

    .pricing-loader {
        position: relative;

        img {
            width: 22px;
            height: 22px;
            position: absolute;
        }
        span {
            line-height: 22px;
            padding-left: 27px;
        }
    }
}

@media screen and (max-width: 360px) {
	.dashboard-section {
		.button {
			width: 100%;

			+ .button {
				margin-top: 20px;
			}
		}
	}
}